<template>
  <div class="detail">
    <div class="header" @click="$emit('changeVisible')">
      <div class="title">雨水管道详情</div>
      <div class="extra">
        <a-icon type="close" />
      </div>
    </div>

    <div class="pipe">
      <template v-if="
        renderDetail.wellFrom && (renderDetail.wellTo || renderDetail.drainTo)
      ">
        <img class="bg" src="../../../assets/icons/pipe4.png" />
      </template>
      <template v-if="
        !renderDetail.wellFrom &&
        (renderDetail.wellTo || renderDetail.drainTo)
      ">
        <img class="bg" src="../../../assets/icons/pipe2.png" />
      </template>
      <template v-if="
        renderDetail.wellFrom &&
        !(renderDetail.wellTo || renderDetail.drainTo)
      ">
        <img class="bg" src="../../../assets/icons/pipe1.png" />
      </template>
      <template v-if="
        !renderDetail.wellFrom &&
        !(renderDetail.wellTo || renderDetail.drainTo)
      ">
        <img class="bg" src="../../../assets/icons/pipe3.png" />
      </template>

      <template v-if="renderDetail.wellFrom">
        <template v-if="renderDetail.wellFrom.depth">
          <template v-if="renderDetail.wellFrom.depth > 4">
            <img class="length1" src="../../../assets/icons/length/8.png" alt="" />
          </template>
          <template v-else-if="renderDetail.wellFrom.depth > 3.5">
            <img class="length1" src="../../../assets/icons/length/7.png" alt="" />
          </template>
          <template v-else-if="renderDetail.wellFrom.depth > 3">
            <img class="length1" src="../../../assets/icons/length/6.png" alt="" />
          </template>
          <template v-else-if="renderDetail.wellFrom.depth > 2.5">
            <img class="length1" src="../../../assets/icons/length/5.png" alt="" />
          </template>
          <template v-else-if="renderDetail.wellFrom.depth > 2">
            <img class="length1" src="../../../assets/icons/length/4.png" alt="" />
          </template>
          <template v-else-if="renderDetail.wellFrom.depth > 1.5">
            <img class="length1" src="../../../assets/icons/length/3.png" alt="" />
          </template>
          <template v-else-if="renderDetail.wellFrom.depth > 1">
            <img class="length1" src="../../../assets/icons/length/2.png" alt="" />
          </template>
          <template v-else-if="renderDetail.wellFrom.depth > 0.5">
            <img class="length1" src="../../../assets/icons/length/1.png" alt="" />
          </template>
          <template v-else>
            <img class="length1" src="../../../assets/icons/length/1.png" alt="" />
          </template>
        </template>
        <template v-else>
          <img class="length1" src="../../../assets/icons/length/1.png" alt="" />
        </template>

        <div class="well-from-depth">
          井深{{
            renderDetail.wellFrom.depth ? renderDetail.wellFrom.depth : "-"
          }}米
        </div>
        <div class="well-from-elevation">
          地面高程：{{
            parseFloat(renderDetail.wellFrom.elevation).toFixed(3)
          }}m
        </div>
        <div class="well-from-elevation2">
          井底高程：{{
            parseFloat(
              renderDetail.wellFrom.elevation -
              (renderDetail.wellFrom.depth ? renderDetail.wellFrom.depth : 0)
            ).toFixed(3)
          }}m
        </div>

        <div class="well-from-id">
          窨井编号：{{ renderDetail.wellFrom.code }}
        </div>

        <template v-if="renderDetail.wellFrom.pipeFrom">
          <div class="from-elevation">
            管底高程：{{ renderDetail.elevationFrom }}m
          </div>

          <div class="from-elevation2">
            距离井底：{{
              parseFloat(
                renderDetail.wellFrom.depth -
                renderDetail.wellFrom.pipeFrom.buringDepthTo
              ).toFixed(3)
            }}m
          </div>

          <div class="from-elevation3">
            管顶高程：{{
              parseFloat(
                renderDetail.elevationFrom +
                renderDetail.wellFrom.pipeFrom.diameter * 0.001
              ).toFixed(3)
            }}m
          </div>
        </template>
      </template>

      <template v-if="renderDetail.wellTo">
        <template v-if="renderDetail.wellTo.depth">
          <template v-if="renderDetail.wellTo.depth > 4">
            <img class="length2" src="../../../assets/icons/length/8.png" alt="" />
          </template>
          <template v-else-if="renderDetail.wellTo.depth > 3.5">
            <img class="length2" src="../../../assets/icons/length/7.png" alt="" />
          </template>
          <template v-else-if="renderDetail.wellTo.depth > 3">
            <img class="length2" src="../../../assets/icons/length/6.png" alt="" />
          </template>
          <template v-else-if="renderDetail.wellTo.depth > 2.5">
            <img class="length2" src="../../../assets/icons/length/5.png" alt="" />
          </template>
          <template v-else-if="renderDetail.wellTo.depth > 2">
            <img class="length2" src="../../../assets/icons/length/4.png" alt="" />
          </template>
          <template v-else-if="renderDetail.wellTo.depth > 1.5">
            <img class="length2" src="../../../assets/icons/length/3.png" alt="" />
          </template>
          <template v-else-if="renderDetail.wellTo.depth > 1">
            <img class="length2" src="../../../assets/icons/length/2.png" alt="" />
          </template>
          <template v-else-if="renderDetail.wellTo.depth > 0.5">
            <img class="length2" src="../../../assets/icons/length/1.png" alt="" />
          </template>
          <template v-else>
            <img class="length2" src="../../../assets/icons/length/1.png" alt="" />
          </template>
        </template>
        <template v-else>
          <img class="length2" src="../../../assets/icons/length/1.png" alt="" />
        </template>

        <div class="well-to-depth">
          井深{{
            renderDetail.wellTo.depth ? renderDetail.wellTo.depth : "-"
          }}米
        </div>
        <div class="well-to-elevation">
          地面高程：{{ parseFloat(renderDetail.wellTo.elevation).toFixed(3) }}m
        </div>
        <div class="well-to-elevation2">
          井底高程：{{
            parseFloat(
              renderDetail.wellTo.elevation -
              (renderDetail.wellTo.depth ? renderDetail.wellTo.depth : 0)
            ).toFixed(3)
          }}m
        </div>

        <div class="well-to-id">窨井编号：{{ renderDetail.wellTo.code }}</div>

        <template v-if="renderDetail.wellTo.pipeTo">
          <div class="to-elevation">
            管底高程：{{ renderDetail.elevationTo }}m
          </div>

          <div class="to-elevation2">
            距离井底：{{
              parseFloat(
                renderDetail.wellTo.depth -
                renderDetail.wellTo.pipeTo.buringDepthFrom
              ).toFixed(3)
            }}m
          </div>

          <div class="to-elevation3">
            管顶高程：{{
              parseFloat(
                renderDetail.elevationTo +
                renderDetail.wellTo.pipeTo.diameter * 0.001
              ).toFixed(3)
            }}m
          </div>
        </template>
      </template>

      <template v-if="renderDetail.drainTo">
        <div class="well-to-depth">
          井深{{
            renderDetail.drainTo.depth ? renderDetail.drainTo.depth : "-"
          }}米
        </div>
        <div class="well-to-elevation">
          地面高程：{{ parseFloat(renderDetail.drainTo.elevation).toFixed(3) }}m
        </div>
        <div class="well-to-elevation2">
          井底高程：{{
            parseFloat(
              renderDetail.drainTo.elevation -
              (renderDetail.drainTo.depth ? renderDetail.drainTo.depth : 0)
            ).toFixed(3)
          }}m
        </div>

        <div class="well-to-id">窨井编号：{{ renderDetail.drainTo.code }}</div>

        <template v-if="renderDetail.drainTo.pipeTo">
          <div class="to-elevation">
            管底高程：{{ renderDetail.drainTo.pipeTo.elevationTo }}m
          </div>

          <div class="to-elevation2">
            距离井底：{{
              parseFloat(
                renderDetail.drainTo.depth -
                renderDetail.drainTo.pipeTo.buringDepthFrom
              ).toFixed(3)
            }}m
          </div>

          <div class="to-elevation3">
            管顶高程：{{
              parseFloat(
                renderDetail.drainTo.pipeTo.elevationTo +
                renderDetail.drainTo.pipeTo.diameter * 0.001
              ).toFixed(3)
            }}m
          </div>
        </template>
      </template>
    </div>

    <div class="content">
      <div class="item">
        <div class="label">编号：</div>
        <div class="value">{{ renderDetail.code }}</div>
      </div>

      <div class="item">
        <div class="label">所在道路：</div>
        <div class="value">
          {{ renderDetail.location ? renderDetail.location : "--" }}
        </div>
      </div>

      <div class="item">
        <div class="label">材质：</div>
        <div class="value">{{ renderDetail.material }}</div>
      </div>
      <div class="item">
        <div class="label">管径(mm)：</div>
        <div class="value">{{ renderDetail.diameter }}</div>
      </div>

      <div class="item">
        <div class="label">埋深-起点(m)：</div>
        <div class="value">{{ renderDetail.buringDepthFrom }}</div>
      </div>
      <div class="item">
        <div class="label">埋深-终点(m)：</div>
        <div class="value">{{ renderDetail.buringDepthTo }}</div>
      </div>

      <div class="item">
        <div class="label">高程-起点(m)：</div>
        <div class="value">{{ renderDetail.elevationFrom }}</div>
      </div>
      <div class="item">
        <div class="label">高程-终点(m)：</div>
        <div class="value">{{ renderDetail.elevationTo }}</div>
      </div>
      <div class="item">
        <div class="label">管长(m)：</div>
        <div class="value">{{ renderDetail.length }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchPipeDetail } from "../api/rain";
export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      renderDetail: {},
    };
  },

  watch: {
    detail() {
      this.getDetail();
    },
  },

  mounted() {
    this.getDetail();
  },

  methods: {
    getDetail() {
      fetchPipeDetail({
        ids: [this.detail.id],
        type: "rain",
      }).then((res) => {
        if (Array.isArray(res) && res.length > 0) {
          const item = res[0];
          this.renderDetail = {
            ...item,
            pipeFrom:
              item.pipesFrom && item.pipesFrom[0] ? item.pipesFrom[0] : null,
            pipeTo: item.pipesTo && item.pipesTo[0] ? item.pipesTo[0] : null,
          };
        }
      });
    },
  },
};
</script>


<style lang="less" scoped>
.detail {
  position: fixed;
  top: 11vh;
  right: 1vw;
  bottom: 2vh;
  bottom: 2vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);

  width: 50vw;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  &>.header {
    background-color: var(--theme-color);
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .extra {
      cursor: pointer;
    }
  }

  .content {
    margin-top: 4vh;
    padding: 2vh 1vw;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0.5vh;

    .item {
      display: flex;
      align-items: center;

      .label {
        width: 8em;
      }

      .value {
        color: var(--theme-color);
      }
    }
  }
}

.pipe {
  position: relative;
  padding: 4vh 0;
  font-size: 1.2vh;

  .bg {
    height: 30vh;
    width: 22vw;
    display: block;
    margin: 0 auto;
  }

  .length1 {
    width: 3.25vw;
    position: absolute;
    bottom: 8.9vh;
    left: 8vw;
  }

  .length2 {
    width: 3.25vw;
    position: absolute;
    bottom: 8.9vh;
    right: 8vw;
  }

  .well-from-depth {
    border-top: 2px solid #999;
    border-bottom: 2px solid #999;
    position: absolute;
    top: 6vh;
    bottom: 4vh;
    left: 1vw;
    writing-mode: vertical-lr;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      position: absolute;
      left: 49%;
      top: 0;
      height: 40%;
      width: 1px;
      background-color: #999;
    }

    &::after {
      content: "";
      position: absolute;
      left: 49%;
      bottom: 0;
      height: 40%;
      width: 1px;
      background-color: #999;
    }
  }

  .well-from-elevation {
    position: absolute;
    top: 1vh;
    left: 20%;
  }

  .well-from-elevation2 {
    position: absolute;
    bottom: -2vh;
    left: 20%;
  }

  .well-from-id {
    position: absolute;
    bottom: 0;
    left: 20%;
  }

  .well-to-depth {
    border-top: 2px solid #999;
    border-bottom: 2px solid #999;
    position: absolute;
    top: 6vh;
    bottom: 4vh;
    right: 1vw;
    writing-mode: vertical-lr;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      position: absolute;
      left: 49%;
      top: 0;
      height: 40%;
      width: 1px;
      background-color: #999;
    }

    &::after {
      content: "";
      position: absolute;
      left: 49%;
      bottom: 0;
      height: 40%;
      width: 1px;
      background-color: #999;
    }
  }

  .well-to-elevation {
    position: absolute;
    top: 1vh;
    right: 20%;
  }

  .well-to-elevation2 {
    position: absolute;
    bottom: -2vh;
    right: 20%;
  }

  .well-to-id {
    position: absolute;
    bottom: 0;
    right: 20%;
  }

  .from-elevation {
    display: flex;
    align-items: center;
    position: absolute;
    left: 8%;
    bottom: 2vh;
  }

  .from-elevation2 {
    display: flex;
    align-items: center;
    position: absolute;
    left: 8%;
    bottom: 4vh;
  }

  .from-elevation3 {
    display: flex;
    align-items: center;
    position: absolute;
    left: 8%;
    bottom: 12vh;
  }

  .to-elevation {
    display: flex;
    align-items: center;
    position: absolute;
    right: 8%;
    bottom: 2vh;
  }

  .to-elevation2 {
    display: flex;
    align-items: center;
    position: absolute;
    right: 8%;
    bottom: 4vh;
  }

  .to-elevation3 {
    display: flex;
    align-items: center;
    position: absolute;
    right: 8%;
    bottom: 12vh;
  }
}
</style>