import { PointLayer } from '@antv/l7';
import { mapState } from 'vuex'

import InterceptingWellDetail from '@/components/intercepting-well/index.vue'
import LiquidDetail from '@/components/liquid/index.vue'
import FlowmeterDetail from '@/components/flowmeter-detail.vue'
import RainfallDetail from '@/components/rainfall-detail.vue'
import MonitorDetail from '@/components/monitor-detail.vue'
import WaterQualityDetail from '@/components/water-quality-detail.vue'

export default {

    components: {
        LiquidDetail,
        FlowmeterDetail,
        MonitorDetail,
        InterceptingWellDetail,

        RainfallDetail,
        WaterQualityDetail,
    },

    data() {
        return {
            cutoDetail: {},
            cutoVisible: false,
            liquidDetail: {},
            liquidVisible: false,
            flowDetail: {},
            flowVisible: false,
            rainfallDetail: {},
            rainfallVisible: false,
            waterQualityDetail: {},
            waterQualityVisible: false,
                        monitorDetail: {},
            monitorVisible: false,
        }
    },

    computed: {
        ...mapState('home', ['facilityList'])
    },

    watch: {
        facilityList() {
            console.log('factility list changed', this.facilityList)
            if (this.facilityList.length > 0) {
                this.getMonitor();
                this.getLiquid();
                this.getFlow();
                this.getCuto();
                this.getRainfall();
                this.getWaterQuality();
            }
        },

            liquidVisible() {
                if (!this.liquidVisible) {
                    this.closeFacilityActive();
                }
            },

            flowVisible() {
                if (!this.flowVisible) {
                    this.closeFacilityActive();
                }
            },

            cutoVisible() {
                if (!this.cutoVisible) {
                    this.closeFacilityActive();
                }
            },

            monitorVisible() {
                if (!this.monitorVisible) {
                    this.closeFacilityActive();
                }
            },

            rainfallVisible() {
                if (!this.rainfallVisible) {
                    this.closeFacilityActive();
                }
            },
            waterQualityVisible() {
                if (!this.waterQualityVisible) {
                    this.closeFacilityActive();
                }
            },

 

    },

    methods: {
        getLiquid() {

            const data = this.facilityList.filter(item => item.type === 'liquidLevel');
            if (data.length === 0) return;

            if (!this.liquidLayer) {
                const zoom = this.map?.getZoom() || 3;

                this.liquidLayer = new PointLayer({
                    zIndex: 100,

                }).source(data, {
                    parser: {
                        type: 'json',
                        x: 'x',
                        y: 'y'
                    }
                })
                    .shape('liquidLevel')
                    .size(zoom * 0.55)

                const that = this;
                this.liquidLayer.on('click', function (e) {
                    console.log('e', e)
                    that.liquidDetail = e.feature;
                                    that.setFacilityActive(e.feature, 'liquidVisible');
                })
                this.scene?.addLayer(this.liquidLayer);


            } else {
                this.liquidLayer.show();
            }

        },
        removeLiquid() {
            this.liquidLayer?.hide();
        },

        getFlow() {

            const data = this.facilityList.filter(item => item.type === 'flowMeter');
            if (data.length === 0) return;

            if (!this.flowLayer) {
                const zoom = this.map?.getZoom() || 3;

                this.flowLayer = new PointLayer({
                    zIndex: 100,

                })

                    .source(data, {
                        parser: {
                            type: 'json',
                            x: 'x',
                            y: 'y'
                        }
                    })
                    .shape('flowMeter')
                    .size(zoom * 0.55)

                const that = this;
                this.flowLayer.on('click', function (e) {
                    console.log('e', e)
                    that.flowDetail = e.feature;
                                    that.setFacilityActive(e.feature, 'flowVisible');

                })


                this.scene?.addLayer(this.flowLayer);

            } else {
                this.flowLayer.show();
            }

        },
        removeFlow() {
            this.flowLayer?.hide();
        },

        getCuto() {

            const data = this.facilityList.filter(item => item.type === 'cutoffWell');
            if (data.length === 0) return;

            if (!this.cutoLayer) {
                const zoom = this.map?.getZoom() || 3;

                this.cutoLayer = new PointLayer({
                    zIndex: 100,

                })

                    .source(data, {
                        parser: {
                            type: 'json',
                            x: 'x',
                            y: 'y'
                        }
                    })
                    .shape('cutoffwell')
                    .size(zoom * 0.55)

                const that = this;
                this.cutoLayer.on('click', function (e) {
                    console.log('e', e)
                    that.cutoDetail = e.feature;
                                    that.setFacilityActive(e.feature, 'cutoVisible');

                })


                this.scene?.addLayer(this.cutoLayer);

            } else {
                this.cutoLayer.show();
            }

        },
        removeCuto() {
            this.cutoLayer?.hide();
        },


        getMonitor() {
            const data = this.facilityList.filter(item => item.type === 'video');
            if (data.length === 0) return;

            if (!this.monitorLayer) {
                const zoom = this.map?.getZoom() || 3;

                this.monitorLayer = new PointLayer({
                    zIndex: 100,

                })
                    .source(data, {
                        parser: {
                            type: 'json',
                            x: 'x',
                            y: 'y'
                        }
                    })
                    .shape('video')
                    .size(zoom * 0.55)

                const that = this;
                this.monitorLayer.on('click', function (e) {
                    console.log('e', e)
                    that.monitorDetail = e.feature;
                                    that.setFacilityActive(e.feature, 'monitorVisible');

                })


                this.scene?.addLayer(this.monitorLayer);

            } else {
                this.monitorLayer.show();
            }

        },
        removeMonitor() {
            this.monitorLayer?.hide();
        },


        getRainfall() {

            const data = this.facilityList.filter(item =>  item.type && item.type.toUpperCase() === 'rainFall'.toUpperCase());
            if (data.length === 0) return;

            if (!this.rainfallLayer) {
                const zoom = this.map?.getZoom() || 3;

                this.rainfallLayer = new PointLayer({
                    zIndex: 100,

                })
                    .source(data, {
                        parser: {
                            type: 'json',
                            x: 'x',
                            y: 'y'
                        }
                    })
                    .shape('rainFall')
                    .size(zoom * 0.55)

                const that = this;
                this.rainfallLayer.on('click', function (e) {
                    console.log('e', e)
                    that.rainfallDetail = e.feature;
                                    that.setFacilityActive(e.feature, 'rainfallVisible');

                })


                this.scene?.addLayer(this.rainfallLayer);

            } else {
                this.rainfallLayer.show();
            }

        },
        removeRainfall() {
            this.rainfallLayer?.hide();
        },



        getWaterQuality() {

            const data = this.facilityList.filter(item => item.type === 'waterquality');
            if (data.length === 0) return;

            if (!this.waterQualityLayer) {
                const zoom = this.map?.getZoom() || 3;

                this.waterQualityLayer = new PointLayer({
                    zIndex: 100,

                })
                    .source(data, {
                        parser: {
                            type: 'json',
                            x: 'x',
                            y: 'y'
                        }
                    })
                    .shape('water-quality')
                    .size(zoom * 0.55)

                const that = this;
                this.waterQualityLayer.on('click', function (e) {
                    console.log('e', e)
                    that.waterQualityDetail = e.feature;
                                    that.setFacilityActive(e.feature, 'waterQualityVisible');

                })


                this.scene?.addLayer(this.waterQualityLayer);

            } else {
                this.waterQualityLayer.show();
            }

        },
        removeWaterQuality() {
            this.waterQualityLayer?.hide();
        },


                setFacilityActive(info, key) {

                        this.closeFacilityActive();

                 const zoom = this.map?.getZoom() || 3;

                this.facilityActiveLayer = new PointLayer({
                    zIndex: 200,
                })
                    .source([info], {
                        parser: {
                            type: 'json',
                            x: 'x',
                            y: 'y'
                        }
                    })
                    .shape('simple')
                    .color('#ff0000')
                    .size(zoom * 0.6)

                const that = this;
                this.facilityActiveLayer.on('click', function () {
                        that.closeFacilityActive();
                that[key] = false;
                })


                this.scene?.addLayer(this.facilityActiveLayer);
                this[key] = true
        },

        closeFacilityActive() {
            if (this.facilityActiveLayer) {
                this.scene?.removeLayer(this.facilityActiveLayer);
                this.facilityActiveLayer = null;
            }
        }

    }
}