<template>
    <div>

        <a-space style="margin-bottom: 1em; padding: 0 1em">
            <a-range-picker size="small" style="width: 12vw" v-model="selectedDate" />
            <a-select size="small" placeholder="指标" style="width: 120px" v-model="queryOption">
                <a-select-option v-for="item in queryOptions" :key="item.value">{{
                    item.name
                }}</a-select-option>
            </a-select>
            <a-select placeholder="间隔" size="small" style="width: 80px" v-model="interval">
                <a-select-option v-for="item in statInterval" :key="item.value">{{
                    item.name
                }}</a-select-option>
            </a-select>
            <a-button type="primary" size="small" @click="query">查询</a-button>
            <a-button size="small" @click="reset">重置</a-button>
        </a-space>
        <Chart :list="chartList" />
    </div>
</template>

<script>
import Chart from './chart.vue'
import moment from 'moment'
import request from '@/api/request'
import { mapGetters } from 'vuex'

export default {
    props: {
        detail: {
            type: Object,
            default: () => ({})
        }
    },
    components: {
        Chart,
    },
    data() {
        return {
            selectedDate: [moment().subtract(1, "months"), moment()],


            queryOption: '',

            interval: '1h',

            chartList: [],
        }
    },

    watch: {
        detail: {
            handler(newValue, oldValue) {
                // 2023-09-18 在查询前检查是否从圣禹切换到祺润，修改queryOption为默认首项
                if (newValue.subType !== oldValue.subType) {
                    this.queryOption = this.queryOptions[0].value
                }
                this.query();
            },
            deep: true,
        }
    },

    mounted() {
        // 2023-09-18 在查询前修改queryOption为默认首项

        this.queryOption = this.queryOptions[0].value
        this.query();

    },

    methods: {
        query() {
            this.getHistoryData();
        },
        reset() {
            this.selectedDate = [moment().subtract(1, "months"), moment()];
            this.interval = "1d";
            this.getHistoryData();
        },

        getHistoryData() {

            if (!this.detail.sn || !this.detail.subType) return;

            this.loading = true;

            request({
                url: "/data-service/dev/stat",
                method: "post",
                data: {
                    beginDate: this.selectedDate[0].format("YYYY-MM-DD") + " 00:00:00",
                    endDate: this.selectedDate[1].format("YYYY-MM-DD") + " 00:00:00",
                    interval: this.interval,

                    eids: [this.detail.sn],
                    devType: this.detail.subType,
                },
            }).then((res) => {
                if (Array.isArray(res)) {
                    this.chartList = res.map((item) => {

                        return {
                            ts: item.ts,
                            value: item[this.queryOption]
                        }
                    })

                }
            })
        },

    },
    computed: {
        ...mapGetters("setting", ["findDataDict"]),

        statInterval() {
            return this.findDataDict("statInterval");
        },
        queryOptions() {
            let shengyu_yeya_queryOptions = [
                {
                    name: "井内液位",
                    value: "liquidLevelInner"
                },
                {
                    name: "井外液位",
                    value: "liquidLevelOuter"
                },
                {
                    name: "污水液位",
                    value: "liquidLevelDirtyPipe"
                },
                {
                    name: "SS",
                    value: "ss"
                },
                {
                    name: "雨量",
                    value: "precipitation"
                },
                {
                    name: "1#气囊压力(kpa)",
                    value: "pressureAirbag1"
                },
                {
                    name: "2#气囊压力(kpa)",
                    value: "pressureAirbag2"
                },
                {
                    name: "1#闸门状态",
                    value: "isIntakeValveOpenAirbag1"
                },
                {
                    name: "2#闸门状态",
                    value: "isIntakeValveOpenAirbag2"
                },
                {
                    name: "外部SS",
                    value: "claatekSs"
                },

            ]


            let shengyu_other_queryOptions = [
                {
                    name: "井内液位",
                    value: "liquidLevelInner"
                },
                {
                    name: "井外液位",
                    value: "liquidLevelOuter"
                },
                {
                    name: "污水液位",
                    value: "liquidLevelDirtyPipe"
                },
                {
                    name: "SS",
                    value: "ss"
                },
                {
                    name: "雨量",
                    value: "precipitation"
                },
                {
                    name: "1#气囊压力(kpa)",
                    value: "pressureAirbag1"
                },
                {
                    name: "2#气囊压力(kpa)",
                    value: "pressureAirbag2"
                },
                {
                    name: "1#气囊开度",
                    value: "gateOpeningAirbag1"
                },
                {
                    name: "2#气囊开度",
                    value: "gateOpeningAirbag2"
                },
                {
                    name: "外部SS",
                    value: "claatekSs"
                },

            ]

            let qirun_queryOptions = [
                {
                    name: "液位",
                    value: "liquidLevel"
                },
                {
                    name: "雨量",
                    value: "rainfall"
                },
                {
                    name: "COD",
                    value: "cod"
                },
                {
                    name: "油压",
                    value: "gateOilPressure"
                },
                {
                    name: "截留闸开度",
                    value: "cutoffGateOpening"
                },
                {
                    name: "出水闸开度",
                    value: "outputGateOpening"
                },
                {
                    name: "外部SS",
                    value: "claatekSs"
                },

            ]

            if (this.detail.subType === 'intel_cutoffWell_qirun_main') {
                return qirun_queryOptions
            }
            else { // 不是祺润就返回圣禹
                if (this.detail.subType === 'intel_cutoffWell_shengyu_yeya') {
                    return shengyu_yeya_queryOptions
                }
                return shengyu_other_queryOptions
            }

        }
    }
}
</script>