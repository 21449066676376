<template>
  <div class="detail">
    <div class="header" @click="$emit('changeVisible')">
      <div class="title">智慧截流井详情</div>
      <div class="extra">
        <a-icon type="close" />
      </div>
    </div>
    <div class="body">
      <a-tabs v-model="activeTab">
        <a-tab-pane key="1" tab="基本信息">
          <Info v-if="detail.name" :detail="detail" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="视频监控">
          <Monitor v-if="detail.id" :detail="detail" />
        </a-tab-pane>
        <a-tab-pane key="3" tab="统计数据">
          <Analysis v-if="detail.sn" :detail="detail" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
  
<script>
import Info from "./info.vue";
import Monitor from "./monitor.vue";
import Analysis from "./analysis.vue";

export default {
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    Info,
    Analysis,
    Monitor,
  },

  data() {
    return {
      activeTab: "1",
    };
  },
};
</script>
  
  
<style lang="less" scoped>
.detail {
  position: fixed;
  top: 11vh;
  right: 1vw;
  bottom: 2vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);

  width: 600px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  & > .header {
    background-color: var(--theme-color);
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
</style>