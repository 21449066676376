import { fetchConnection } from "../api";

import { LineLayer } from "@antv/l7";
import { mapState, mapMutations } from 'vuex'
export default {

    data() {
        return {
        }
    },

    computed: {
        ...mapState('gis', ['detail', 'category', 'type', 'selectedWell'])
    },

    methods: {

        ...mapMutations('gis', ['setConnectDetail']),

        onConnect(list, category) {


            if (this.connectLayer) {
                this.scene?.removeLayer(this.connectLayer);

            }


            if (list.length > 1) {
                const prev = list[0];
                const next = list[1];

                // const prev = this.selectedWell[this.selectedWell.length - 2];
                // const next = this.selectedWell[this.selectedWell.length - 1];

                fetchConnection({
                    category,
                    fromWellCode: prev.code,
                    fromWellType: prev.type,
                    toWellCode: next.code,
                    toWellType: next.type,
                }).then((res) => {
                    console.log("res", res);

                    if (res) {

                        this.setConnectDetail(res); // 给断面分析用

                        const list = []
                        this.setConnectPipe(list, res);
                        console.log('list', list)
                        if (list.length > 0) {
                            this.renderConnectPipe(list);

                            // 连通成功了，管线高亮了，就重置数据，让用户重新选
                            this.selectedWellForConnect = null;

                            if (this.firstWellLayerForConnect) {
                                this.scene?.removeLayer(this.firstWellLayerForConnect);
                            }


                        } else {
                            this.$message.error("暂无溯源信息")
                        }
                    }

                });
            } else {
                this.$message.error("请点击两个检查井");
            }


        },

        setConnectPipe(list, data) {
            if (data) {
                if (data.info && data.info.xfrom) {
                    list.push(data.info)
                }
                if (data.well && Array.isArray(data.well.pipes) && data.well.pipes.length > 0) {
                    data.well.pipes.forEach((item) => {
                        this.setConnectPipe(list, item);
                    });
                }
                if (Array.isArray(data.pipes) && data.pipes.length > 0) {
                    data.pipes.forEach((item) => {
                        this.setConnectPipe(list, item);
                    });
                }
            }
        },

        renderConnectPipe(list) {

            let thinList = []
            for (let i = 0; i < list.length; i++) {

                const item = list[i]

                thinList.push({
                    "type": "Feature",
                    "properties": {
                        id: item.id,
                    },
                    "geometry": {
                        "type": "LineString",
                        "coordinates": [
                            [parseFloat(item.xfrom), parseFloat(item.yfrom)],
                            [parseFloat(item.xto), parseFloat(item.yto)],
                        ]
                    }
                })


            }

            const sources = {
                "type": "FeatureCollection",
                "features": thinList
            }

            this.connectLayer = new LineLayer({
                zIndex: 200,
            }).source(sources).size(5)
                .shape('line')
                .color('red')
                .animate({
                    interval: 1, // 间隔
                    duration: 2, // 持续时间，延时
                    trailLength: 2 // 流线长度
                });

            this.scene?.addLayer(this.connectLayer);

        }



    }
}