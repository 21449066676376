import { fetchPipe } from "../api/rain";
import { LineLayer } from '@antv/l7'
import { mapState, mapMutations } from 'vuex'
import { getCenterPoint } from '@/utils/map'
export default {

    data() {
        return {
            rainpipeDetail: {},
            rainpipeVisible: false,
        }
    },

    computed: {
        ...mapState('gis', ['clickedMarkers'])
    },

    watch: {
        rainpipeVisible() {
            if (!this.rainpipeVisible) {
                this.closePipeActive();
            }
        },
    },

    methods: {
        getrainpipe() {
            if (!this.rainpipeLayer) {
                fetchPipe({
                }).then((res) => {
                    if (res && Array.isArray(res.locs)) {

                        this.setRainPipeRawList(Object.freeze(res.locs))

                        let thinList = []
                        let thickList = []
                        for (let i = 0; i < res.locs.length; i++) {
                            const item = res.locs[i]
                            if (item[5] === 'thin') {
                                thinList.push({
                                    "type": "Feature",
                                    "properties": {
                                        id: item[0],
                                        startLng: item[1],
                                        startLat: item[2],
                                        endLng: item[3],
                                        endLat: item[4]
                                    },
                                    "geometry": {
                                        "type": "LineString",
                                        "coordinates": [
                                            [parseFloat(item[1]), parseFloat(item[2])],
                                            [parseFloat(item[3]), parseFloat(item[4])],
                                        ]
                                    }
                                })
                            } else {
                                thickList.push({
                                    "type": "Feature",
                                    "properties": {
                                        id: item[0],
                                        startLng: item[1],
                                        startLat: item[2],
                                        endLng: item[3],
                                        endLat: item[4]
                                    },
                                    "geometry": {
                                        "type": "LineString",
                                        "coordinates": [
                                            [parseFloat(item[1]), parseFloat(item[2])],
                                            [parseFloat(item[3]), parseFloat(item[4])],
                                        ]
                                    }
                                })
                            }
                        }

                        this.thinRawList = thinList; // 保存原始数据
                        this.thickRawList = thickList;

                        const sources = {
                            "type": "FeatureCollection",
                            "features": thinList
                        }

                        const rainpipeLayer = new LineLayer({
                            zIndex: 10,
                            depth: true
                        }).source(sources).size(1)
                            .shape('line')
                            .color('#01C8E3')
                            .animate({
                                interval: 1, // 间隔
                                duration: 2, // 持续时间，延时
                                trailLength: 2 // 流线长度
                            });

                        rainpipeLayer.on('click', this.onrainpipeClick);
                        this.rainpipeLayer = rainpipeLayer;
                        this.scene?.addLayer(rainpipeLayer);


                        const sources2 = {
                            "type": "FeatureCollection",
                            "features": thickList
                        }

                        const rainpipeLayer2 = new LineLayer({
                            zIndex: 10,
                            depth: true
                        }).source(sources2).size(1)
                            .shape('line')
                            .color('#01C8E3')
                            .animate({
                                interval: 1, // 间隔
                                duration: 2, // 持续时间，延时
                                trailLength: 2 // 流线长度
                            });

                        rainpipeLayer2.on('click', this.onrainpipeClick);

                        this.rainpipeLayer2 = rainpipeLayer2;
                        this.scene?.addLayer(rainpipeLayer2);

                        this.onMoveEnd();

                    }
                });
            } else {
                this.rainpipeLayer?.show();
                this.rainpipeLayer2?.show();
                this.activerainpipeLayer?.show();
                if (this.rainpipeDetail.id) {
                    this.rainpipeVisible = true;
                }
                this.onMoveEnd();
            }
        },

        onrainpipeClick(e) {
            console.log("e", e);

            const { AMap } = window;
            if (!AMap) return;

            if (this.controlType === 'distance') {
                if (this.markerForDistance1) {

                    if (this.markerForDistance2) {
                        this.map?.remove(this.markerForDistance1)
                        this.map?.remove(this.markerForDistance2)

                        if (this.polylineForDistance) {
                            this.map?.remove(this.polylineForDistance)
                        }
                        if (this.textMarkerForDistance) {
                            this.map?.remove(this.textMarkerForDistance)
                        }
                        this.markerForDistance2 = null;
                        this.polylineForDistance = null;
                        this.textMarkerForDistance = null;

                        // 重新测量
                        this.markerForDistance1 = new AMap.Marker({
                            zIndex: 500,
                            position: new AMap.LngLat(e.lngLat.lng, e.lngLat.lat),
                            extData: {
                                ...e.feature.properties,
                            }
                        });
                        this.map?.add(this.markerForDistance1);
                        this.$message.info("请点击另一根管线")

                    } else {

                        this.markerForDistance2 = new AMap.Marker({
                            zIndex: 500,

                            position: [e.lngLat.lng, e.lngLat.lat],

                        });
                        this.map?.add(this.markerForDistance2);


                        const position1 = this.markerForDistance1.getPosition();
                        const position2 = this.markerForDistance2.getPosition();

                        this.polylineForDistance = new AMap.Polyline({
                            zIndex: 500,
                            strokeStyle: "dashed",
                            path: [
                                position1,
                                position2,
                            ],
                            borderWeight: 2, // 线条宽度，默认为 1
                            strokeColor: "red", // 线条颜色
                            strokeOpacity: 1,
                            lineJoin: "round", // 折线拐点连接处样式
                        });

                        // 将折线添加至地图实例
                        this.map.add(this.polylineForDistance);

                        const firstPipe = this.markerForDistance1.getExtData();
                        console.log('firstPipe', firstPipe)
                        const { endLat, endLng, startLat, startLng } = e.feature.properties;

                        const length = AMap.GeometryUtil.distanceToSegment([
                            parseFloat(firstPipe.startLng),
                            parseFloat(firstPipe.startLat)
                        ], [
                            parseFloat(startLng),
                            parseFloat(startLat)
                        ], [
                            parseFloat(endLng),
                            parseFloat(endLat),
                        ]);

                        // const length = getLength([
                        //   position1,
                        //   position2,
                        // ])
                        if (length) {
                            const msg = (`净距：${Math.round(length).toLocaleString()}米`);
                            console.log(msg)

                            const point = getCenterPoint(position1.lng + ',' + position1.lat, position2.lng + ',' + position2.lat)
                            console.log('point', point)

                            if (point.lng) {
                                this.textMarkerForDistance = new AMap.Text({
                                    text: msg,
                                    anchor: 'center', // 设置文本标记锚点
                                    style: {
                                        'text-align': 'center',
                                        'font-size': '14',
                                        'color': 'blue'
                                    },
                                    position: [point.lng, point.lat]
                                });
                                this.map.add(this.textMarkerForDistance)
                            }




                        } else {
                            this.$message.error('无法获取净距')
                        }

                    }


                } else {
                    // this.setSewageActive(e.feature.properties)
                    // this.setSewageActive(e.feature.properties.id, this.thickRawList)
                    this.markerForDistance1 = new AMap.Marker({
                        zIndex: 500,
                        position: [e.lngLat.lng, e.lngLat.lat],
                        extData: {
                            ...e.feature.properties,
                        }
                    });
                    this.map?.add(this.markerForDistance1);
                    this.$message.info("请点击另一根管线")
                }
                return;
            }

            if (this.controlType === 'source') {
                this.onSource({
                    pipeId: e.feature.properties.id,
                    category: 'rain',
                })
                return;
            }

            console.log("e", e);

            this.rainpipeDetail = e.feature.properties
            // this.rainpipeVisible = true;
            this.drainVisible = false;
            this.wellVisible = false;

            this.setPipeActive(e.feature.properties, 'rainpipeVisible')

            this.setClickedMarkers([...this.clickedMarkers, e.lngLat])
        },

        ...mapMutations('gis', ['setCrossList', 'setClickedMarkers', 'setRainPipeRawList']),

        removerainpipe() {
            this.rainpipeVisible = false;
            this.rainpipeLayer?.hide();
            this.rainpipeLayer2?.hide();
            this.activerainpipeLayer?.hide();

            // this.clearPipeText('rain');


        },

    }
}

