

 <template>
  <div
    style="
      background-color: var(--theme-color);
      color: #fff;
      padding: 4px 24px;
      border-radius: 100px;
      position: absolute;
      top: 2vh;
      right: 1vw;
      cursor: pointer;
    "
    @click="navigate"
  >
    <span>导航</span>

    <a-modal
      title="位置"
      :visible="visible"
      :footer="null"
      @cancel="visible = false"
      width="64vw"
    >
      <iframe
        :src="src"
        frameborder="0"
        style="width: 60vw; height: 66vh; margin: 0 auto; display: block"
      ></iframe>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    lng: {
      type: Number,
      default: 0,
    },
    lat: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    src() {
      return `https://uri.amap.com/marker?position=${this.lng},${this.lat}&callnative=1`;
    },
  },

  data() {
    return {
      visible: false,
    };
  },

  methods: {
    navigate() {
      const { lng } = this;
      if (lng) {
        this.visible = true;
      } else {
        this.$message.error("没有经纬度信息");
      }
    },
  },
};
</script>

<style>
</style>