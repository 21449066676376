<template>
  <div>
    <div class="detail">
      <div class="item" style="width: 20%">
        <div>
          <div>
            <a-icon class="icon" type="line-chart" />
          </div>
          <div>
            <div class="label">设备总数量</div>
            <span class="value">{{ detail.totalNum }}</span>
          </div>
        </div>
      </div>

      <div style="width: 25%">
        <Pie id="typeMap-facility" :info="renderType(detail.typeMap)" />
      </div>
    </div>

    <a-table
      bordered
      :data-source="list"
      :loading="loading"
      @change="onChange"
      :pagination="{
        total: idList.length,
        current,
        pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }"
    >
      <a-table-column title="编码" data-index="code" />
      <a-table-column title="名称" data-index="name" />
      <a-table-column title="类型" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="deviceType" :dictValue="text.type" />
        </template>
      </a-table-column>
      <a-table-column title="位置" data-index="location" align="center" />
    </a-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import request from "@/api/request";
import Pie from "./pie.vue";
export default {
  components: {
    Pie,
  },
  props: {
    idList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      current: 1,
      pageSize: 10,
      loading: false,
      list: [],
      detail: {},
    };
  },
  computed: {
    filteredIdList() {
      return this.idList.filter(
        (item, index) =>
          index < this.current * this.pageSize &&
          index >= (this.current - 1) * this.pageSize
      );
    },

    ...mapGetters("setting", ["findDataDict"]),
    deviceType() {
      return this.findDataDict("deviceType");
    },
  },

  watch: {
    idList() {
      this.getData();
    },
    visible() {
      if (this.visible) {
        if (this.current !== 1) {
          this.current = 1;
        } else {
          this.getList();
        }
      }
    },

    filteredIdList() {
      this.getList();
    },

    list() {
      console.log("list changed", this.list);
    },
  },

  mounted() {
    this.getData();
    if (this.current !== 1) {
      this.current = 1;
    } else {
      this.getList();
    }
  },

  methods: {
    getData() {
      if (this.idList.length === 0) {
        return;
      }
      request({
        url: "/model-analysis/pipe/area/stat",
        method: "post",
        data: {
          deviceIds: this.idList.map((item) => item.id),
          type: "device",
        },
      }).then((res) => {
        console.log("stat", res);
        if (res && res.devModel) {
          this.detail = res.devModel;
        }
      });
    },

    getList() {
      this.loading = true;

      request({
        url: "/model-analysis/pipe/area/device/query",
        method: "post",
        data: { ids: this.filteredIdList.map((item) => item.id) },
      })
        .then((res) => {
          console.log("facility res", res);
          if (Array.isArray(res)) {
            this.list = res;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
    },

    renderType(obj) {
      const result = {};
      if (typeof obj === "object" && obj) {
        Object.keys(obj).forEach((key) => {
          const dict = this.deviceType.find((item) => item.value === key);
          let name = key;
          if (dict) {
            name = dict.name;
          }
          result[name] = obj[key];
        });
      }
      console.log("result", result);
      return result;
    },
  },
};
</script>


<style lang="less" scoped>
.detail {
  display: flex;
  align-items: center;
  gap: 2em;
  margin-bottom: 2em;

  .item {
    display: flex;
    flex-direction: column;
    gap: 1em;
    & > div {
      display: flex;
      align-items: center;
    }
    .icon {
      font-size: 2.5em;
      margin-right: 12px;
      color: var(--theme-color);
    }
    .label {
      font-size: 0.9em;
      color: #999;
    }
    .value {
      font-size: 1.4em;
      margin-top: 0.4em;
      font-weight: bold;
      color: var(--theme-color);
    }
  }
}
</style>