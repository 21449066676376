<template>
    <div>
        <video id="video" controls autoplay ref="video"></video>

        <div class="monitor" v-if="ipc_name">
            <MonitorControlPanel :ipc_name="ipc_name" />
            <a-space>
                <a-button type="primary" @click="play_video">播放</a-button>
                <a-button @click="stop_video">中止</a-button>
                <a-button type="primary" @click="start_control(ipc_name)">控制云台</a-button>
                <a-button @click="stop_control(ipc_name)">结束控制</a-button>
            </a-space>
        </div>
        <div class="monitor" v-else>
            视频未接入
        </div>
    </div>
</template>

<script>
import { get_cutoff_ipcname_and_url } from "@/api/ipc_media/index.js";
import { start_control, stop_control } from "@/api/ipc_controller/index.js"
import MonitorControlPanel from "@/components/monitor-control-panel.vue"

export default {

    props: {
        detail: {
            type: Object,
            default: () => ({})
        }
    },

    components: {
        MonitorControlPanel,
    },

    data() {
        return {
            ipc_name: null,
            webrtc_url: null,

            // shengyu_cutoff_well_other_dict: {
            //     workMode: {
            //         '0': '自动',
            //         '1': '手动',
            //         '2': '远程手动',
            //     }
            // }
        }
    },

    watch: {
        detail: {
            handler() {
                this.stop_video()
                this.get_ipc_info()
            },
            deep: true,
        }
    },

    mounted() {
        this.get_ipc_info()
    },

    beforeDestroy() {
        console.log('destroyed')
        this.stop_video()
    },

    methods: {

        async get_ipc_info() {

            let res = await get_cutoff_ipcname_and_url(this.detail.id)
            console.log(res)
            /**获取IPC信息 */

            if (res.videoPropVo) {
                this.ipc_name = res.videoPropVo.ipcName
                this.webrtc_url = `http://223.75.200.168:18080/index/api/webrtc?app=${res.videoPropVo.appName}&stream=${res.videoPropVo.streamName}&type=play`
                this.play_video()
            }
        },
        start_control() {
            if (this.ipc_name) {
                start_control(this.ipc_name)
            }
        },
        stop_control() {
            if (this.ipc_name) {
                stop_control(this.ipc_name)
            }
        },
        play_video() {

            this.stop_video()
            console.log('playing')

            const url = this.webrtc_url;
            const video_element = this.$refs.video;
            let cfg = {
                element: video_element,
                debug: false,
                simulcast: false,
                zlmsdpUrl: url,
                resolution: { w: 1920, h: 1080 },
                videoEnable: true,
                audioEnable: false,
                useCamera: false,
                usedatachannel: false,
                recvOnly: true
            }
            this.player = new window.ZLMRTCClient.Endpoint(
                cfg
            );
        },
        stop_video() {
            if (this.player) {
                console.log('stop playing')
                this.player.close();
                this.player = null;
                var remote = this.$refs.video;
                if (remote) {
                    remote.srcObject = null;
                    remote.load();
                }
            }
        }
    }
}
</script>


<style lang="less" scoped>
#video {
    width: 100%;
}

.monitor {
    display: flex;
    align-items: center;
    padding: 2vh 2vw;
    gap: 2vh;
}
</style>