<template>
  <div class="wrapper">
    <FbxLoader
      v-if="GateStatus_B() === 1"
      url="/intercepting-well-open.fbx"
      type="well"
      :width="600"
      :height="320"
      :text="fbx_labels"
    />
    <FbxLoader
      v-if="GateStatus_B() === 0"
      url="/intercepting-well-close.fbx"
      type="well"
      :width="600"
      :height="320"
      :text="fbx_labels"
    />

    <div style="position: relative">
      <Navigator :lng="detail.x" :lat="detail.y" />

      <div style="padding: 2vh 1vw 0; font-weight: bold">
        名称：{{ detail.name }}
      </div>
      <div style="padding: 2vh 1vw; font-weight: bold">
        位置：{{ detail.location }}
      </div>
    </div>

    <div
      class="content"
      v-if="detail.subType === 'intel_cutoffWell_shengyu_yeya'"
    >
      <div>
        <div class="item">
          <div class="label">数据更新时间</div>
          <div class="value">
            {{ realTimeData.ts ? realTimeData.ts : "--" }}
          </div>
        </div>
      </div>
      <div class="item">
        <div class="label">井内液位(mm)</div>
        <div class="value">
          {{
            realTimeData.liquidLevelInner !== undefined
              ? realTimeData.liquidLevelInner + "mm"
              : "--"
          }}
        </div>
      </div>
      <div class="item">
        <div class="label">井外液位(mm)</div>
        <div class="value">
          {{
            realTimeData.liquidLevelOuter !== undefined
              ? (realTimeData.liquidLevelOuter > 0
                  ? realTimeData.liquidLevelOuter
                  : 0) + "mm"
              : "--"
          }}
        </div>
      </div>
      <div class="item">
        <div class="label">污水液位(mm)</div>
        <div class="value">
          {{
            realTimeData.liquidLevelDirtyPipe !== undefined
              ? (realTimeData.liquidLevelDirtyPipe > 0
                  ? realTimeData.liquidLevelDirtyPipe
                  : 0) + "mm"
              : "--"
          }}
        </div>
      </div>

      <div class="item">
        <div class="label">SS(mg/l)</div>
        <div class="value">
          {{ realTimeData.ss !== undefined ? realTimeData.ss : "--" }}
        </div>
      </div>
      <div class="item">
        <div class="label">雨量(dmm)</div>
        <div class="value">
          {{
            realTimeData.precipitation !== undefined
              ? realTimeData.precipitation
              : "--"
          }}
        </div>
      </div>

      <div class="item">
        <div class="label">1#气囊压力(kpa)</div>
        <div class="value">
          {{
            realTimeData.pressureAirbag1 !== undefined
              ? realTimeData.pressureAirbag1
              : "--"
          }}
        </div>
      </div>

      <div class="item">
        <div class="label">2#气囊压力(kpa)</div>
        <div class="value">
          {{
            realTimeData.pressureAirbag2 !== undefined
              ? realTimeData.pressureAirbag2
              : "--"
          }}
        </div>
      </div>
      <div class="item">
        <div class="label">1#闸门状态</div>
        <div class="value">
          {{
            realTimeData.isIntakeValveOpenAirbag1 !== undefined
              ? realTimeData.isIntakeValveOpenAirbag1 === 0
                ? "关"
                : "开"
              : "--"
          }}
        </div>
      </div>

      <div class="item">
        <div class="label">2#闸门状态</div>
        <div class="value">
          {{
            realTimeData.isIntakeValveOpenAirbag2 !== undefined
              ? realTimeData.isIntakeValveOpenAirbag2 === 0
                ? "关"
                : "开"
              : "--"
          }}
        </div>
      </div>

      <div class="item">
        <div class="label">工作状态</div>
        <div class="value">
          {{
            realTimeData.workStatus !== undefined
              ? realTimeData.workStatus
              : "--"
          }}
        </div>
      </div>

      <div class="item">
        <div class="label">运行模式</div>
        <div class="value">
          {{
            realTimeData.isAutoMode !== undefined
              ? realTimeData.isAutoMode == 1
                ? "自动"
                : "手动"
              : "--"
          }}
        </div>
      </div>
      <div class="item">
        <div class="label">外部采集SS(mg/l)</div>
        <div class="value">
          {{
            realTimeData.claatekSs !== undefined ? realTimeData.claatekSs : "--"
          }}
        </div>
      </div>
    </div>
    <!--Other类型的圣禹截流井-->
    <div
      class="content"
      v-if="detail.subType === 'intel_cutoffWell_shengyu_other'"
    >
      <div class="item">
        <div class="label">数据更新时间</div>
        <div class="value">{{ realTimeData.ts ? realTimeData.ts : "--" }}</div>
      </div>
      <div class="item">
        <div class="label">井内液位(mm)</div>
        <div class="value">
          {{
            realTimeData.liquidLevelInner !== undefined
              ? realTimeData.liquidLevelInner + "mm"
              : "--"
          }}
        </div>
      </div>
      <div class="item">
        <div class="label">井外液位(mm)</div>
        <div class="value">
          {{
            realTimeData.liquidLevelOuter !== undefined
              ? (realTimeData.liquidLevelOuter > 0
                  ? realTimeData.liquidLevelOuter
                  : 0) + "mm"
              : "--"
          }}
        </div>
      </div>
      <div class="item">
        <div class="label">污水液位(mm)</div>
        <div class="value">
          {{
            realTimeData.liquidLevelDirtyPipe !== undefined
              ? (realTimeData.liquidLevelDirtyPipe > 0
                  ? realTimeData.liquidLevelDirtyPipe
                  : 0) + "mm"
              : "--"
          }}
        </div>
      </div>

      <div class="item">
        <div class="label">SS(mg/l)</div>
        <div class="value">
          {{ realTimeData.ss !== undefined ? realTimeData.ss : "--" }}
        </div>
      </div>
      <div class="item">
        <div class="label">雨量(dmm)</div>
        <div class="value">
          {{
            realTimeData.precipitation !== undefined
              ? realTimeData.precipitation
              : "--"
          }}
        </div>
      </div>

      <div class="item">
        <div class="label">1#气囊压力(kpa)</div>
        <div class="value">
          {{
            realTimeData.pressureAirbag1 !== undefined
              ? realTimeData.pressureAirbag1
              : "--"
          }}
        </div>
      </div>
      <div class="item">
        <div class="label">1#气囊开度(%)</div>
        <div class="value">
          {{
            realTimeData.gateOpeningAirbag1 !== undefined
              ? realTimeData.gateOpeningAirbag1
              : "--"
          }}
        </div>
      </div>

      <div class="item">
        <div class="label">2#气囊压力(kpa)</div>
        <div class="value">
          {{
            realTimeData.pressureAirbag2 !== undefined
              ? realTimeData.pressureAirbag2
              : "--"
          }}
        </div>
      </div>
      <div class="item">
        <div class="label">2#气囊开度(%)</div>
        <div class="value">
          {{
            realTimeData.gateOpeningAirbag2 !== undefined
              ? realTimeData.gateOpeningAirbag2
              : "--"
          }}
        </div>
      </div>

      <div class="item">
        <div class="label">工作状态</div>
        <div class="value">
          {{
            realTimeData.workStatus !== undefined
              ? realTimeData.workStatus
              : "--"
          }}
        </div>
      </div>

      <div class="item">
        <div class="label">运行模式</div>
        <div class="value">
          {{
            realTimeData.workMode !== undefined
              ? shengyu_cutoff_well_other_dict.workMode[
                  realTimeData.workMode.toString()
                ]
              : "--"
          }}
        </div>
      </div>
      <div class="item">
        <div class="label">外部采集SS(mg/l)</div>
        <div class="value">
          {{
            realTimeData.claatekSs !== undefined ? realTimeData.claatekSs : "--"
          }}
        </div>
      </div>
    </div>

    <!--main类型的祺润截流井-->
    <div
      class="content"
      v-if="detail.subType === 'intel_cutoffWell_qirun_main'"
    >
      <div class="item">
        <div class="label">数据更新时间</div>
        <div class="value">{{ realTimeData.ts ? realTimeData.ts : "--" }}</div>
      </div>
      <div class="item">
        <div class="label">液位(m)</div>
        <div class="value">
          {{
            realTimeData.liquidLevel !== undefined
              ? realTimeData.liquidLevel + "m"
              : "--"
          }}
        </div>
      </div>

      <div class="item">
        <div class="label">外部采集SS(mg/l)</div>
        <div class="value">
          {{
            realTimeData.claatekSs !== undefined ? realTimeData.claatekSs : "--"
          }}
        </div>
      </div>
      <div class="item">
        <div class="label">雨量(dmm)</div>
        <div class="value">
          {{
            realTimeData.rainfall !== undefined ? realTimeData.rainfall : "--"
          }}
        </div>
      </div>

      <div class="item">
        <div class="label">油压(kpa)</div>
        <div class="value">
          {{
            realTimeData.gateOilPressure !== undefined
              ? realTimeData.gateOilPressure
              : "--"
          }}
        </div>
      </div>
      <div class="item">
        <div class="label">截留闸开度</div>
        <div class="value">
          {{
            realTimeData.cutoffGateOpening !== undefined
              ? realTimeData.cutoffGateOpening
              : "--"
          }}
        </div>
      </div>

      <div class="item">
        <div class="label">出水闸开度</div>
        <div class="value">
          {{
            realTimeData.outputGateOpening !== undefined
              ? realTimeData.outputGateOpening
              : "--"
          }}
        </div>
      </div>
      <div class="item">
        <div class="label">cod</div>
        <div class="value">
          {{ realTimeData.cod !== undefined ? realTimeData.cod : "--" }}
        </div>
      </div>

      <div class="item">
        <div class="label">油温报警状态位</div>
        <div class="value">
          {{ realTimeData.isOilTemperatureWarn ? "异常" : "正常" }}
        </div>
      </div>

      <div class="item">
        <div class="label">油泵液位报警状态位</div>
        <div class="value">
          {{
            realTimeData.isOilLiquidLevelWarn !== undefined
              ? realTimeData.isOilLiquidLevelWarn
              : "--"
          }}
        </div>
      </div>

      <div class="item">
        <div class="label">运行模式</div>
        <div class="value">
          {{
            realTimeData.isLocalMode === true
              ? "本地模式"
              : realTimeData.isAutoMode === true
              ? "自动模式"
              : "unknown"
          }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FbxLoader from "@/components/fbx-loader.vue";
import request from "@/api/request";
import Navigator from "@/components/navigator";

export default {
  components: {
    FbxLoader,
    Navigator,
  },

  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      realTimeData: {},

      fbx_labels: [],

      shengyu_cutoff_well_other_dict: {
        workMode: {
          0: "自动",
          1: "手动",
          2: "远程手动",
        },
      },
    };
  },

  watch: {
    detail: {
      handler() {
        console.log("onchange handler", this.detail);
        this.getRealTimeData();
      },
      deep: true,
    },
  },

  mounted() {
    this.getRealTimeData();
  },

  methods: {
    getRealTimeData() {
      if (!this.detail.sn || !this.detail.subType) {
        return;
      }

      request({
        url: "/data-service/dev/realTime",
        method: "post",
        data: {
          devType: this.detail.subType, // 获取类型
          eids: [this.detail.sn],
        },
      })
        .then((res) => {
          this.realTimeData = res;
          // 获取数据后，修改3D模型中的标签
          // 2023-09-18 新加入的祺润截流井只有一个液位，将逻辑摘出正文，如果是祺润截流井，只显示井内液位

          if (this.detail.subType === "intel_cutoffWell_qirun_main") {
            this.fbx_labels = [
              {
                text: `井内液位：${res.liquidLevel} m`,
                position: [0, -25, 60],
              },
            ];
          } else {
            this.fbx_labels = [
              // {
              //   text: "管底标高：xxxm", // 等后端整理了数据之后再做
              //   position: [0, 2, 60],
              // },
              {
                text: `井外液位：${
                  res.liquidLevelOuter > 0 ? res.liquidLevelOuter : 0
                }m`,
                position: [0, 5, 0],
              },
              {
                text: `井内液位：${res.liquidLevelInner}mm`,
                position: [0, -25, 60],
              },
              // {
              //     text: `闸门A开关：${this.GateStatus_A()}`,
              //     position: [-20, -10, 30],
              // },
              // {
              //     text: `闸门B开关：${this.GateStatus_B()}`,
              //     position: [20, -10, 0],
              // }
            ];
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    GateStatus_A() {
      /** 排水门A开关状态 */
      if (this.detail.subType === "intel_cutoffWell_shengyu_other") {
        // donothin
        return this.realTimeData.gateOpeningAirbag1 > 50 ? 1 : 0;
      } else if (this.detail.subType === "intel_cutoffWell_shengyu_yeya") {
        // donothin
        return this.realTimeData.isIntakeValveOpenAirbag1;
      } else if (this.detail.subType === "intel_cutoffWell_shengyu_fenliu") {
        // donothin
        return this.realTimeData.gateOpening1 > 50 ? 1 : 0;
      } else if (this.detail.subType === "intel_cutoffWell_qirun_main") {
        return this.realTimeData.cutoffGateOpening > 50 ? 1 : 0;
      }
      return 0;
    },
    GateStatus_B() {
      /** 排水门B开关状态 */
      if (this.detail.subType === "intel_cutoffWell_shengyu_other") {
        // donothin
        return this.realTimeData.gateOpeningAirbag2 > 50 ? 1 : 0;
      } else if (this.detail.subType === "intel_cutoffWell_shengyu_yeya") {
        return this.realTimeData.isIntakeValveOpenAirbag2;
        // donothin
      } else if (this.detail.subType === "intel_cutoffWell_shengyu_fenliu") {
        // donothin
        return 0; // 分流井没有这个值
      } else if (this.detail.subType === "intel_cutoffWell_qirun_main") {
        return this.realTimeData.outputGateOpening > 50 ? 1 : 0;
      }
      return 0;
    },
  },
};
</script>


<style lang="less" scoped>
.wrapper {
  //   overflow-y: auto;
  //   max-height: 70vh;
}

.content {
  padding: 0 1vw 2vh;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5vh 1em;

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;

    .label {
      min-width: 4em;
    }

    .value {
      color: var(--theme-color);
    }
  }
}
</style>