
import { fetchList } from "@/api/area";
import { PolygonLayer } from '@antv/l7'

export default {

    data() {
        return {
            isDistrictHightLight: true,
        }
    },

    methods: {

        renderDistrict() {
            fetchList().then((res) => {
                if(!res.locs.length){
                    return
                }
                const locs = res.locs

                const sources = {
                    type: 'FeatureCollection',
                    features: [
                        {
                            type: 'Feature',
                            properties: {},
                            geometry: {
                                type: 'Polygon',
                                coordinates: [
                                    locs,
                                ],
                            },
                        },
                    ],
                }

                const layer = new PolygonLayer({
                    zIndex: -5,
                    depth: true
                }).source(sources)
                    .shape('fill')
                    .color('#d7efe4')
                    .style({
                        opacity: 0.5,
                    })

                this.scene?.addLayer(layer);
                console.log(this.scene)
            });

        },

        setHighLight(status) {
            if (status) {
                this.isDistrictHightLight = true;
                this.polygon?.show();
            } else {
                this.isDistrictHightLight = false;
                this.polygon?.hide();
            }
        }

    }

}