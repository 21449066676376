<template>
  <div class="detail">
    <div class="header" @click="$emit('changeVisible')">
      <div class="title">视频监控详情</div>
      <div class="extra">
        <a-icon type="close" />
      </div>
    </div>

    <video src="#" id="video" controls autoplay ref="video"></video>

    <div class="content" style="position: relative">
      <div class="item">
        <div class="label">编号：</div>
        <div class="value">{{ renderDetail.code }}</div>
      </div>

      <div class="item">
        <div class="label">名称：</div>
        <div class="value">{{ renderDetail.name }}</div>
      </div>

      <div class="item">
        <div class="label">地址：</div>
        <div class="value">{{ renderDetail.location }}</div>
      </div>

      <div v-if="ipc_name == null" class="item">
        <div class="value">视频未接入</div>
      </div>
      <div v-else class="ptz_panel">
        <div class="left-panel">
          <a-button @click="play_video">播放</a-button>
          <a-button @click="stop_video">中止</a-button>
          <a-button @click="start_control(ipc_name)">控制云台</a-button>
          <a-button @click="stop_control(ipc_name)">结束控制</a-button>
        </div>
        <div class="right-panel">
          <MonitorControlPanel :ipc_name="ipc_name" />
        </div>
      </div>

      <Navigator :lng="detail.x" :lat="detail.y" />
    </div>
  </div>
</template>

<script>
//import { ZLMRTCClient } from "../utils/ZLMRTCClient.js"
import { start_control, stop_control } from "../api/ipc_controller/index.js";
import { get_ipcname_and_url } from "../api/ipc_media/index.js";
import MonitorControlPanel from "./monitor-control-panel.vue";

import Navigator from "@/components/navigator";

// 放到index.html里引入了
export default {
  components: {
    MonitorControlPanel,
    Navigator,
  },
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      ipc_name: null,
      webrtc_url: null,
      player: null,
    };
  },
  watch: {
    detail: function (newVal, oldVal) {
      // do something when propA changes
      if (newVal !== oldVal) {
        console.log("prop切换了");
        this.stop_video();
        this.get_ipc_info();
      }
    },
  },

  computed: {
    renderDetail() {
      return this.detail;
    },
  },
  beforeDestroy() {
    console.log("destroyed");
    this.stop_video();
  },
  mounted() {
    this.get_ipc_info();
  },
  methods: {
    async get_ipc_info() {
      //this.detail.
      let res = await get_ipcname_and_url(this.detail.id);
      console.log(res);
      /**获取IPC信息 */
      if (res.videoPropVo === undefined) {
        this.ipc_name = null;
        this.webrtc_url = null;
        return;
      } else {
        this.ipc_name = res.videoPropVo.ipcName;
        this.webrtc_url = `http://223.75.200.168:18080/index/api/webrtc?app=${res.videoPropVo.appName}&stream=${res.videoPropVo.streamName}&type=play`;
        this.play_video();
      }
    },
    start_control() {
      if (this.ipc_name !== undefined) {
        start_control(this.ipc_name);
      }
    },
    stop_control() {
      if (this.ipc_name !== undefined) {
        stop_control(this.ipc_name);
      }
    },
    play_video() {
      this.stop_video();
      console.log("playing");

      const url = this.webrtc_url;
      const video_element = this.$refs.video;
      let cfg = {
        element: video_element,
        debug: false,
        simulcast: false,
        zlmsdpUrl: url,
        resolution: { w: 1920, h: 1080 },
        videoEnable: true,
        audioEnable: false,
        useCamera: false,
        usedatachannel: false,
        recvOnly: true,
      };
      this.player = new window.ZLMRTCClient.Endpoint(cfg);
    },
    stop_video() {
      if (this.player != null) {
        console.log("stop playing video");
        this.player.close();
        this.player = null;
        var remote = this.$refs.video;
        if (remote) {
          remote.srcObject = null;
          remote.load();
        }
      }
    },
  },
};
</script>


<style lang="less" scoped>
.detail {
  position: fixed;
  top: 11vh;
  right: 1vw;
  bottom: 2vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);

  width: 50vw;
  border-radius: 10px;

  & > .header {
    background-color: var(--theme-color);
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .extra {
      cursor: pointer;
    }
  }

  #video {
    width: 100%;
    padding: 0.5rem;
  }

  .content {
    padding: 2vh 1vw;
    display: flex;
    flex-direction: column;
    gap: 1vh;

    .item {
      display: flex;
      align-items: center;

      .label {
        width: 4em;
      }

      .value {
        color: var(--theme-color);
      }
    }
  }
}

.ptz_panel {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 2rem;

  .left-panel {
    display: flex;
    flex-direction: column;
  }

  .right-panel {
  }
}
</style>