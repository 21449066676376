import request from '@/api/request'

export function fetchPipe(data) {
    return request({
        url: '/model-analysis/pipe/rain/locations',
        method: 'post',
        data
    })
}

export function fetchPipeDetail(data) {
    return request({
        url: '/model-analysis/pipe/rain/query',
        method: 'post',
        data
    })
}

export function fetchWell(data) {
    return request({
        url: '/model-analysis/pipe/rain/well/locations',
        method: 'post',
        data
    })
}

export function fetchWellDetail(data) {
    return request({
        url: '/model-analysis/pipe/rain/well/query',
        method: 'post',
        data
    })
}

// 排口
export function fetchDrain(data) {
    return request({
        url: '/model-analysis/pipe/rain/drain/locations',
        method: 'post',
        data
    })
}

export function fetchDrainDetail(data) {
    return request({
        url: '/model-analysis/pipe/rain/drain/query',
        method: 'post',
        data
    })
}

// 设备详情
export function fetchFacility(data) {
    return request({
        url: '/model-analysis/pipe/rain/device/query',
        method: 'post',
        data
    })
}