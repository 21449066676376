
export function getLength(list) {
    if (window.AMap) {
        // 返回两点间的地面距离，单位：米
        const distance = window.AMap.GeometryUtil.distanceOfLine([
            [list[0].lng, list[0].lat],
            [list[1].lng, list[1].lat],
        ]);
        return distance
    }
}

export function getCenterPoint(startAxis, endAxis) {
    let arr = [startAxis, endAxis];
    let total = arr.length;
    let X = 0, Y = 0, Z = 0;
    for (let i = 0; i < arr.length; i++) {
        let lat, lon, x, y, z;
        lon = parseFloat(arr[i].split(",")[0]) * Math.PI / 180;
        lat = parseFloat(arr[i].split(",")[1]) * Math.PI / 180;
        x = Math.cos(lat) * Math.cos(lon);
        y = Math.cos(lat) * Math.sin(lon);
        z = Math.sin(lat);
        X += x;
        Y += y;
        Z += z;
    }
    X = X / total;
    Y = Y / total;
    Z = Z / total;
    let Lon = Math.atan2(Y, X);
    let Hyp = Math.sqrt(X * X + Y * Y);
    let Lat = Math.atan2(Z, Hyp);
    return {
        lng: (Lon * 180 / Math.PI),
        lat: (Lat * 180 / Math.PI)
    }
}


// 计算经纬度旋转角度


// export function getAngle(lng1, lat1, lng2, lat2) {
//     const firstPoint = {
//         lng: lng1,
//         lat: lat1,
//     }
//     const nextPoint = {
//         lng: lng2,
//         lat: lat2
//     }

//     let dRotateAngle = Math.atan2(
//         Math.abs(firstPoint.lng - nextPoint.lng),
//         Math.abs(firstPoint.lat - nextPoint.lat)
//     );
//     if (nextPoint.lng >= firstPoint.lng) {
//         if (nextPoint.lat < firstPoint.lat) {
//             dRotateAngle = Math.PI - dRotateAngle;

//         }
//     } else {
//         if (nextPoint.lat >= firstPoint.lat) {
//             dRotateAngle = 2 * Math.PI - dRotateAngle;
//         } else {
//             dRotateAngle = Math.PI + dRotateAngle;
//         }
//     }
//     dRotateAngle = (dRotateAngle * 180) / Math.PI;
//     return dRotateAngle;
// }



export function getAngle(lng1, lat1, lng2, lat2) {
    let angle = Math.atan2(
        Math.abs(lat1 - lat2),
        Math.abs(lng1 - lng2),
    ) * 180 / Math.PI;


    if (lng2 > lng1 && lat2 > lat1) {
        // 第一象限
        angle = -angle
    } else if (lng2 > lng1 && lat2 < lat1) {
        // 第二象限
        // angle = angle + 90;
        // angle = 
    } else if (lng2 < lng1 && lat2 < lat1) {
        // 第三象限
        angle = -angle;
        // angle = angle + 180;
    } else if (lng2 < lng1 && lat2 > lat1) {
        // 第四象限
        // angle = angle + 270;
    }


    return angle
}