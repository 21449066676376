<template>
  <a-modal title="区域内设施" :footer="null" :visible="visible" width="1200px" @cancel="$emit('close')">
    <div style="margin-top: -16px">
      <a-tabs v-model="activeTab">
        <a-tab-pane key="pipe" tab="管线">
          <area-pipe :idList="pipeIdList" :visible="visible" />
        </a-tab-pane>
        <a-tab-pane key="well" tab="检查井">
          <area-well :idList="wellIdList" :visible="visible" />
        </a-tab-pane>
        <a-tab-pane key="facility" tab="设备">
          <area-facility :idList="facilityIdList" :visible="visible" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </a-modal>
</template>

<script>
import AreaPipe from "./area-pipe.vue";
import AreaWell from "./area-well.vue";
import AreaFacility from "./area-facility.vue";

export default {
  components: { AreaWell, AreaPipe, AreaFacility },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    idList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      activeTab: "pipe",
    };
  },
  computed: {
    pipeIdList() {
      return this.idList.filter(
        (item) => item.type === "sewage" || item.type === "rain"
      );
    },

    wellIdList() {
      return this.idList.filter((item) => item.type.indexOf("well") > -1);
    },

    facilityIdList() {
      return this.idList.filter((item) => item.type.indexOf("facility") > -1);
    },
  },
};
</script>