import { fetchWell } from "../api/rain";
import { PointLayer } from '@antv/l7'
import { fetchWellDetail as fetchRainWellDetail } from "@/api/rain";

export default {

    data() {
        return {
            rainWellLoading: false,
            rainwellDetail: {},
            rainwellVisible: false,
        }
    },

    methods: {
        getrainwell() {

            if(this.rainWellLoading) return;

            const zoom = this.map?.getZoom();
            if (zoom < 16.7) {
                return;
            }

            if (this.selected.indexOf('雨水检查井') === -1) {
                return;
            }

            if (!this.rainWellLayer) {

                this.rainWellLoading = true;

                fetchWell({
                }).then((res) => {
                    if (res && Array.isArray(res.locs)) {
                        const list = Object.freeze(res.locs);

                        this.setRainWellRawList(list)

                        this.rainWellLayer = new PointLayer({
                            zIndex: 100,
                        })

                            .source(list.map(item => {
                                return {
                                    id: item[0],
                                    type: item[1],
                                    x: item[3],
                                    y: item[4],
                                }
                            }), {
                                parser: {
                                    type: 'json',
                                    x: 'x',
                                    y: 'y'
                                }
                            })
                            .shape('rainWell')
                            .size(zoom * 0.55)

                        this.rainWellLayer.on('click', this.rainWellClick)
                        this.scene?.addLayer(this.rainWellLayer);

                        // if (this.isShowWellId) {
                        //     this.showRainWellId();
                        // }

                    }
                }).finally(()=>{
                    this.rainWellLoading = false;
                })

            } else {
                this.rainWellLayer.show();
                // if (this.isShowWellId) {
                //     this.showRainWellId();
                // }
            }

        },

        rainWellClick(e) {
            console.log('e', e)

            if (this.controlType === 'source') {
                fetchRainWellDetail({
                    ids: [e.feature.id],
                    type: "rain",
                }).then(res => {
                    if (Array.isArray(res) && res.length > 0) {
                        const item = res[0];

                        this.onSource({
                            facilityCode: item.code,
                            category: 'rain',
                        })

                    }
                })

                return;
            }

            if (this.controlType === 'connect') {

                if (this.selectedWellForConnect) {

                    fetchRainWellDetail({
                        ids: [e.feature.id],
                        type: "rain",
                    }).then(res => {
                        if (Array.isArray(res) && res.length > 0) {
                            const item = res[0];
                            this.onConnect([this.selectedWellForConnect, item], 'rain')
                        }
                    })

                } else {

                    if (this.connectLayer) {
                        this.scene?.removeLayer(this.connectLayer);
                    }

                    const zoom = this.map?.getZoom();
                    const { id, x, y } = e.feature;

                    this.firstWellLayerForConnect = new PointLayer({
                        zIndex: 200,
                    })

                        .source([{
                            id,
                            x: parseFloat(x),
                            y: parseFloat(y)
                        }], {
                            parser: {
                                type: 'json',
                                x: 'x',
                                y: 'y'
                            }
                        })
                        .shape('simple')
                        .color('red')
                        .size(zoom * 0.55)

                    this.scene?.addLayer(this.firstWellLayerForConnect)

                    fetchRainWellDetail({
                        ids: [e.feature.id],
                        type: "rain",
                    }).then(res => {
                        if (Array.isArray(res) && res.length > 0) {
                            const item = res[0];
                            this.selectedWellForConnect = item;
                            this.$message.info('请继续点击检查井')
                        }
                    })


                }

                return;

            }


            this.rainwellDetail = e.feature;
            this.setFacilityActive(e.feature, 'rainwellVisible');
        },

        removerainwell() {
            this.rainwellVisible = false;
            this.rainWellLayer?.hide();
            console.log('hide rain well')
            // this.hideRainWellId();
        },

        // showRainWellId() {

        //     if (this.rainWellIdLayer) {
        //         this.rainWellIdLayer.show();
        //         return;
        //     }

        //     if (this.rainWellRawList.length > 0) {
        //         this.rainWellIdLayer = new PointLayer({
        //             zIndex: 600,
        //         })
        //             .source(this.rainWellRawList.map(item => ({
        //                 code: item[5],

        //                 type: item[1],
        //                 x: item[3],
        //                 y: item[4],
        //             })), {
        //                 parser: {
        //                     type: 'json',
        //                     x: 'x',
        //                     y: 'y'
        //                 }
        //             })
        //             .shape('code', 'text')
        //             .size(10)
        //             .color('#01C8E3')
        //             .style({
        //                 fontWeight: '900',
        //                 textAnchor: 'center', // 文本相对锚点的位置 center|left|right|top|bottom|top-left
        //                 textOffset: [64, 32], // 文本相对锚点的偏移量 [水平, 垂直]
        //                 spacing: 1, // 字符间距
        //                 padding: [1, 1], // 文本包围盒 padding [水平，垂直]，影响碰撞检测结果，避免相邻文本靠的太近
        //             });
        //         this.scene?.addLayer(this.rainWellIdLayer);
        //     }


        // },
        // hideRainWellId() {
        //     this.rainWellIdLayer?.hide();

        // }

    }
}

