<template>
    <div class="ptz">
        <div class="outer-ring">
            <!-- 上 -->
            <a-icon type="caret-up" class="caret-up" @mousedown="move('up', 1)" @mouseup="move('up', 0)" />
            <!-- 下 -->
            <a-icon type="caret-down" class="caret-down" @mousedown="move('down', 1)" @mouseup="move('down', 0)" />
            <!-- 左 -->
            <a-icon type="caret-left" class="caret-left" @mousedown="move('left', 1)" @mouseup="move('left', 0)" />
            <!-- 右 -->
            <a-icon type="caret-right" class="caret-right" @mousedown="move('right', 1)" @mouseup="move('right', 0)" />


            <div v-show="!isQirunCutoff" class="inner-ring">
                <a-icon type="plus" class="plus" @mousedown="zoom('in', 1)" @mouseup="zoom('in', 0)" />
                <div class="line"></div>
                <a-icon type="minus" class="minus" @mousedown="zoom('out', 1)" @mouseup="zoom('out', 0)" />
            </div>
            <!-- 左上 -->
            <!-- <a-icon type="caret-left" class="ob-caret-left" @click="ptzController(4)" /> -->
            <!-- 右上 -->
            <!-- <a-icon type="caret-up" class="ob-caret-up" @click="ptzController(6)"  /> -->
            <!-- 右下 -->
            <!-- <a-icon type="caret-right" class="ob-caret-right" @click="ptzController(7)" /> -->
            <!-- 左下 -->
            <!-- <a-icon type="caret-down" class="ob-caret-down" @click="ptzController(5)" /> -->
        </div>
    </div>
</template>
   
<script>
import { send_move_consist, send_zoom } from "../api/ipc_controller/index.js"
export default {
    props: {
        ipc_name: String
    },
    data() {
        return {}
    },
    methods: {
        move(direction, flag) {
            console.log("send move.")
            send_move_consist(
                this.ipc_name,
                direction,
                flag
            )
        },
        zoom(direction, flag) {
            send_zoom(
                this.ipc_name,
                direction,
                flag
            )
        }
    },
    computed: {
        isQirunCutoff() {
            if (this.ipc_name.includes("qr")) {
                return true
            }
            return false
        }
    }
}
</script>
   
<style lang="less" scoped>
.ptz {
    .outer-ring {
        position: relative;
        width: 150px;
        height: 150px;
        background-color: #000;
        border-radius: 50%;
        box-shadow: inset 0 0 25px #e8e8e8, 0 1px 0 #c3c3c3, 0 2px 0 #c9c9c9, 0 2px 3px #333;

        i {
            font-size: 20px;
            cursor: pointer;

            &:hover {
                color: #00B176;
            }
        }

        .caret-up {
            position: absolute;
            top: 6px;
            left: 75px;
            margin-left: -10px;
        }

        .caret-down {
            position: absolute;
            bottom: 6px;
            left: 75px;
            margin-left: -10px;
        }

        .caret-left {
            position: absolute;
            left: 6px;
            top: 75px;
            margin-top: -10px;
        }

        .caret-right {
            position: absolute;
            right: 6px;
            top: 75px;
            margin-top: -10px;
        }

        .ob-caret-left {
            transform: rotate(45deg);
            position: absolute;
            top: 24px;
            left: 24px;
        }

        .ob-caret-up {
            transform: rotate(45deg);
            position: absolute;
            top: 24px;
            right: 24px;
        }

        .ob-caret-right {
            transform: rotate(45deg);
            position: absolute;
            bottom: 24px;
            right: 24px;
        }

        .ob-caret-down {
            transform: rotate(45deg);
            position: absolute;
            bottom: 24px;
            left: 24px;
        }

        .inner-ring {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background-color: #000;
            border: 1px solid #ddd;

            .plus {
                position: absolute;
                top: 10px;
                left: 40px;
                margin-left: -10px;
            }

            .line {
                height: 1px;
                width: 100%;
                background-color: #ddd;
                position: absolute;
                top: 39px;
                left: 0;
            }

            .minus {
                position: absolute;
                bottom: 10px;
                left: 40px;
                margin-left: -10px;
            }
        }
    }
}
</style>