<template>
  <div :id="id"></div>
</template>


<script>
import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  LineChart,
  CanvasRenderer,
  TooltipComponent,
  GridComponent,
  LegendComponent,
]);
export default {
  props: {
    id: {
      type: String,
      default: "liquid-line-chart",
    },
    list: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    list() {
      this.renderChart();
    },
  },

  mounted() {
    var chartDom = document.getElementById(this.id);
    chartDom.style.height = "30vh";
    chartDom.style.width = "24vw";
    this.chart = echarts.init(chartDom);
    this.renderChart();
  },

  methods: {
    renderChart() {
      var option = {
        legend: { textStyle: { color: '#fff' },
          show: true,
        },

        xAxis: {
          type: "category",
          data: this.list.map((item) => item.ts),
          splitLine: {
            show: true,
          },
          axisLine: {
            show: true,
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
          },
          axisLabel: {},
        },

        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },

        grid: {
          show: false,
          left: "10%",
          top: "10%",
          right: "0%",
          bottom: "15%",
        },

        series: [
          {
            name: "雨量(mm)",
            type: "line",
            smooth: true,
            data: this.list.map((item) => item.rainfall),
          },
        ],
      };

      option && this.chart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped></style>