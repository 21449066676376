<template>
  <div class="content">
    <div class="item">
      <div class="label">编号：</div>
      <div class="value">{{ renderDetail.code }}</div>
    </div>
    <div class="item">
      <div class="label">类型：</div>
      <div class="value">
        <DataDictFinder
          dictType="sewageWellType"
          :dictValue="renderDetail.type"
        />
      </div>
    </div>
    <div class="item">
      <div class="label">附属物：</div>
      <div class="value">
        {{ renderDetail.accessary ? renderDetail.accessary : "--" }}
      </div>
    </div>

    <div class="item">
      <div class="label">井盖直径：</div>
      <div class="value">{{ renderDetail.diameterCover }}mm</div>
    </div>
    <div class="item">
      <div class="label">井室直径：</div>
      <div class="value">{{ renderDetail.diameterRoom }}mm</div>
    </div>

    <div class="item">
      <div class="label">所在道路：</div>
      <div class="value">{{ renderDetail.location }}</div>
    </div>

    <div class="item">
      <div class="label">井盖材质：</div>
      <div class="value">{{ renderDetail.materialCover }}</div>
    </div>

    <div class="item">
      <div class="label">检查井材质：</div>
      <div class="value">{{ renderDetail.materialWell }}</div>
    </div>

    <div class="item">
      <div class="label">井盖形状：</div>
      <div class="value">{{ renderDetail.shapeCover }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    renderDetail: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>


<style lang="less" scoped>
.content {
  padding: 0 1vw 2vh;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1vh;

  .item {
    display: flex;
    align-items: center;

    .label {
      width: 6em;
    }

    .value {
      color: var(--theme-color);
    }
  }
}
</style>