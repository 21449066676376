<template>
  <div>
    <div class="detail">
      <div class="item">
        <div>
          <div>
            <a-icon class="icon" type="line-chart" />
          </div>
          <div>
            <div class="label">检查井总数量</div>
            <span class="value">{{ detail.totalNum }}</span>
          </div>
        </div>
      </div>

      <div>
        <Pie id="typeMap" :info="detail.typeMap" />
      </div>
      <div>
        <Pie id="categoryMap2" :info="detail.categoryMap" />
      </div>
    </div>

    <a-table
      bordered
      :data-source="list"
      :loading="loading"
      @change="onChange"
      :pagination="{
        total: idList.length,
        current,
        pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }"
    >
      <a-table-column title="编码" data-index="code" />
      <a-table-column title="类型" align="center">
        <template slot-scope="text">
          <DataDictFinder
            dictType="sewageWellType"
            :dictValue="text.type"
            v-if="text.category === 'sewage'"
          />
          <DataDictFinder
            dictType="rainWellType"
            :dictValue="text.type"
            v-if="text.category === 'rain'"
          />
        </template>
      </a-table-column>
      <a-table-column
        title="地面高程(m)"
        data-index="elevation"
        align="right"
      />
      <a-table-column title="井深(m)" data-index="depth" align="right" />
      <a-table-column title="材质" data-index="materialWell" align="center" />
    </a-table>
  </div>
</template>

<script>
import { fetchWellDetail } from "../api/sewage";
import { fetchWellDetail as fetchRainWellDetail } from "../api/rain";
import request from "@/api/request";
import Pie from "./pie.vue";
export default {
  components: {
    Pie,
  },
  props: {
    idList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      current: 1,
      pageSize: 10,
      loading: false,
      list: [],
      detail: {},
    };
  },
  computed: {
    filteredIdList() {
      return this.idList.filter(
        (item, index) =>
          index < this.current * this.pageSize &&
          index >= (this.current - 1) * this.pageSize
      );
    },
  },

  watch: {
    idList() {
      this.getData();
    },
    visible() {
      if (this.visible) {
        if (this.current !== 1) {
          this.current = 1;
        } else {
          this.getList();
        }
      }
    },

    filteredIdList() {
      this.getList();
    },

    list() {
      console.log("list changed", this.list);
    },
  },

  mounted() {
    this.getData();
    if (this.current !== 1) {
      this.current = 1;
    } else {
      this.getList();
    }
  },

  methods: {
    getData() {
      if (this.idList.length === 0) {
        return;
      }
      request({
        url: "/model-analysis/pipe/area/stat",
        method: "post",
        data: {
          rainIds: this.idList
            .filter((item) => item.type === "rain-well")
            .map((item) => item.id),
          sewageIds: this.idList
            .filter((item) => item.type === "sewage-well")
            .map((item) => item.id),
          type: "well",
        },
      }).then((res) => {
        console.log("stat", res);
        if (res && res.wellModel) {
          this.detail = res.wellModel;
        }
      });
    },

    getList() {
      let sewageWellList = [];
      let rainWellList = [];
      this.filteredIdList.forEach((element) => {
        if (element.type === "sewage-well") {
          sewageWellList.push(element.id);
        }
        if (element.type === "rain-well") {
          rainWellList.push(element.id);
        }
      });

      console.log(sewageWellList, rainWellList);

      const tasks = [];
      if (sewageWellList.length > 0) {
        tasks.push(
          fetchWellDetail({
            ids: sewageWellList,
          })
        );
      }
      if (rainWellList.length > 0) {
        tasks.push(
          fetchRainWellDetail({
            ids: rainWellList,
          })
        );
      }

      this.loading = true;
      Promise.all(tasks)
        .then((res) => {
          const list = [];
          if (res[0] && Array.isArray(res[0])) {
            list.push(...res[0]);
          }
          if (res[1] && Array.isArray(res[1])) {
            list.push(...res[1]);
          }
          this.list = Object.freeze(list);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
    },
  },
};
</script>


<style lang="less" scoped>
.detail {
  display: flex;
  align-items: center;
  gap: 2em;
  margin-bottom: 2em;
  & > div {
    flex: 1;
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 1em;
    & > div {
      display: flex;
      align-items: center;
    }
    .icon {
      font-size: 2.5em;
      margin-right: 12px;
      color: var(--theme-color);
    }
    .label {
      font-size: 0.9em;
      color: #999;
    }
    .value {
      font-size: 1.4em;
      margin-top: 0.4em;
      font-weight: bold;
      color: var(--theme-color);
    }
  }
}
</style>