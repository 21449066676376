<template>
  <div class="detail">
    <div class="header" @click="$emit('changeVisible')">
      <div class="title">详情</div>
      <div class="extra">
        <a-icon type="close" />
      </div>
    </div>

    <div class="body" style="position: relative">
      <Navigator :lng="detail.x" :lat="detail.y" />

      <div class="well" v-if="renderDetail.code">
        <template v-if="renderDetail.pipeFrom && renderDetail.pipeTo">
          <template v-if="liquidData || flowData">
            <FbxLoader
              url="/flow.fbx"
              type="check-well"
              :width="420"
              :height="300"
              :text="labels"
            />
          </template>
          <template v-else>
            <FbxLoader
              url="/check-well.fbx"
              type="check-well"
              :width="420"
              :height="300"
              :text="labels"
            />
          </template>
        </template>
        <template v-else-if="!renderDetail.pipeFrom && renderDetail.pipeTo">
          <FbxLoader
            url="/pipe-one-side.fbx"
            type="check-well"
            :width="420"
            :height="300"
            :text="labels"
          />
        </template>
        <template v-else-if="renderDetail.pipeFrom && !renderDetail.pipeTo">
          <FbxLoader
            url="/pipe-one-side.fbx"
            type="check-well"
            :width="420"
            :height="300"
            :text="labels"
          />
        </template>
        <template v-else>
          <FbxLoader
            url="/check-well.fbx"
            type="check-well"
            :width="420"
            :height="300"
            :text="labels"
          />
        </template>
      </div>

      <a-tabs v-model="activeTab">
        <a-tab-pane key="1" tab="基本信息">
          <Info :render-detail="renderDetail" />
        </a-tab-pane>
        <template v-if="Array.isArray(renderDetail.boundDevices)">
          <a-tab-pane
            v-for="item in renderDetail.boundDevices"
            :key="item.id"
            :tab="item.name"
          >
            <div class="content">
              <div class="item">
                <div class="label">液位：</div>
                <div class="value">
                  <template v-if="nowData.liquidLevel">
                    {{
                      nowData.liquidLevel +
                      parseFloat(renderDetail.elevation - depth).toFixed(3)
                    }}m
                  </template>
                  <template v-else>--</template>
                </div>
              </div>

              <div class="item">
                <div class="label">上报时间：</div>
                <div class="value">{{ nowData.ts ? nowData.ts : "--" }}</div>
              </div>
            </div>
          </a-tab-pane>
        </template>
      </a-tabs>
    </div>
  </div>
</template>
  
<script>
import { mapState } from "vuex";
import { mapMutations } from "vuex";
import request from "@/api/request";
import moment from "moment";
import Info from "./info.vue";
import FbxLoader from "../fbx-loader.vue";

import Navigator from "@/components/navigator";

export default {
  components: {
    Info,
    FbxLoader,
    Navigator,
  },

  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      renderDetail: {},
      labels: [],

      activeTab: "1",
      date: [moment().subtract(1, "days"), moment()],

      nowData: {},
    };
  },

  computed: {
    ...mapState("gis", ["selectedWell"]),

    liquidData() {
      const list = this.renderDetail.boundDevices ?? [];
      const obj = list.find((item) => item.type === "liquidLevel");
      return obj ? obj : null;
    },
    flowData() {
      const list = this.renderDetail.boundDevices ?? [];
      const obj = list.find((item) => item.type === "flowMeter");
      return obj ? obj : null;
    },
  },

  watch: {
    detail() {
      this.getDetail();
    },

    liquidData() {
      this.getNowData();
    },
  },

  mounted() {
    this.getDetail();
  },

  methods: {
    ...mapMutations("gis", [
      "setDetail",
      "setCategory",
      "setType",
      "setSelectedWell",
    ]),
    getDetail() {
      request({
        url: "/model-analysis/pipe/sewage/well/query",
        method: "post",
        data: {
          ids: [this.detail.id],
        },
      }).then((res) => {
        console.log("well detail", res);
        if (Array.isArray(res) && res.length > 0) {
          const item = res[0];
          this.renderDetail = {
            ...item,
            pipeFrom:
              item.pipesFrom && item.pipesFrom[0] ? item.pipesFrom[0] : null,
            pipeTo: item.pipesTo && item.pipesTo[0] ? item.pipesTo[0] : null,
          };

          this.setDetail(this.renderDetail);
          this.setCategory("sewage");
          this.setType("well");

          this.setSelectedWell([...this.selectedWell, this.renderDetail]);

          if (this.renderDetail) {
            const depth = this.renderDetail.depth ? this.renderDetail.depth : 0;

            const labels = [
              {
                text: `井深：${depth}米`,
                position: [0, 10, 5],
              },
              {
                text: `地面高程：${parseFloat(
                  this.renderDetail.elevation
                ).toFixed(3)}米`,
                position: [0, -25, 0],
              },

              {
                text: `井底高程：${parseFloat(
                  this.renderDetail.elevation - depth
                ).toFixed(3)}米`,
                position: [0, -30, 0],
              },
            ];

            if (this.renderDetail.pipeFrom) {
              labels.push({
                text: `管底高程：${this.renderDetail.pipeFrom.elevationTo.toFixed(
                  3
                )}米`,
                position: [0, -20, 30],
              });

              labels.push({
                // 最左侧中间
                text: `距离井底：${parseFloat(
                  depth - this.renderDetail.pipeFrom.buringDepthTo
                ).toFixed(3)}米`,
                position: [0, -40, 30],
              });
              labels.push({
                // 最左侧下面
                text: `管顶高程：${parseFloat(
                  this.renderDetail.pipeFrom.elevationTo +
                    this.renderDetail.pipeFrom.diameter * 0.001
                ).toFixed(3)}米`,
                position: [0, 0, 30],
              });
            }

            if (this.renderDetail.pipeTo) {
              labels.push({
                text: `管底高程：${this.renderDetail.pipeTo.elevationFrom.toFixed(
                  3
                )}米`,
                position: [0, -20, -15],
              });
              labels.push({
                text: `距离井底：${this.renderDetail.pipeTo.buringDepthFrom.toFixed(
                  3
                )}米`,
                position: [0, -40, -15],
              });
              labels.push({
                text: `管顶高程：${parseFloat(
                  this.renderDetail.pipeTo.elevationFrom +
                    this.renderDetail.pipeTo.diameter * 0.01
                ).toFixed(3)}米`,
                position: [0, 0, -15],
              });
            }

            this.labels = labels;
          }
        }
      });
    },

    getNowData() {
      request({
        url: "/data-service/dev/realTime",
        method: "post",
        data: {
          devType: "liquidLevel",
          eids: [this.liquidData.sn],
        },
      }).then((res) => {
        this.nowData = res;

        if (res.liquidLevel) {
          this.labels.push({
            text: `距离井盖：${
              this.renderDetail.depth
                ? this.renderDetail.depth - res.liquidLevel
                : "-"
            }米`,
            position: [0, -55, -10],
          });
        }
      });
    },
  },
};
</script>
  
  
<style lang="less" scoped>
.detail {
  position: fixed;
  top: 11vh;
  right: 1vw;
  bottom: 2vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);
  width: 50vw;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;

  & > .header {
    background-color: var(--theme-color);
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}
</style>