import request from '../request'


export function get_ipcname_and_url(device_id) {
    return request({
        url: `http://223.75.200.168:19000/model-analysis/pipe/device/query/${device_id}`,
        method: 'get', // 陈政把这个请求改成了Get
    })
}

export function get_cutoff_ipcname_and_url(device_id) {
    return request({
        url: `http://223.75.200.168:19000/model-analysis/pipe/cutoffwell/query/${device_id}`,
        method: 'post', // 陈政把这个请求改成了post
    })
}


