<template>
  <div :id="id" style="height: 20vh"></div>
</template>

<script>
import * as echarts from "echarts/core";
import { TooltipComponent, LegendComponent } from "echarts/components";
import { PieChart } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { mapGetters } from "vuex";

echarts.use([
  TooltipComponent,
  PieChart,
  CanvasRenderer,
  LabelLayout,
  LegendComponent,
]);

export default {
  props: {
    id: {
      type: String,
      default: "",
    },
    info: {
      type: Object,
      default: () => ({}),
    },
  },

  watch: {
    info() {
      this.setChart();
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    rainWellType() {
      return this.findDataDict("rainWellType");
    },
    sewageWellType() {
      return this.findDataDict("sewageWellType");
    },
  },

  mounted() {
    var chartDom = document.getElementById(this.id);
    this.chart = echarts.init(chartDom);
    this.setChart();
  },

  beforeDestroy() {
    this.chart?.dispose();
  },

  methods: {
    setChart() {
      const keys = Object.keys(this.info);
      if (keys.length === 0) {
        return;
      }

      const list = keys.map((key) => {
        let name = key;
        if (this.id === "diameterMap") {
          name = "DN" + key;
        }

        if (key === "sewage") {
          name = "污水";
        } else if (key === "rain") {
          name = "雨水";
        }

        let obj = this.sewageWellType.find((item) => item.value === key);
        if (!obj) {
          obj = this.rainWellType.find((item) => item.value === key);
        }

        return {
          name: obj ? obj.name : name,
          value: this.info[key],
        };
      });

      var option;

      option = {
        // color: ["#4ECB73", "#FBD437", "#FFA700", "#0AD8F3"],
        tooltip: {
          trigger: "item",
        },
        legend: {
          textStyle: { color: "#fff" },
          show: true,
          // type: "scroll",
          orient: "vertical",
          left: "right",
          top: "middle",
        },
        series: [
          {
            name: "",
            type: "pie",
            // radius: ["60%", "80%"],
            avoidLabelOverlap: false,
            label: {
              show: false,
              formatter: ["{title|{b}}", "{percentage|{d}%}"].join("\n"),
              rich: {
                title: {
                  lineHeight: 24,
                },
                percentage: {
                  fontSize: 16,
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              show: true,
            },
            data: list,
          },
        ],
      };

      option && this.chart.setOption(option);
    },
  },
};
</script>