import request from '@/api/request'


// 截流设施
export function fetchFacility() {
    return request({
        url: '/model-analysis/pipe/facility/all',
    })
}

// 截流设施详情
export function fetchFacilityDetail(data) {
    return request({
        url: '/data-service/dev/realTime',
        method: 'post',
        data
    })
}


// 连通性检测
export function fetchConnection(data) {
    return request({
        url: '/model-analysis/pipe/connection',
        method: 'post',
        data,
    })
}


// 溯源分析
export function fetchSource(data) {
    return request({
        url: '/model-analysis/pipe/trace',
        method: 'post',
        data,
    })
}