import { fetchWell, fetchWellDetail as fetchSewageWellDetail } from "../api/sewage";
import { PointLayer } from '@antv/l7'

export default {

    data() {
        return {
            wellLoading: false,
            wellDetail: {},
            wellVisible: false,
            // isShowWellId: false,
        }
    },

    watch: {
        wellVisible() {
            if (!this.wellVisible) {
                this.closeFacilityActive();
            }
        },
        rainwellVisible() {
            if (!this.rainwellVisible) {
                this.closeFacilityActive();
            }
        },

        // isShowWellId() {
        //     if (this.isShowWellId) {
        //         this.getWell();
        //         this.getrainwell();
        //     } else {
        //         this.hideWellId();
        //         this.hideRainWellId();
        //     }
        // }
    },

    methods: {
        getWell() {
            if (this.wellLoading) return;

            const zoom = this.map?.getZoom();
            if (zoom < 16.7) {
                return;
            }


            if (this.selected.indexOf("污水检查井") === -1) {
                return;
            }

            if (!this.sewageWellLayer) {
                this.wellLoading = true;
                fetchWell({}).then((res) => {
                    if (res && Array.isArray(res.locs)) {

                        const list = Object.freeze(res.locs);

                        this.setSewageWellRawList(list)

                        this.sewageWellLayer = new PointLayer({
                            zIndex: 100,
                        }).source(list.map(item => {
                            return {
                                id: item[0],
                                type: item[1],
                                x: item[3],
                                y: item[4],
                            }
                        }), {
                            parser: {
                                type: 'json',
                                x: 'x',
                                y: 'y'
                            }
                        })
                            .shape('sewageWell')
                            .size(zoom * 0.55)

                        this.sewageWellLayer.on('click', this.onSewageWellClick)
                        this.scene?.addLayer(this.sewageWellLayer);

                        // if (this.isShowWellId) {
                        //     this.showWellId();
                        // }


                    }
                }).finally(()=>{
                    this.wellLoading = false;
                })
            } else {
                this.sewageWellLayer.show();
                // if (this.isShowWellId) {
                //     this.showWellId();
                // }
            }

        },

        onSewageWellClick(e) {
            console.log('e', e)

            if (this.controlType === 'source') {
                fetchSewageWellDetail({
                    ids: [e.feature.id],
                    type: "sewage",
                }).then(res => {
                    if (Array.isArray(res) && res.length > 0) {
                        const item = res[0];


                        this.onSource({
                            facilityCode: item.code,
                            category: 'sewage',
                        })

                    }
                })

                return;
            }

            if (this.controlType === 'connect') {

                if (this.selectedWellForConnect) {

                    fetchSewageWellDetail({
                        ids: [e.feature.id],
                        type: "sewage",
                    }).then(res => {
                        if (Array.isArray(res) && res.length > 0) {
                            const item = res[0];
                            this.onConnect([this.selectedWellForConnect, item], 'sewage')
                        }
                    })

                } else {

                    if (this.connectLayer) {
                        this.scene?.removeLayer(this.connectLayer);
                    }

                    const zoom = this.map?.getZoom();
                    const { id, x, y } = e.feature;


                    this.firstWellLayerForConnect = new PointLayer({
                        zIndex: 200,
                    })

                        .source([{
                            id,
                            x: parseFloat(x),
                            y: parseFloat(y)
                        }], {
                            parser: {
                                type: 'json',
                                x: 'x',
                                y: 'y'
                            }
                        })
                        .shape('simple')
                        .color('red')
                        .size(zoom * 0.55)

                    this.scene?.addLayer(this.firstWellLayerForConnect)

                    fetchSewageWellDetail({
                        ids: [e.feature.id],
                        type: "sewage",
                    }).then(res => {
                        if (Array.isArray(res) && res.length > 0) {
                            const item = res[0];
                            this.selectedWellForConnect = item;
                            this.$message.info('请继续点击检查井')
                        }
                    })


                }

                return;

            }

            this.wellDetail = e.feature;
            this.setFacilityActive(e.feature, 'wellVisible');
        },

        removeWell() {
            this.wellVisible = false;
            this.sewageWellLayer?.hide();
            console.log('hide sewage well')
            // this.hideWellId();
        },

        // showWellId() {
        //     if (this.sewageWellIdLayer) {
        //         this.sewageWellIdLayer.show();
        //         return;
        //     }

        //     if (this.sewageWellRawList.length > 0) {
        //         this.sewageWellIdLayer = new PointLayer({
        //             zIndex: 600,
        //         })
        //             .source(this.sewageWellRawList.map(item => ({
        //                 code: item[5],

        //                 type: item[1],
        //                 x: item[3],
        //                 y: item[4],
        //             })), {
        //                 parser: {
        //                     type: 'json',
        //                     x: 'x',
        //                     y: 'y'
        //                 }
        //             })
        //             .shape('code', 'text')
        //             .size(10)
        //             .color('#FFA500')
        //             .style({
        //                 fontWeight: '900',
        //                 textAnchor: 'center', // 文本相对锚点的位置 center|left|right|top|bottom|top-left
        //                 textOffset: [48, 24], // 文本相对锚点的偏移量 [水平, 垂直]
        //                 spacing: 1, // 字符间距
        //                 padding: [1, 1], // 文本包围盒 padding [水平，垂直]，影响碰撞检测结果，避免相邻文本靠的太近
        //             });
        //         this.scene?.addLayer(this.sewageWellIdLayer);
        //     }

        // },

        // hideWellId() {
        //     this.sewageWellIdLayer?.hide();
        // }
    }
}

