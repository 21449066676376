import { fetchPipe } from "../api/sewage";
import { LineLayer } from '@antv/l7'
import { mapState, mapMutations } from 'vuex'
import { getCenterPoint } from '@/utils/map'
export default {

  data() {
    return {
      selectedPipeLngLatForDistance: null,
      pipeDetail: {},
      pipeVisible: false,

    }
  },

  computed: {
    ...mapState('gis', ['clickedMarkers', 'selectedLnglat', 'selectedLnglatLine', 'controlType',

      "sewagePipeRawList",
      "rainPipeRawList",
      'sewageWellRawList',
      'rainWellRawList',
    ])
  },

  watch: {
    controlType() {
      // 操作类型变化，说明用户切换新操作，要么停止现在操作，都清空高亮

      // 取消净距图层
      this.selectedPipeLngLatForDistance = null;
      if (this.activePipeLayer) {
        this.scene?.removeLayer(this.activePipeLayer);
      }
      if (this.activerainpipeLayer) {
        this.scene?.removeLayer(this.activerainpipeLayer);
      }

      if (this.markerForDistance1) {
        this.map?.remove(this.markerForDistance1)
        this.markerForDistance1 = null;
      }

      if (this.markerForDistance2) {
        this.map?.remove(this.markerForDistance2)
        this.markerForDistance2 = null;
      }

      if (this.polylineForDistance) {
        this.map?.remove(this.polylineForDistance)
        this.polylineForDistance = null;
      }
      if (this.textMarkerForDistance) {
        this.map?.remove(this.textMarkerForDistance)
        this.textMarkerForDistance = null;
      }

      // 取消溯源分析
      if (this.sourceLayer) {
        this.scene?.removeLayer(this.sourceLayer)
      }

      //  取消连通性分析
      this.selectedWellForConnect = null;
      this.setConnectDetail(null); // 给断面分析用
      if (this.connectLayer) {
        this.scene?.removeLayer(this.connectLayer);
      }
      if (this.firstWellLayerForConnect) {
        this.scene?.removeLayer(this.firstWellLayerForConnect);
      }

      // 取消横断面分析
      if (this.markerForAcross1) {
        this.map?.remove(this.markerForAcross1)
        this.markerForAcross1 = null;
      }
      if (this.markerForAcross2) {
        this.map?.remove(this.markerForAcross2)
        this.markerForAcross2 = null;
      }
      if (this.polylineForAcross) {
        this.map?.remove(this.polylineForAcross)
        this.polylineForAcross = null;
      }

      // 开始编辑多边形面积，用来拾取内部的管线和检查井
      const { AMap } = window;




      if (this.controlType === 'area') {

        var path = [
          [
            112.795797,
            32.106083
          ],
          [
            112.784596,
            32.14353
          ],
          [
            112.741504,
            32.125721
          ],
        ]

        if (!this.areaPolygon) {
          this.areaPolygon = new AMap.Polygon({
            path: path,
            strokeColor: "#01C8E3",
            strokeWeight: 6,
            strokeOpacity: 0.5,
            fillOpacity: 0.4,
            fillColor: '#ff0000',
            zIndex: 200,
            bubble: true,
          })

          this.map.add(this.areaPolygon)
          // this.map.setFitView()
          this.areaPolyEditor = new AMap.PolygonEditor(this.map, this.areaPolygon);


        }
        this.areaPolyEditor?.open();

      }
      else if (this.controlType === 'getArea') {
        this.areaPolyEditor?.close();
        const path = this.areaPolygon?.getPath();
        console.log(path);
        if (Array.isArray(path)) {
          const list = path.map(element => [element.lng, element.lat]);

          const idList = [];

          if (this.sewagePipeRawList?.length > 0) {
            this.sewagePipeRawList.forEach(item => {
              const startPosition = [parseFloat(item[1]), parseFloat(item[2])];
              const endPosition = [parseFloat(item[3]), parseFloat(item[4])];

              if (
                AMap.GeometryUtil.isPointInRing(startPosition, list) &&
                AMap.GeometryUtil.isPointInRing(endPosition, list)
              ) {
                idList.push({
                  id: item[0],
                  type: 'sewage',
                })
              }

            })
          }
          if (this.rainPipeRawList?.length > 0) {
            this.rainPipeRawList.forEach(item => {

              const startLng = parseFloat(item[1]);
              const startLat = parseFloat(item[2]);
              const endLng = parseFloat(item[3]);
              const endLat = parseFloat(item[4]);
              if (isNaN(startLng)) return;
              if (isNaN(startLat)) return;
              if (isNaN(endLng)) return;
              if (isNaN(endLat)) return;

              const startPosition = [parseFloat(item[1]), parseFloat(item[2])];
              const endPosition = [parseFloat(item[3]), parseFloat(item[4])];


              if (
                AMap.GeometryUtil.isPointInRing(startPosition, list) &&
                AMap.GeometryUtil.isPointInRing(endPosition, list)
              ) {
                idList.push({
                  id: item[0],
                  type: 'rain',
                })

              }

            })
          }

          if (this.sewageWellRawList?.length > 0) {

            this.sewageWellRawList.forEach(item => {

              const lng = parseFloat(item[3]);
              const lat = parseFloat(item[4]);
              if (isNaN(lng)) return;
              if (isNaN(lat)) return;

              const position = [lng, lat];

              if (
                AMap.GeometryUtil.isPointInRing(position, list)
              ) {
                idList.push({
                  id: item[0],
                  type: 'sewage-well',
                })

              }

            })
          }
          if (this.rainWellRawList?.length > 0) {
            if (this.rainWellRawList?.length > 0) {

              this.rainWellRawList.forEach(item => {

                const lng = parseFloat(item[3]);
                const lat = parseFloat(item[4]);
                if (isNaN(lng)) return;
                if (isNaN(lat)) return;

                const position = [lng, lat];

                if (
                  AMap.GeometryUtil.isPointInRing(position, list)
                ) {
                  idList.push({
                    id: item[0],
                    type: 'rain-well',
                  })

                }

              })
            }
          }


          if (idList.length > 0) {
            this.areaFacilityIdList = Object.freeze(idList);
            this.areaFacilityListVisible = true;
          } else {
            this.$message.error('选择范围内没有设施')
          }

        }
      } else {
        // 切换到其他的操作了，这时候就把已经做好的关闭了
        if (this.areaPolygon) {
          this.map.remove(this.areaPolygon)
          this.areaPolygon = null;
          this.areaPolyEditor = null;
        }
      }


    },


    pipeVisible() {
      if (!this.pipeVisible) {
        this.closePipeActive();
      }
    },


  },
  methods: {
    getPipe() {
      if (!this.pipeLayer) {
        fetchPipe({
        }).then((res) => {
          if (res && Array.isArray(res.locs)) {

            this.setSewagePipeRawList(Object.freeze(res.locs))

            let thinList = []
            let thickList = []
            for (let i = 0; i < res.locs.length; i++) {
              const item = res.locs[i]
              if (item[5] === 'thin') {
                thinList.push({
                  "type": "Feature",
                  "properties": {
                    id: item[0],
                    startLng: item[1],
                    startLat: item[2],
                    endLng: item[3],
                    endLat: item[4]
                  },
                  "geometry": {
                    "type": "LineString",
                    "coordinates": [
                      [parseFloat(item[1]), parseFloat(item[2])],
                      [parseFloat(item[3]), parseFloat(item[4])],
                    ]
                  }
                })
              } else {
                thickList.push({
                  "type": "Feature",
                  "properties": {
                    id: item[0],
                    startLng: item[1],
                    startLat: item[2],
                    endLng: item[3],
                    endLat: item[4]
                  },
                  "geometry": {
                    "type": "LineString",
                    "coordinates": [
                      [parseFloat(item[1]), parseFloat(item[2])],
                      [parseFloat(item[3]), parseFloat(item[4])],
                    ]
                  }
                })
              }
            }

            this.thinRawList = thinList; // 保存原始数据
            this.thickRawList = thickList;

            const sources = {
              "type": "FeatureCollection",
              "features": thinList
            }

            const pipeLayer = new LineLayer({
              zIndex: 10,
              depth: true
            }).source(sources).size(1)
              .shape('line')
              .color('#FFA500')
              .animate({
                interval: 1, // 间隔
                duration: 2, // 持续时间，延时
                trailLength: 2 // 流线长度
              });

            pipeLayer.on('click', this.onPipeClick);

            this.pipeLayer = pipeLayer;
            this.scene?.addLayer(pipeLayer);


            const sources2 = {
              "type": "FeatureCollection",
              "features": thickList
            }

            const pipeLayer2 = new LineLayer({
              zIndex: 10,
              depth: true
            }).source(sources2).size(1)
              .shape('line')
              .color('#FFA500')
              .animate({
                interval: 1, // 间隔
                duration: 2, // 持续时间，延时
                trailLength: 2 // 流线长度
              });

            pipeLayer2.on('click', this.onPipeClick);
            this.pipeLayer2 = pipeLayer2;
            this.scene?.addLayer(pipeLayer2);

            this.onMoveEnd();
          }
        });
      } else {
        this.pipeLayer?.show();
        this.pipeLayer2?.show();
        this.activePipeLayer?.show();
        if (this.pipeDetail.id) {
          this.pipeVisible = true;
        }
        this.onMoveEnd();
      }
    },

    onPipeClick(e) {

      console.log("e", e);

      const { AMap } = window;
      if (!AMap) return;

      if (this.controlType === 'distance') {
        if (this.markerForDistance1) {

          if (this.markerForDistance2) {
            this.map?.remove(this.markerForDistance1)
            this.map?.remove(this.markerForDistance2)

            if (this.polylineForDistance) {
              this.map?.remove(this.polylineForDistance)
            }
            if (this.textMarkerForDistance) {
              this.map?.remove(this.textMarkerForDistance)
            }
            this.markerForDistance2 = null;
            this.polylineForDistance = null;
            this.textMarkerForDistance = null;

            // 重新测量
            this.markerForDistance1 = new AMap.Marker({
              zIndex: 500,
              position: new AMap.LngLat(e.lngLat.lng, e.lngLat.lat),
              extData: {
                ...e.feature.properties,
              }
            });
            this.map?.add(this.markerForDistance1);
            this.$message.info("请点击另一根管线")

          } else {

            this.markerForDistance2 = new AMap.Marker({
              zIndex: 500,

              position: [e.lngLat.lng, e.lngLat.lat],

            });
            this.map?.add(this.markerForDistance2);


            const position1 = this.markerForDistance1.getPosition();
            const position2 = this.markerForDistance2.getPosition();

            this.polylineForDistance = new AMap.Polyline({
              zIndex: 500,
              strokeStyle: "dashed",
              path: [
                position1,
                position2,
              ],
              borderWeight: 2, // 线条宽度，默认为 1
              strokeColor: "red", // 线条颜色
              strokeOpacity: 1,
              lineJoin: "round", // 折线拐点连接处样式
            });

            // 将折线添加至地图实例
            this.map.add(this.polylineForDistance);

            const firstPipe = this.markerForDistance1.getExtData();
            console.log('firstPipe', firstPipe)
            const { endLat, endLng, startLat, startLng } = e.feature.properties;

            const length = AMap.GeometryUtil.distanceToSegment([
              parseFloat(firstPipe.startLng),
              parseFloat(firstPipe.startLat)
            ], [
              parseFloat(startLng),
              parseFloat(startLat)
            ], [
              parseFloat(endLng),
              parseFloat(endLat),
            ]);

            // const length = getLength([
            //   position1,
            //   position2,
            // ])
            if (length) {
              const msg = (`净距：${Math.round(length).toLocaleString()}米`);
              console.log(msg)

              const point = getCenterPoint(position1.lng + ',' + position1.lat, position2.lng + ',' + position2.lat)
              console.log('point', point)

              if (point.lng) {
                this.textMarkerForDistance = new AMap.Text({
                  text: msg,
                  anchor: 'center', // 设置文本标记锚点
                  style: {
                    'text-align': 'center',
                    'font-size': '14',
                    'color': 'blue'
                  },
                  position: [point.lng, point.lat]
                });
                this.map.add(this.textMarkerForDistance)
              }




            } else {
              this.$message.error('无法获取净距')
            }

          }


        } else {
          this.markerForDistance1 = new AMap.Marker({
            zIndex: 500,
            position: [e.lngLat.lng, e.lngLat.lat],
            extData: {
              ...e.feature.properties,
            }
          });
          this.map?.add(this.markerForDistance1);
          this.$message.info("请点击另一根管线")
        }
        return;
      }
      if (this.controlType === 'source') {
        this.onSource({
          pipeId: e.feature.properties.id,
          category: 'sewage',
        })
        return;
      }


      this.pipeDetail = e.feature.properties;

      this.pipeVisible = true;
      this.drainVisible = false;
      this.wellVisible = false;

      this.setPipeActive(e.feature.properties, 'pipeVisible')
    },

    ...mapMutations('gis', ['setClickedMarkers', 'setSewagePipeRawList', 'setSelectedLnglat', 'setSelectedLnglatLine', 'setConnectDetail',

      'setSewageWellRawList',
      'setRainWellRawList',
      'setControlType'
    ]),


    setPipeActive(properties, key) {
      console.log('setactive')

      const sources = {
        "type": "FeatureCollection",
        "features": [{
          "type": "Feature",
          properties,
          "geometry": {
            "type": "LineString",
            "coordinates": [
              [parseFloat(properties.startLng), parseFloat(properties.startLat)],
              [parseFloat(properties.endLng), parseFloat(properties.endLat)],
            ]
          }

        }]
      }


      this.closePipeActive();
      if (this.activePipeLayer) {
        this.scene?.removeLayer(this.activePipeLayer);
        this.activePipeLayer = null;
        this[key] = false;
      }
      this.activePipeLayer = new LineLayer({
        zIndex: 900,
        depth: true
      }).source(sources).size(5)
        .shape('line')
        .color('red')
        .animate({
          interval: 1, // 间隔
          duration: 2, // 持续时间，延时
          trailLength: 2 // 流线长度
        });

      this.activePipeLayer.on('click', () => {
        this.closePipeActive();
        this[key] = false;
      });

      this.scene?.addLayer(this.activePipeLayer);
      this[key] = true;

    },

    closePipeActive() {
      if (this.activePipeLayer) {
        this.scene?.removeLayer(this.activePipeLayer);
        this.activePipeLayer = null;
      }
    },

    removePipe() {
      this.pipeVisible = false;
      this.pipeLayer?.hide();
      this.pipeLayer2?.hide();
      this.activePipeLayer?.hide();

      // this.clearPipeText('sewage');


    },

  }
}

