<template>
  <div class="control-bar">
    <img
      :src="iocnNorth"
      alt=""
      :style="{
        transform: `rotate(${rotation}deg)`,
        width: '2vw',
        marginBottom: '2vh',
      }"
    />
    <div @click="rotate('left')">
      <img
        :src="iconRotateLeft"
        alt=""
        style="width: 2vw; margin-bottom: 2vh"
      />
    </div>
    <div @click="rotate('right')">
      <img :src="iconRotateRight" alt="" style="width: 2vw" />
    </div>
  </div>
</template>


<script>
export default {
  props: {
    rotation: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      iocnNorth: require("../../assets/north.png"),
      iconRotateLeft: require("../../assets/rotate-left.png"),
      iconRotateRight: require("../../assets/rotate-right.png"),
    };
  },
  methods: {
    rotate(type) {
      const value = type === "left" ? this.rotation - 10 : this.rotation + 10;
      this.$emit("rotate", value);
    },
  },
};
</script>

<style lang="less" scoped>
.control-bar {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1vh 1vw;

  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>