<template>
  <div class="detail">
    <div class="header" @click="$emit('changeVisible')">
      <div class="title">详情</div>
      <div class="extra">
        <a-icon type="close" />
      </div>
    </div>

    <div class="well">
      <template v-if="renderDetail.depth">
        <template v-if="renderDetail.depth > 4">
          <img
            class="length1"
            src="../../../assets/icons/length/8.png"
            alt=""
          />
        </template>
        <template v-else-if="renderDetail.depth > 3.5">
          <img
            class="length1"
            src="../../../assets/icons/length/7.png"
            alt=""
          />
        </template>
        <template v-else-if="renderDetail.depth > 3">
          <img
            class="length1"
            src="../../../assets/icons/length/6.png"
            alt=""
          />
        </template>
        <template v-else-if="renderDetail.depth > 2.5">
          <img
            class="length1"
            src="../../../assets/icons/length/5.png"
            alt=""
          />
        </template>
        <template v-else-if="renderDetail.depth > 2">
          <img
            class="length1"
            src="../../../assets/icons/length/4.png"
            alt=""
          />
        </template>
        <template v-else-if="renderDetail.depth > 1.5">
          <img
            class="length1"
            src="../../../assets/icons/length/3.png"
            alt=""
          />
        </template>
        <template v-else-if="renderDetail.depth > 1">
          <img
            class="length1"
            src="../../../assets/icons/length/2.png"
            alt=""
          />
        </template>
        <template v-else-if="renderDetail.depth > 0.5">
          <img
            class="length1"
            src="../../../assets/icons/length/1.png"
            alt=""
          />
        </template>
        <template v-else>
          <img
            class="length1"
            src="../../../assets/icons/length/1.png"
            alt=""
          />
        </template>
      </template>
      <template v-else>
        <img class="length1" src="../../../assets/icons/length/1.png" alt="" />
      </template>

      <div class="depth">
        井深{{ renderDetail.depth ? renderDetail.depth : "-" }}米
      </div>
      <div class="elevation">
        地面高程：{{ parseFloat(renderDetail.elevation).toFixed(3) }}m
      </div>
      <div class="elevation2">
        井底高程：{{ parseFloat(renderDetail.elevation - depth).toFixed(3) }}m
      </div>

      <template v-if="renderDetail.pipeFrom && renderDetail.pipeTo">
        <template
          v-if="
            renderDetail.pipeFrom.buringDepthTo ===
            renderDetail.pipeTo.buringDepthTo
          "
        >
          <img class="bg" src="../../../assets/icons/well2.png" />
        </template>
        <template v-else>
          <img class="bg" src="../../../assets/icons/well1.png" />
        </template>
      </template>
      <template v-else-if="!renderDetail.pipeFrom && renderDetail.pipeTo">
        <img class="bg" src="../../../assets/icons/well3.png" />
      </template>
      <template v-else-if="renderDetail.pipeFrom && !renderDetail.pipeTo">
        <img class="bg" src="../../../assets/icons/well4.png" />
      </template>

      <template v-if="renderDetail.pipeFrom">
        <div class="from-elevation">
          管底高程：{{ renderDetail.pipeFrom.elevationTo }}m
        </div>

        <div class="from-elevation2">
          距离井底：{{
            parseFloat(depth - renderDetail.pipeFrom.buringDepthTo).toFixed(3)
          }}m
        </div>

        <div class="from-elevation3">
          管顶高程：{{
            parseFloat(
              renderDetail.pipeFrom.elevationTo +
                renderDetail.pipeFrom.diameter * 0.001
            ).toFixed(3)
          }}m
        </div>
      </template>

      <template v-if="renderDetail.pipeTo">
        <div class="to-elevation">
          管底高程：{{ renderDetail.pipeTo.elevationFrom }}m
        </div>

        <div class="to-elevation2">
          距离井底：{{
            parseFloat(depth - renderDetail.pipeTo.buringDepthFrom).toFixed(3)
          }}m
        </div>
        <div class="to-elevation3">
          管顶高程：{{
            parseFloat(
              renderDetail.pipeTo.elevationFrom +
                renderDetail.pipeTo.diameter * 0.01
            ).toFixed(3)
          }}m
        </div>
      </template>
    </div>

    <div class="content" style="position: relative">
      <Navigator :lng="detail.x" :lat="detail.y" />

      <div class="item">
        <div class="label">编号：</div>
        <div class="value">{{ renderDetail.code }}</div>
      </div>
      <div class="item">
        <div class="label">类型：</div>
        <div class="value">{{ renderDetail.type }}</div>
      </div>
      <div class="item">
        <div class="label">附属物：</div>
        <div class="value">{{ renderDetail.accessary }}</div>
      </div>
      <div class="item">
        <div class="label">井深：</div>
        <div class="value">{{ renderDetail.depth }}</div>
      </div>
      <div class="item">
        <div class="label">井盖直径：</div>
        <div class="value">{{ renderDetail.diameterCover }}mm</div>
      </div>
      <div class="item">
        <div class="label">井室直径：</div>
        <div class="value">{{ renderDetail.diameterRoom }}mm</div>
      </div>

      <div class="item">
        <div class="label">所在道路：</div>
        <div class="value">{{ renderDetail.location }}</div>
      </div>

      <div class="item">
        <div class="label">井盖材质：</div>
        <div class="value">{{ renderDetail.materialCover }}</div>
      </div>

      <div class="item">
        <div class="label">检查井材质：</div>
        <div class="value">{{ renderDetail.materialWell }}</div>
      </div>

      <div class="item">
        <div class="label">井盖形状：</div>
        <div class="value">{{ renderDetail.shapeCover }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchDrainDetail } from "@/api/rain";
import Navigator from "@/components/navigator";

export default {
  components: {
    Navigator,
  },
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      renderDetail: {},
    };
  },

  computed: {
    depth() {
      return this.renderDetail.depth ? this.renderDetail.depth : 0;
    },
  },

  watch: {
    detail() {
      this.getDetail();
    },
  },

  mounted() {
    this.getDetail();
  },

  methods: {
    getDetail() {
      fetchDrainDetail({
        ids: [this.detail.id],
        type: "rain",
      }).then((res) => {
        console.log("detai", res);
        if (Array.isArray(res) && res.length > 0) {
          const item = res[0];
          this.renderDetail = {
            ...item,
            pipeFrom:
              item.pipesFrom && item.pipesFrom[0] ? item.pipesFrom[0] : null,
            pipeTo: item.pipesTo && item.pipesTo[0] ? item.pipesTo[0] : null,
          };
        }
      });
    },
  },
};
</script>


<style lang="less" scoped>
.detail {
  position: fixed;
  top: 11vh;
  right: 1vw;
  bottom: 2vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);

  width: 20vw;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  & > .header {
    background-color: var(--theme-color);
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .content {
    padding: 2vh 1vw;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1vh;

    .item {
      display: flex;
      align-items: center;
      margin-bottom: 1vh;

      .label {
        width: 5em;
      }

      .value {
        color: var(--theme-color);
      }
    }
  }
}

.well {
  position: relative;
  padding: 4vh 0vw;
  font-size: 1.2vh;

  .length1 {
    width: 4.1vw;
    position: absolute;
    bottom: 8.9vh;
    left: 8vw;
  }

  .bg {
    height: 35vh;
    width: 14vw;
    display: block;
    margin: 0 auto;
  }

  .depth {
    border-top: 2px solid #999;
    border-bottom: 2px solid #999;
    position: absolute;
    top: 6vh;
    bottom: 4vh;
    left: 0.5vw;
    writing-mode: vertical-lr;
    display: flex;
    align-items: center;
    justify-content: center;
    &::before {
      content: "";
      position: absolute;
      left: 49%;
      top: 0;
      height: 40%;
      width: 1px;
      background-color: #999;
    }
    &::after {
      content: "";
      position: absolute;
      left: 49%;
      bottom: 0;
      height: 40%;
      width: 1px;
      background-color: #999;
    }
  }

  .elevation {
    position: absolute;
    top: 1vh;
    left: 50%;
    transform: translateX(-50%);
  }
  .elevation2 {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .to-elevation {
    display: flex;
    align-items: center;
    position: absolute;
    left: 65%;
    bottom: 2vh;
  }
  .to-elevation2 {
    display: flex;
    align-items: center;
    position: absolute;
    left: 65%;
    bottom: 4vh;
  }
  .to-elevation3 {
    display: flex;
    align-items: center;
    position: absolute;
    left: 65%;
    bottom: 12vh;
  }

  .from-elevation {
    display: flex;
    align-items: center;
    position: absolute;
    right: 65%;
    bottom: 2vh;
  }
  .from-elevation2 {
    display: flex;
    align-items: center;
    position: absolute;
    right: 65%;
    bottom: 4vh;
  }
  .from-elevation3 {
    display: flex;
    align-items: center;
    position: absolute;
    right: 65%;
    bottom: 12vh;
  }
}
</style>