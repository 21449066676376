import { fetchSource } from "../api";
import { LineLayer } from "@antv/l7";
import { mapState } from 'vuex'
export default {

    computed: {
        ...mapState('gis', ['detail', 'category', 'type'])
    },

    methods: {

        onSource(params) {
            if (this.sourceLayer) {
                this.scene?.removeLayer(this.sourceLayer)
            }

            const hide = this.$message.loading('加载中...')
            fetchSource({
                ...params,
            }).then((res) => {
                console.log("res", res);
                if (res) {
                    const list = []
                    this.setPipe(list, res);
                    console.log('list', list)
                    if (list.length > 0) {
                        this.renderPipe(list);
                    } else {
                        this.$message.error("暂无溯源信息")
                    }
                }
            }).finally(() => {
                this.sourceLoading = false;
                hide();
            })
        },

        setPipe(list, data) {
            if (data) {
                if (data.pipe) {
                    list.push(data.pipe)
                }
                if (Array.isArray(data.upStream) && data.upStream.length > 0) {
                    data.upStream.forEach((item) => {
                        this.setPipe(list, item);
                    });
                }
            }
        },

        renderPipe(list) {

            let thinList = []
            for (let i = 0; i < list.length; i++) {

                const item = list[i]

                thinList.push({
                    "type": "Feature",
                    "properties": {
                        id: item.id,
                    },
                    "geometry": {
                        "type": "LineString",
                        "coordinates": [
                            [parseFloat(item.xfrom), parseFloat(item.yfrom)],
                            [parseFloat(item.xto), parseFloat(item.yto)],
                        ]
                    }
                })


            }

            const sources = {
                "type": "FeatureCollection",
                "features": thinList
            }

            this.sourceLayer = new LineLayer({
                zIndex: 200,
                depth: true
            }).source(sources).size(5)
                .shape('line')
                .color('red')
                .animate({
                    interval: 1, // 间隔
                    duration: 2, // 持续时间，延时
                    trailLength: 2 // 流线长度
                });



            this.scene?.addLayer(this.sourceLayer);

        }
    }
}