<template>
  <div class="detail">
    <div class="header" @click="$emit('changeVisible')">
      <div class="title">水质监测站详情</div>
      <div class="extra">
        <a-icon type="close" />
      </div>
    </div>

    <div class="content" style="padding-top: 2vh; position: relative">
      <Navigator :lng="detail.x" :lat="detail.y" />

      <div class="item">
        <div class="label">编号：</div>
        <div class="value">{{ detail.code }}</div>
      </div>
      <div class="item">
        <div class="label">名称：</div>
        <div class="value">{{ detail.name }}</div>
      </div>
    </div>

    <a-tabs v-model="activeTab">
      <a-tab-pane key="1" tab="实时数据">
        <div class="grid">
          <div class="item">
            <div class="label">溶解氧：</div>
            <div class="value">
              {{ realTimeData.do ? realTimeData.do + " mg/L" : "--" }}
            </div>
          </div>
          <!--
          <div class="item">
            <div class="label">透明度：</div>
            <div class="value">{{ realTimeData.ss ? realTimeData.ss + "cm" : "--" }}</div>
          </div>
          <div class="item">
            <div class="label">氧化还原电位：</div>
            <div class="value">{{ realTimeData.orp ? realTimeData.orp + "mV" : "--" }}</div>
          </div>
        -->

          <div class="item">
            <div class="label">浊度：</div>
            <div class="value">
              {{
                realTimeData.turbidity ? realTimeData.turbidity + " NTU" : "--"
              }}
            </div>
          </div>
          <div class="item">
            <div class="label">电导率：</div>
            <div class="value">
              {{
                realTimeData.conductivity
                  ? realTimeData.conductivity + " uS/cm"
                  : "--"
              }}
            </div>
          </div>
          <!---->
          <div class="item">
            <div class="label">化学需氧量：</div>
            <div class="value">
              {{ realTimeData.cod ? realTimeData.cod + " mg/L" : "--" }}
            </div>
          </div>

          <div class="item">
            <div class="label">酸碱度：</div>
            <div class="value">
              {{ realTimeData.ph ? realTimeData.ph : "--" }}
            </div>
          </div>

          <div class="item">
            <div class="label">氨氮：</div>
            <div class="value">
              {{ realTimeData.nh3n ? realTimeData.nh3n + " mg/L" : "--" }}
            </div>
          </div>
          <!--2023.12.8新增-->
          <div class="item">
            <div class="label">温度：</div>
            <div class="value">
              {{
                realTimeData.temperature
                  ? realTimeData.temperature + " ℃"
                  : "--"
              }}
            </div>
          </div>
          <div class="item">
            <div class="label">更新时间：</div>
            <div class="value">
              {{ realTimeData.ts ? realTimeData.ts : "--" }}
            </div>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="统计数据">
        <a-space style="margin-bottom: 1em; padding: 0 1em">
          <a-range-picker size="small" style="width: 12vw" v-model="selectedDate" />
          <a-select size="small" placeholder="指标" style="width: 6vw" v-model="queryOption">
            <a-select-option v-for="item in queryOptions" :key="item.value">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <a-select placeholder="间隔" size="small" style="width: 4vw" v-model="interval">
            <a-select-option v-for="item in statInterval" :key="item.value">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <a-button type="primary" size="small" @click="query">查询</a-button>
          <a-button size="small" @click="reset">重置</a-button>
        </a-space>

        <WaterQualityChart :list="chartList" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import WaterQualityChart from "./water-quality-chart.vue";
import moment from "moment";
import request from "@/api/request";

import { mapGetters } from "vuex";

import Navigator from "@/components/navigator";

export default {
  props: {
    type: {
      type: String,
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    WaterQualityChart,
    //Chart,
    Navigator,
  },

  data() {
    return {
      info: {},
      loading: false,
      chartList: [],
      activeTab: "1",
      realTimeData: {},
      selectedDate: [moment().subtract(1, "months"), moment()],
      interval: "1d",
      queryOption: "ph",
      queryOptions: [
        {
          name: "透明度",
          value: "ss",
        },
        {
          name: "cod",
          value: "cod",
        },
        {
          name: "orp",
          value: "orp",
        },
        {
          name: "氨氮",
          value: "nh3n",
        },
        {
          name: "酸碱度",
          value: "ph",
        },
        {
          name: "溶解氧",
          value: "doxygen",
        },
      ],
    };
  },

  watch: {
    detail() {
      this.getRealTimeData();
      this.getHistoryData();
    },
  },

  mounted() {
    this.getRealTimeData();
    this.getHistoryData();
  },

  methods: {
    getHistoryData() {
      this.loading = true;

      if (this.selectedDate.length !== 2) return;
      let that = this;
      request({
        url: "/data-service/dev/stat",
        method: "post",
        data: {
          devType: "waterquality",
          beginDate: this.selectedDate[0].format("YYYY-MM-DD") + " 00:00:00",
          endDate: this.selectedDate[1].format("YYYY-MM-DD") + " 00:00:00",
          eids: [this.detail.sn],
          interval: this.interval,
        },
      }).then((res) => {
        if (Array.isArray(res)) {
          const select_historydata = res.map((item) => {
            const xname = that.queryOption;

            return {
              ts: item.ts,
              [xname]: item[xname],
            };
          });

          this.chartList = select_historydata;
        }
      });
    },
    getRealTimeData() {
      /** 获取水质监测箱实时的数据 */
      request({
        url: "/data-service/dev/realTime",
        method: "post",
        data: {
          devType: this.detail.type, // 获取类型
          eids: [this.detail.sn],
        },
      })
        .then((res) => {
          this.realTimeData = res;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getName(name) {
      if (name === "cod") {
        return "COD";
      } else if (name === "do") {
        return "溶解氧";
      } else if (name === "nh3n") {
        return "氨氮";
      } else if (name === "ph") {
        return "Ph";
      } else if (name === "ss") {
        return "浊度";
      } else if (name === "orp") {
        return "ORP";
      } else {
        return "";
      }
    },
    getValue(name, value) {
      if (name === "ss") {
        return parseFloat(value).toFixed(2);
      } else {
        return value;
      }
    },
    getUnit(name) {
      if (name === "cod") {
        return "mg/L";
      } else if (name === "do") {
        return "mg/L";
      } else if (name === "nh3n") {
        return "mg/L";
      } else if (name === "ph") {
        return "";
      } else if (name === "ss") {
        return "NTU";
      } else if (name === "orp") {
        return "MV";
      } else {
        return "";
      }
    },
    query() {
      this.getHistoryData();
    },
    reset() {
      this.selectedDate = [moment().subtract(1, "months"), moment()];
      this.interval = "1d";
      this.getHistoryData();
    },
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    statInterval() {
      return this.findDataDict("statInterval");
    },
  },
};
</script>


<style lang="less" scoped>
.detail {
  position: fixed;
  top: 11vh;
  right: 1vw;
  bottom: 2vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);

  width: 50vw;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  &>.header {
    background-color: var(--theme-color);
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .content {
    padding: 0 1vw;
    display: flex;
    flex-direction: column;
    gap: 1vh;

    .item {
      display: flex;
      align-items: center;

      .label {
        min-width: 4em;
      }

      .value {
        color: var(--theme-color);
      }
    }

    .col-2 {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  .grid {
    padding: 0 1vw 2vh;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1vh;

    .item {
      display: flex;
      align-items: center;

      .label {
        min-width: 4em;
      }

      .value {
        color: var(--theme-color);
      }
    }

    .col-2 {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}

.info-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vh 1vw;

  &>div:first-child {
    font-weight: bold;
  }

  &>div:last-child {
    color: #999;
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .refresh {
    margin-left: 4px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 1s linear infinite;
}
</style>