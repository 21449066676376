import { fetchDrain } from "../api/rain";
import { PointLayer } from '@antv/l7'

export default {

    data() {
        return {
            raindrainDetail: {},
            raindrainVisible: false,
        }
    },

    watch: {
        raindrainVisible() {
            if (!this.raindrainVisible) {
                this.closeFacilityActive();
            }
        }
    },

    methods: {
        getraindrain() {

            if (!this.raindrainLayer) {

                fetchDrain({
                }).then((res) => {
                    if (res && Array.isArray(res.locs)) {
                        const list = Object.freeze(res.locs);

                        const zoom = this.map?.getZoom() || 3;

                        this.raindrainLayer = new PointLayer({
                            zIndex: 100,
                        })

                            .source(list.map(item => {
                                return {
                                    id: item[0],
                                    type: item[1],
                                    x: item[3],
                                    y: item[4],
                                }
                            }), {
                                parser: {
                                    type: 'json',
                                    x: 'x',
                                    y: 'y'
                                }
                            })
                            .shape('raindrain')
                            .size(zoom * 0.55)

                        const that = this;
                        this.raindrainLayer.on('click', function (e) {
                            console.log('e', e)
                            that.raindrainDetail = e.feature;

                                 that.setFacilityActive(e.feature, 'raindrainVisible')
                        })


                        this.scene?.addLayer(this.raindrainLayer);


                    }
                });



            } else {
                this.raindrainLayer.show();
            }

        },

        removeraindrain() {
            this.raindrainVisible = false;
            this.raindrainLayer?.hide();
        },

    }
}

