<template>
  <div id="scene" :style="{ width: '100%' }"></div>
</template>


<script>
import {
  Scene,
  Color,
  PerspectiveCamera,
  AmbientLight,
  DirectionalLight,
  PointLight,
  WebGLRenderer,
  Mesh,
  MathUtils,
  Box3,
  Vector3,
  MeshPhongMaterial,
} from "three";
import { TextGeometry } from "three/examples/jsm/geometries/TextGeometry";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import { FontLoader } from "three/examples/jsm/loaders/FontLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

export default {
  props: {
    url: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    width: {
      type: Number,
      default: 250,
    },
    height: {
      type: Number,
      default: 500,
    },
    //   liquidLevel: {
    //     type: Number,
    //     default: 0,
    //   },
    text: {
      type: Array,
      default: () => [],
    },
  },

  watch: {
    text() {
      this.renderText();
    },
  },

  mounted() {
    this.initScene();
    this.renderByURL(this.url);

    // 组件卸载的时候要清空 model 和 font scene
  },

  destroyed() {
    this.clearModel();
    this.controls?.dispose();
    this.renderer?.dispose();
  },

  methods: {
    initScene() {
      //const width = document.getElementById("scene").clientWidth;
      //console.log("width", width);
      this.scene = new Scene();
      this.scene.background = new Color(0x000000);

      const ambient = new AmbientLight(0xffffff, 0.5);
      this.scene.add(ambient);

      const light3 = new DirectionalLight(0xffffff, 0.8);
      light3.position.set(400, 400, 400);
      this.scene.add(light3);

      const light4 = new DirectionalLight(0xffffff, 0.8);
      light4.position.set(-400, -400, 400);
      this.scene.add(light4);

      const light5 = new PointLight(0xffffff, 0.8);
      light5.position.set(400, 0, 0);
      this.scene.add(light5);

      this.camera = new PerspectiveCamera(
        75,
        this.width / this.height,
        0.1,
        1000
      );
      this.camera.position.set(50, 50, 50);

      this.renderer = new WebGLRenderer({
        antialias: true,
        preserveDrawingBuffer: true,
      });
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(
        document.getElementById("scene").clientWidth || 420,
        this.height
      ); // 宽高
      document.getElementById("scene").appendChild(this.renderer.domElement);

      this.controls = new OrbitControls(this.camera, this.renderer.domElement); //创建控件对象
      this.controls.addEventListener("change", this.render); //监听鼠标、键盘事件

      // const planeList = [new THREE.Plane(new THREE.Vector3(1, 0, 0), 20)];
      // renderer.clippingPlanes = planeList;
      // renderer.localClippingEnabled = true;

      this.fbxLoader = new FBXLoader();

      const fontLoader = new FontLoader();

      const that = this;
      fontLoader.load("/font2.json", function (f) {
        that.font = f;
        that.renderText();
      });

      this.render();
    },

    renderByURL(url) {
      this.clearModel();

      if (url.indexOf(".fbx") > -1) {
        const hide = this.$message.loading("模型加载中...");

        const that = this;

        this.fbxLoader.load(url, function (model) {
          //console.log("fbx", model);

          model.children.forEach((item) => {
            if (item.material && item.material.color) {
              item.material.color = new Color(0x404040);
            }

            if (that.type === "well") {
              if (
                item.name.indexOf("基本墙_300mm_6069447") === 0 ||
                item.name.indexOf("基本墙_300mm_6069552") === 0
              ) {
                // 外面两个墙隐藏
                item.material.transparent = true;
                item.material.opacity = 0.3;
              }
              if (item.name.indexOf("shui_shui_6090845") === 0) {
                // 液位透明高亮
                item.material.color = new Color(0x1890ff);
                item.material.transparent = true;
                item.material.opacity = 0.3;
                item.material.depthWrite = false;
                item.material.depthTest = false;
              }

              if (
                item.name.indexOf("旋转堰1_旋转堰_6100039") === 0 ||
                item.name.indexOf("旋转堰1_旋转堰_6099127") === 0
              ) {
                // 闸门挡板
                item.material.transparent = true;
                item.material.opacity = 0.3;
              }
            }

            if (that.type === "check-well") {
              if (item.name.indexOf("基本墙_80_6100214") === 0) {
                // 外面两个墙隐藏
                item.material.transparent = true;
                item.material.opacity = 0.3;
              }
            }
          });

          model.rotateX(MathUtils.degToRad(-90));
          model.rotateZ(MathUtils.degToRad(90));

          // 智慧截流井
          if (that.type === "well") {
            const { x, y, z } = model.scale;
            model.scale.set(x + 4, y + 4, z + 4);
          }
          // 检查井
          if (that.type === "check-well") {
            const { x, y, z } = model.scale;
            model.scale.set(x + 3, y + 3, z + 3);
          }

          that.scene.add(model);
          that.model = model;
          that.setCenter();

          hide();
        });
      } else {
        alert("不支持这种格式");
      }
    },

    clearModel() {
      if (this.model) {
        this.model.children?.forEach((item) => {
          if (item && item.geometry) {
            item.geometry.dispose();
          }
          if (item && item.material) {
            item.material.dispose();
          }
          if (item) {
            item.clear();
          }
        });
        this.scene?.remove(this.model);
        this.model = null;
      }
    },

    setCenter() {
      const { model } = this;
      if (model) {
        // 获得包围盒得min和max
        const box = new Box3().setFromObject(model);
        // 返回包围盒的中心点

        const center = box.getCenter(new Vector3());
        model.position.x += model.position.x - center.x;
        model.position.y += model.position.y - center.y;
        model.position.z += model.position.z - center.z;
        this.render();
      }
    },

    renderText() {
      const { font } = this;

      if (this.fontModelList) {
        this.fontModelList.forEach((item) => {
          this.scene.remove(item);
        });
        this.fontModelList.length = 0;
      } else {
        this.fontModelList = [];
      }

      this.text.forEach(({ text = "", position = [] }) => {
        const geometry = new TextGeometry(text.toString(), {
          font,
          size: 2,
          height: 0,
          curveSegments: 1,
          bevelEnabled: true,
          bevelThickness: 1,
          bevelSize: 0,
          bevelSegments: 0,
        });

        const materials = [
          new MeshPhongMaterial({
            color: 0xff0000,
            flatShading: true,
          }), // front
          new MeshPhongMaterial({ color: 0xff0000 }), // side
        ];

        const fontModel = new Mesh(geometry, materials);
        fontModel.position.set(position[0], position[1], position[2]);
        fontModel.rotateY(MathUtils.degToRad(90));
        this.scene.add(fontModel);
        this.fontModelList.push(fontModel);
      });

      this.render();
    },

    render() {
      //console.log("render");
      this.renderer?.render(this.scene, this.camera); //执行渲染操作
    },
  },
};
</script>