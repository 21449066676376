import request from '../request'

export function fetchRainPipe(data) {
    return request({
        url: '/model-analysis/pipe/rain/locations',
        method: 'post',
        data,
    })
}

export function fetchRainPipeDetail(data) {
    return request({
        url: '/model-analysis/pipe/rain/query',
        method: 'post',
        data
    })
}

export function fetchWell() {
    return request({
        url: '/model-analysis/pipe/rain/well/locations',
        method: 'post',
        data: {}
    })
}

export function fetchWellDetail(data) {
    return request({
        url: '/model-analysis/pipe/rain/well/query',
        method: 'post',
        data
    })
}

export function fetchDrain(data) {
    return request({
        url: '/model-analysis/pipe/rain/drain/locations',
        method: 'post',
        data
    })
}

export function fetchDrainDetail(data) {
    return request({
        url: '/model-analysis/pipe/rain/drain/query',
        method: 'post',
        data
    })
}

// 获取每条河的经纬度
export function fetchRiverDetail(data) {
    return request({
        url: '/model-analysis/pipe/area/locations',
        method: 'post',
        data: {
            ...data,
            district: 'huangshan'
        }
    })
}

// 获取所有的河
export function fetchRiverList(data) {
    return request({
        url: '/model-analysis/pipe/area',
        method: 'post',
        data
    })
}

// 获取所有的河
export function fetchWaterFacilityList(data) {
    return request({
        url: '/model-analysis/pipe/area/devices',
        method: 'post',
        data
    })
}

// 获取所有的设备，包括液位计等
export function fetchFacility(data) {
    return request({
        url: '/model-analysis/pipe/rain/device/query',
        method: 'post',
        data: {
            ...data,
            district: 'huangshan'
        }
    })
}