import request from '../request'


export function send_move(ipc_name, direction) {
    request({
        url: '/video-watch/move',
        method: 'get',
        params: {
            'ipc_name': ipc_name,
            'direction': direction
        }
    })
}

export function send_move_consist(ipc_name, direction, flag) {
    request({
        url: '/video-watch/move_consist',
        method: 'get',
        params: {
            'ipc_name': ipc_name,
            'direction': direction,
            'flag': flag
        }
    })
}

export function send_zoom(ipc_name, direction, flag) {
    request({
        url: '/video-watch/zoom_consist',
        method: 'get',
        params: {
            'ipc_name': ipc_name,
            'direction': direction,
            'flag': flag
        }
    })
}

export function start_control(ipc_name) {
    request({
        url: '/video-watch/start_control',
        method: 'get',
        params: {
            'ipc_name': ipc_name
        }
    })
}

export function stop_control(ipc_name) {
    request({
        url: '/video-watch/stop_control',
        method: 'get',
        params: {
            'ipc_name': ipc_name
        }
    })
}

