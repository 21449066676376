<template>
  <div>
    <div class="right" v-if="!isHarmonyOS()">
      <a-icon type="printer" @click="$print(id)" title="打印" />
      <a-icon type="download" @click="$download(id)" title="下载" />
    </div>
    <div :id="id"></div>
  </div>
</template>


<script>
import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent
} from "echarts/components";
import { CanvasRenderer } from "echarts/renderers";

import { isHarmonyOS } from "../../../utils/os";


echarts.use([
  LineChart,
  CanvasRenderer,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  MarkLineComponent
]);
export default {
  props: {
    id: {
      type: String,
    },
    list: {
      type: Array,
      default: () => [],
    },
    markline1: {
      type: Number,
      default: 1,
    },
    markline2: {
      type: Number,
      default: 2,
    },
  },

  watch: {
    list() {
      this.renderChart();
    },
  },

  mounted() {
    var chartDom = document.getElementById(this.id);
    chartDom.style.height = "25vh";
    chartDom.style.width = "100%";
    this.chart = echarts.init(chartDom);
    this.renderChart();
  },

  methods: {
    isHarmonyOS,
    renderChart() {

      // 生成markline_data
      const markline_data = [
        {
          yAxis: this.markline1, // 使用默认值或传入的值
          name: '管底',
          lineStyle: {
            color: 'red',
            type: 'dashed',
          },
          label: {
            position: 'middle', // 可选值：'start', 'middle', 'end', 'insideStart', 'insideEnd'
            formatter: `{b}: {c} m`,
          },
        },
        {
          yAxis: this.markline2, // 使用默认值或传入的值
          name: '井口',
          lineStyle: {
            color: 'blue',
            type: 'dashed',
          },
          label: {
            position: 'middle', // 可选值：'start', 'middle', 'end', 'insideStart', 'insideEnd',
            formatter: `{b}: {c} m`,
          },
        },
      ]

      const data = this.list.map((item) => item.value)
      // 计算数据的最小值和最大值
      const minValue = Math.min(...data);
      const maxValue = Math.max(...data);

      // 动态设置Y轴的最小值和最大值
      const yAxisMin = Math.min(0, minValue); // 保证从0开始
      const yAxisMax = Math.max(this.markline2, maxValue); // 确保最小高度为1.2
      var option = {
        xAxis: {
          type: "category",
          data:
            this.list.length > 0
              ? this.list.map((item) => item.category)
              : [1, 2, 3, 4, 5, 6, 7, 8],
          splitLine: {
            show: true,
          },
          axisLine: {
            show: true,
          },
        },
        yAxis: {
          type: "value",
          min: yAxisMin,
          max: yAxisMax,
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
          },
          axisLabel: {},
        },

        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },

        grid: {
          left: "12%",
          top: "10%",
          right: "3%",
          bottom: "15%",
        },

        series: [
          {
            name: "",
            type: "line",
            smooth: true,
            // data: this.list.map((item) => item.flowrate),
            data:
              this.list.length > 0
                ? this.list.map((item) => item.value)
                : [10, 20, 30, 20, 10, 5, 20, 30],
            markLine: {
              data: markline_data
            },
          },
        ],

      };

      option && this.chart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.right {
  gap: 0.5em;
  margin-right: 1em;
  cursor: pointer;
}
</style>