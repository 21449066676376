<template>
  <div class="detail">
    <div class="header">
      <div class="title">获取区域内设施</div>
      <div class="extra" @click="cancel">
        <a-icon type="close" />
      </div>
    </div>

    <div class="body">
      <div>
        {{ message }}
      </div>
      <div style="padding-top: 2vh"></div>
      <a-radio-group v-model="selected">
        <a-radio value="矩形">
          <span class="text">矩形</span>
        </a-radio>
        <a-radio value="圆形">
          <span class="text">圆形</span>
        </a-radio>
        <a-radio value="多边形">
          <span class="text">多边形</span>
        </a-radio>
      </a-radio-group>

      <div class="center">
        <a-space>
          <a-button @click="clear">清除</a-button>
          <a-button @click="query" type="primary">查询设备</a-button>
        </a-space>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    overlays: {
      type: Array,
      default: () => [],
    },
    mouseTool: {},
  },
  data() {
    return {
      selected: null,
    };
  },

  computed: {
    message() {
      if (this.selected === "矩形" || this.selected === "圆形") {
        return "请用鼠标在地图上左键按住一个位置开始拖动";
      } else if (this.selected === "多边形") {
        return "请点击地图任意位置，结束多边形绘制，请点击鼠标右键";
      } else {
        return "选择图形";
      }
    },
  },

  watch: {
    selected() {
      if (this.selected === "矩形") {
        this.mouseTool?.rectangle({
          fillColor: "#00b0ff",
          strokeColor: "#80d8ff",
          //同Polygon的Option设置
        });
      } else if (this.selected === "圆形") {
        this.mouseTool?.circle({
          fillColor: "#00b0ff",
          strokeColor: "#80d8ff",
          //同Circle的Option设置
        });
      } else if (this.selected === "多边形") {
        this.mouseTool?.polygon({
          fillColor: "#00b0ff",
          strokeColor: "#80d8ff",
          //同Polygon的Option设置
        });
      }
    },
  },

  methods: {
    cancel() {
      this.selected = null;
      this.$emit("cancel");
    },
    clear() {
      // this.selected = null;
      this.$emit("clear");
    },

    query() {
      const list = [];

      // if (this.overlays[0] && this.overlays[0]._position) {
      //   list.push(
      //     this.overlays.map((item) => {
      //       return [item._position.lng, item._position.lat];
      //     })
      //   );
      // }

      this.overlays.forEach((item) => {
        if (Array.isArray(item.aE) && item.aE[0]) {
          list.push(item.aE[0]);
        }

        if (item._opts && item._opts.path) {
          list.push(item._opts.path);
        }
      });

      this.$emit("query", list);
    },
  },
};
</script>


<style lang="less" scoped>
.detail {
  position: fixed;
  top: 11vh;
  right: 1vw;
  bottom: 2vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  width: 20vw;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  & > .header {
    background-color: var(--theme-color);
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .body {
    padding: 2vh;
    .center {
      margin-top: 2em;
    }
  }

  .text {
    color: #fff;
  }
}
</style>