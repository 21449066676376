<template>
  <div class="detail">
    <div class="header" @click="$emit('changeVisible')">
      <div class="title">液位计详情</div>
      <div class="extra">
        <a-icon type="close" />
      </div>
    </div>

    <div class="well" v-if="well.id">
      <template v-if="well.pipeFrom && well.pipeTo">
        <FbxLoader url="/flow.fbx" type="check-well" :width="480" :height="300" :text="labels" />
      </template>
      <template v-else-if="!well.pipeFrom && well.pipeTo">
        <FbxLoader url="/pipe-one-side.fbx" type="check-well" :width="480" :height="280" :text="labels" />
      </template>
      <template v-else-if="well.pipeFrom && !well.pipeTo">
        <FbxLoader url="/pipe-one-side.fbx" type="check-well" :width="480" :height="280" :text="labels" />
      </template>
      <template v-else>
        <FbxLoader url="/check-well.fbx" type="check-well" :width="480" :height="280" :text="labels" />
      </template>
    </div>

    <div class="content" style="padding-top: 2vh; position: relative">
      <div class="item">
        <div class="label">名称：</div>
        <div class="value">{{ detail.name }}</div>
      </div>
      <div class="item">
        <div class="label">编号：</div>
        <div class="value">{{ detail.code }}</div>
      </div>

      <Navigator :lng="detail.x" :lat="detail.y" />
    </div>

    <a-tabs v-model="activeTab">
      <a-tab-pane key="1" tab="实时数据">
        <div class="content" style="padding-bottom: 2vh">
          <div class="item">
            <div class="label">液位：</div>
            <div class="value">
              {{
                nowData.liquidLevel !== undefined
                  ? nowData.liquidLevel + " m"
                  : "--"
              }}
            </div>
          </div>

          <div class="item">
            <div class="label">距离井底：</div>
            <div class="value">
              {{
                nowData.distance !== undefined ? nowData.distance + " m" : "--"
              }}
            </div>
          </div>
          <div class="item">
            <div class="label">上报时间：</div>
            <div class="value">{{ nowData.ts ? nowData.ts : "--" }}</div>
          </div>

          <br />
          <div class="item">
            <div class="label">井深：</div>
            <div class="value">{{ well ? well.depth + ' m' : "--" }}</div>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="统计数据">
        <a-space style="margin-bottom: 1em; padding: 0 1em">
          <a-range-picker size="small" style="width: 10vw" v-model="selectedDate" :allowClear="false" />
          <a-select placeholder="指标" size="small" style="width: 3vw" v-model="interval">
            <a-select-option v-for="item in statInterval" :key="item.value">
              {{ item.name }}
            </a-select-option>
          </a-select>
          <a-button type="primary" size="small" @click="query">查询</a-button>
          <a-button size="small" @click="reset">重置</a-button>
        </a-space>

        <Chart id="liquid-chart" :list="list.map((item) => ({
          category: item.ts,
          value: item.liquidLevel,
        }))
          " :markline1="PipeBottom" :markline2="WellTop" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import moment from "moment";
import Chart from "./chart/index.vue";

import request from "@/api/request";

import FbxLoader from "@/components/fbx-loader";
/** 
 * 需要家一个查询液位计绑定的井的信息，选择其中的部分作为输入給到liquid-chart(Chart组件力)
 * 画成图上的限制值。
 * 
*/

function fetchBindWell({ id }) {
  return request({
    url: "/model-analysis/pipe/device/query/" + id,
    method: "get", // 2023.4.17 陈政把这个查询改成了get请求
  });
}

import { mapGetters } from "vuex";
import Navigator from "@/components/navigator";

export default {
  props: {
    type: {
      type: String,
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    Chart,
    FbxLoader,
    Navigator,
  },

  data() {
    return {
      info: {},
      loading: false,
      chartList: [],
      activeTab: "1",

      selectedDate: [moment().subtract(1, "months"), moment()],
      interval: "1d",

      nowData: {},

      list: [],

      well: {},
    };
  },

  watch: {
    detail() {
      this.getNowData();
      this.getData();
      this.refreshBindWell()
    },
  },

  computed: {
    pipesFrom() {
      return this.well.pipeFrom ?? {};
    },
    pipesTo() {
      return this.well.pipeTo ?? {};
    },

    ...mapGetters("setting", ["findDataDict"]),
    WellTop() {
      return this.well.depth;
    },
    PipeBottom() {
      const { pipesFrom, pipesTo, well } = this;

      // 如果 pipesFrom 存在，则计算 a，否则设为 null
      let a = pipesFrom ? parseFloat(well.depth - pipesFrom.buringDepthTo) : null;
      a = isNaN(a) ? null : a;
      // 如果 pipesTo 存在，则计算 b，否则设为 null
      let b = pipesTo ? parseFloat(well.depth - pipesTo.buringDepthTo) : null;
      b = isNaN(b) ? null : b;
      // 过滤掉 null 值并返回最小值
      const values = [a, b].filter(v => v !== null);
      //console.log(values)
      return values.length > 0 ? Math.min(...values) : null;
    },
    statInterval() {
      return this.findDataDict("statInterval");
    },
    labels() {
      const { pipesFrom, pipesTo, well, nowData } = this;
      return [
        {
          text:
            "管顶高程：" +
            parseFloat(
              pipesFrom.elevationTo + pipesFrom.diameter * 0.001
            ).toFixed(3) +
            "m",
          position: [0, -10, 40],
        },

        {
          text:
            "距离井底：" +
            parseFloat(well.depth - pipesFrom.buringDepthTo).toFixed(3) +
            "m",
          position: [0, -17, 40],
        },

        {
          text: "管底高程：" + pipesFrom.elevationTo + "m",
          position: [0, -20, 40],
        },

        {
          text:
            "管顶高程：" +
            parseFloat(
              pipesTo.elevationFrom + pipesTo.diameter * 0.001
            ).toFixed(3) +
            "m",
          position: [0, -10, -15],
        },

        {
          text:
            "距离井底：" +
            parseFloat(well.depth - pipesTo.buringDepthFrom).toFixed(3) +
            "m",
          position: [0, -17, -15],
        },

        {
          text:
            "管底高程：" + parseFloat(pipesTo.elevationFrom).toFixed(3) + "m",
          position: [0, -20, -15],
        },

        {
          // 最上面 中间
          text: "地面高程：" + well.elevation + "m",
          position: [0, 25, 10],
        },
        {
          // 最下面 中间
          text:
            "井底高程：" +
            parseFloat(well.elevation - well.depth).toFixed(3) +
            "m",
          position: [0, -30, 10],
        },

        {
          // 最左侧中间
          text: "井深：" + well.depth + "m",
          position: [0, 2, 23],
        },

        {
          // 最右侧中间
          text: nowData.liquidLevel
            ? "距离井盖：" + (well.depth - nowData.liquidLevel) + "m"
            : "",
          position: [0, 2, -15],
        },
      ];
    },
  },

  mounted() {
    this.getNowData();
    this.getData();
    // 切换BindWell ，如果未找到well应置0
    this.refreshBindWell()
  },

  methods: {
    refreshBindWell() {
      fetchBindWell({
        id: this.detail.id,
      }).then((res) => {
        //console.log("res", res);
        if (res && res.deviceWellVo) {
          this.well = {
            ...res.deviceWellVo,
            pipeFrom:
              res.deviceWellVo.pipesFrom && res.deviceWellVo.pipesFrom[0]
                ? res.deviceWellVo.pipesFrom[0]
                : null,
            pipeTo:
              res.deviceWellVo.pipesTo && res.deviceWellVo.pipesTo[0]
                ? res.deviceWellVo.pipesTo[0]
                : null,
          };
        } else {
          this.well = {}
        }
      });
    },
    getNowData() {
      request({
        url: "/data-service/dev/realTime",
        method: "post",
        data: {
          devType: "liquidLevel",
          eids: [this.detail.sn],
        },
      }).then((res) => {
        this.nowData = res;
      });
    },

    getData() {
      this.loading = true;

      if (this.selectedDate.length !== 2) return;

      request({
        url: "/data-service/dev/stat",
        method: "post",
        data: {
          devType: "liquidLevel",
          beginDate: this.selectedDate[0].format("YYYY-MM-DD") + " 00:00:00",
          endDate: this.selectedDate[1].format("YYYY-MM-DD") + " 00:00:00",
          eids: [this.detail.sn],
          interval: this.interval,
        },
      }).then((res) => {
        //console.log("liquid detail", res);
        if (Array.isArray(res)) {
          this.list = res;
        }
      });
    },

    query() {
      this.getData();
    },
    reset() {
      this.selectedDate = [moment().subtract(1, "months"), moment()];
      this.interval = "1d";
      this.getData();
    },

    getName(name) {
      if (name === "cod") {
        return "COD";
      } else if (name === "do") {
        return "溶解氧";
      } else if (name === "nh3n") {
        return "氨氮";
      } else if (name === "ph") {
        return "Ph";
      } else if (name === "ss") {
        return "浊度";
      } else if (name === "orp") {
        return "ORP";
      } else {
        return "";
      }
    },
    getValue(name, value) {
      if (name === "ss") {
        return parseFloat(value).toFixed(2);
      } else {
        return value;
      }
    },
    getUnit(name) {
      if (name === "cod") {
        return "mg/L";
      } else if (name === "do") {
        return "mg/L";
      } else if (name === "nh3n") {
        return "mg/L";
      } else if (name === "ph") {
        return "";
      } else if (name === "ss") {
        return "NTU";
      } else if (name === "orp") {
        return "MV";
      } else {
        return "";
      }
    },
  },
};
</script>


<style lang="less" scoped>
.detail {
  position: fixed;
  top: 11vh;
  overflow-y: auto;
  right: 1vw;
  bottom: 2vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);

  width: 50vw;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  &>.header {
    background-color: var(--theme-color);
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .model {
    margin: 0 auto;
  }

  .content {
    padding: 0 1vw;
    display: flex;
    flex-direction: column;
    gap: 1vh;

    .item {
      display: flex;
      align-items: center;

      .label {
        width: 5em;
      }

      .value {
        color: var(--theme-color);
      }
    }
  }
}
</style>