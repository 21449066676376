<template>
  <div class="detail">
    <div class="header" @click="$emit('changeVisible')">
      <div class="title">流量计详情</div>
      <div class="extra">
        <a-icon type="close" />
      </div>
    </div>

    <div class="content" style="padding-top: 2vh; position: relative">
      <div class="item">
        <div class="label">编号：</div>
        <div class="value">{{ detail.code }}</div>
      </div>
      <div class="item">
        <div class="label">名称：</div>
        <div class="value">{{ detail.name }}</div>
      </div>

      <Navigator :lng="detail.x" :lat="detail.y" />
    </div>

    <a-tabs v-model="activeTab">
      <a-tab-pane key="1" tab="实时数据">
        <div class="content" style="padding-bottom: 2vh">
          <div class="item">
            <div class="label">更新时间：</div>
            <div class="value">
              {{ realTimeData.ts !== undefined ? realTimeData.ts : "--" }}
            </div>
          </div>
          <div class="item">
            <div class="label">流速：</div>
            <div class="value">
              {{
      realTimeData.flowRate !== undefined
        ? realTimeData.flowRate + " m/s"
        : "--"
    }}
            </div>
          </div>
          <div class="item">
            <div class="label">流量：</div>
            <div class="value">
              {{
        realTimeData.instantaneousFlow
          !== undefined
          ? realTimeData.instantaneousFlow
          + " m3/h"
          : "--"
      }}
            </div>
          </div>
          <div class="item">
            <div class="label">液位：</div>
            <div class="value">
              {{
        realTimeData.liquidLevel !== undefined
          ? realTimeData.liquidLevel + " m"
          : "--"
      }}
            </div>
          </div>
          <div class="item">
            <div class="label">距离井底：</div>
            <div class="value">
              {{
        realTimeData.distance !== undefined
          ? realTimeData.distance + " m"
          : "--"
      }}
            </div>
          </div>
          <div class="item">
            <div class="label">温度：</div>
            <div class="value">
              {{
        realTimeData.temperature !== undefined
          ? realTimeData.temperature + " ℃"
          : "--"
      }}
            </div>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="统计数据">
        <div style="width: 100%">
          <a-space style="margin-bottom: 1em; padding: 0 1em">
            <a-range-picker size="small" v-model="selectedDate" />
            <a-select placeholder="指标" size="small" v-model="queryOption">
              <a-select-option v-for="item in queryOptions" :key="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
            <a-select placeholder="间隔" size="small" v-model="interval">
              <a-select-option v-for="item in statInterval" :key="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
            <a-button type="primary" size="small" @click="getHistoryData">查询</a-button>
          </a-space>
          <FlowLineChart :list="chartList" />
        </div>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
import FlowLineChart from "./flow-line-chart.vue";

import request from "@/api/request";

import moment from "moment";
import { mapGetters } from "vuex";

import Navigator from "@/components/navigator";

export default {
  props: {
    type: {
      type: String,
    },
    detail: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    FlowLineChart,
    Navigator,
  },

  data() {
    return {
      info: {},
      loading: false,
      chartList: [],
      activeTab: "1",
      realTimeData: {},
      selectedDate: [moment().subtract(1, "months"), moment()],
      interval: "1d",
      queryOption: "liquidLevel",
      queryOptions: [
        // 历史数据查询选项
        {
          name: "温度",
          value: "temperature",
        },
        {
          name: "流速",
          value: "flowRate",
        },
        {
          name: "液位",
          value: "liquidLevel",
        },
        {
          name: "流量",
          value: "instantaneousFlow"
        }
      ],
    };
  },

  watch: {
    detail() {
      this.getRealTimeData();
      this.getHistoryData();
    },
  },

  mounted() {
    this.getRealTimeData();
    this.getHistoryData();
  },

  methods: {
    getRealTimeData() {
      if (!this.detail.sn || !this.detail.type) {
        return;
      }

      request({
        url: "/data-service/dev/realTime",
        method: "post",
        data: {
          devType: this.detail.type, // 获取类型
          eids: [this.detail.sn],
        },
      }).then((res) => {
        this.realTimeData = res;
      });
    },
    getHistoryData() {
      this.loading = true;

      if (this.selectedDate.length !== 2) return;
      let that = this;
      request({
        url: "/data-service/dev/stat",
        method: "post",
        data: {
          devType: this.detail.type,
          beginDate: this.selectedDate[0].format("YYYY-MM-DD") + " 00:00:00",
          endDate: this.selectedDate[1].format("YYYY-MM-DD") + " 00:00:00",
          eids: [this.detail.sn],
          interval: this.interval,
        },
      }).then((res) => {
        if (Array.isArray(res)) {
          const select_historydata = res.map((item) => {
            const xname = that.queryOption;

            return {
              ts: item.ts,
              [xname]: item[xname],
            };
          });

          this.chartList = select_historydata;
        }
      });
    },
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    statInterval() {
      return this.findDataDict("statInterval");
    },
  },
};
</script>


<style lang="less" scoped>
.detail {
  position: fixed;
  top: 11vh;
  right: 1vw;
  bottom: 2vh;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.7);

  width: 50vw;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  &>.header {
    background-color: var(--theme-color);
    cursor: pointer;
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vh 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .content {
    padding: 0 1vw;
    display: flex;
    flex-direction: column;
    gap: 1vh;

    .item {
      display: flex;
      align-items: center;
      margin-bottom: 1vh;

      .label {
        min-width: 4em;
      }

      .value {
        color: var(--theme-color);
      }
    }

    .col-2 {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }
}

.info-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1vh 1vw;

  &>div:first-child {
    font-weight: bold;
  }

  &>div:last-child {
    color: #999;
    font-size: 12px;
    display: flex;
    align-items: center;
  }

  .refresh {
    margin-left: 4px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.rotate {
  animation: rotate 1s linear infinite;
}
</style>