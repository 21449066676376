<template>
  <a-modal
    :visible="verticalVisible"
    title="纵断面分析"
    width="80vw"
    :footer="null"
    @cancel="$emit('close')"
  >
    <div id="vertical">
      <div class="vertical">
        <div class="chart">
          <div class="label" v-for="item in labels" :key="item">
            <span>
              {{ typeof item === "number" ? item.toFixed(2) : item }}
            </span>
          </div>
        </div>
        <div class="table">
          <div class="item">
            <span>地面高程</span>
          </div>
          <div class="item">
            <span>管顶高程(m)</span>
          </div>
          <div class="item">
            <span>管道埋深(m)</span>
          </div>
          <div class="item">
            <span>管长(m)</span>
          </div>

          <div class="item">
            <span>管径(mm)</span>
          </div>
          <div class="item">
            <span>管道材质</span>
          </div>
          <div class="item">
            <span>检查井编号</span>
          </div>
        </div>

        <div class="scale">
          <div class="x">
            <span>1:500</span>
          </div>
          <div class="y">
            <span>1:100</span>
          </div>
        </div>

        <div class="line-wrapper">
          <div
            class="line"
            v-for="(item, index) in connectList"
            :key="index"
            :style="{
              top: (max - item.elevation) * 20 + 10 + 'px',
              width: item.pipe ? item.pipe.length * 4 + 'px' : '20px',
              left: `calc(20% + ${getLeft(index) * 4}px)`,
            }"
          >
            <!-- width: item.pipe ? item.pipe.length / 5 + '%' : 'auto', -->
            <div
              class="well"
              v-if="item.depth"
              :style="{
                top: '0',
                left: '-5px',
                width: '10px',
                height: item.depth * 20 + 'px',
              }"
            ></div>
            <div
              v-if="item.pipe"
              class="pipe"
              :style="{
                top: (item.elevation - item.pipe.elevationFrom) * 20 - 2 + 'px',
                left: '0%',
                width: '100%',
                transform: getRotate(item.pipe),
              }"
            ></div>
            <div
              v-if="item.pipe"
              class="pipe"
              :style="{
                top:
                  (item.elevation -
                    (item.pipe.elevationFrom + item.pipe.diameter / 1000)) *
                    20 -
                  2 +
                  'px',
                left: '0%',
                width: '100%',
                transform: getRotate(item.pipe),
              }"
            ></div>

            <div
              v-if="item.pipe"
              class="horizon"
              :style="{
                top: '0%',
                left: '0%',
                width: '100%',
                transform: getHorizonRotate(item, index),
              }"
            >
              <div class="inner">
                <div class="shadow" v-for="item in 10" :key="item"></div>
              </div>
            </div>

            <span
              class="data"
              style="position: absolute; bottom: calc(263px + 49px)"
              >{{ item.elevation ? item.elevation.toFixed(2) : "" }}</span
            >
            <span
              class="data"
              style="position: absolute; bottom: calc(214px + 49px)"
            >
              <span class="old-data" v-if="index !== 0">
                {{
                  connectList[index - 1].pipe &&
                  connectList[index - 1].pipe.elevationTo
                    ? connectList[index - 1].pipe.elevationTo.toFixed(2)
                    : "&nbsp;"
                }}
              </span>
              {{
                item.pipe && item.pipe.elevationFrom
                  ? item.pipe.elevationFrom.toFixed(2)
                  : "&nbsp;"
              }}
            </span>

            <span
              class="data"
              style="position: absolute; bottom: calc(165px + 49px)"
            >
              <span class="old-data" v-if="index !== 0">
                {{
                  connectList[index - 1].pipe &&
                  connectList[index - 1].pipe.buringDepthTo
                    ? connectList[index - 1].pipe.buringDepthTo.toFixed(2)
                    : "&nbsp;"
                }}
              </span>

              {{
                item.pipe && item.pipe.buringDepthFrom
                  ? item.pipe.buringDepthFrom.toFixed(2)
                  : "&nbsp;"
              }}
            </span>

            <span class="value">
              <span v-if="item.pipe"> L= </span>
              {{ item.pipe ? item.pipe.length : "&nbsp;" }}</span
            >
            <span class="value">{{
              item.pipe ? item.pipe.diameter : "&nbsp;"
            }}</span>
            <span class="value">{{
              item.pipe ? item.pipe.material : "&nbsp;"
            }}</span>
            <span class="value">{{ item.code ? item.code : "&nbsp;" }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="right" style="margin-top: 1em" v-if="!isHarmonyOS()">
      <a-space>
        <a-button @click="$print('vertical')">打印</a-button>
        <a-button type="primary" @click="$download('vertical', '纵断面分析')"
          >下载</a-button
        >
      </a-space>
    </div>
  </a-modal>
</template>

<script>
import { isHarmonyOS } from "../../../utils/os";

export default {
  props: {
    verticalVisible: {
      type: Boolean,
      default: false,
    },
    connectList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      labels: [],
      max: 0,
      min: 0,
      size: 1,
    };
  },

  watch: {
    verticalVisible() {
      if (this.verticalVisible && this.connectList.length > 0) {
        console.log("set labels");
        let max = this.connectList[0].elevation;
        let min = max - this.connectList[0].depth - 3;
        this.connectList.forEach((item) => {
          if (item.elevation > max) {
            max = item.elevation;
            min = item.elevation - item.depth - 3;

            console.log("max min", max, min);
          }
          //   if (item.elevation < min) {
          //   }
        });
        // min = min;
        // max = max;
        const size = 1;
        const arr = [];
        for (let i = 0; i < 10; i++) {
          arr.push(max - i * size);
        }
        // arr.push(min);
        this.labels = arr;

        this.max = max;
        this.min = min;
        this.size = size;
      }
    },
  },

  methods: {
    isHarmonyOS,

    getLeft(index) {
      let allLength = 0;

      if (index !== 0) {
        for (let i = 0; i < index; i++) {
          const pipe = this.connectList[i].pipe ?? { length: 0 };
          allLength += pipe.length;
        }
      }

      return allLength;
    },

    getHorizonRotate(item, index) {
      const next = this.connectList[index + 1];

      if (next) {
        const num = item.elevation - next.elevation;
        const length = item.pipe
          ? item.pipe.length
            ? item.pipe.length
            : 1
          : 1;
        const deg = Math.atan(Math.abs(num) / length) * (180 / Math.PI);

        console.log("getHorizonRotate deg", deg);

        if (num > 0) {
          return `rotate(${deg * 5}deg)`;
        } else if (num < 0) {
          return `rotate(-${deg * 5}deg)`;
        } else {
          return "";
        }
      } else {
        return "";
      }
    },

    getRotate() {
      // pipe

      // const { buringDepthFrom = 0, buringDepthTo = 0, length = 1 } = pipe || {};
      // const num = buringDepthFrom - buringDepthTo;
      // const deg = Math.atan(Math.abs(num) / length) * (180 / Math.PI);
      // console.log("deg", deg);
      // if (num > 0) {
      //   return `rotate(${deg * 10}deg)`;
      // } else if (num < 0) {
      //   return `rotate(-${deg * 10}deg)`;
      // } else {
      //   return "";
      // }
      return "";
    },
  },
};
</script>

<style lang="less" scoped>
#vertical {
  // background-color: #fff;
}

.vertical {
  position: relative;

  .table {
    border-left: 1px solid #fff;
    border-top: 1px solid #fff;

    .item {
      border-bottom: 1px solid #fff;

      & > span:first-child {
        position: relative;
        z-index: 99;
      }

      span {
        height: 48px;
        line-height: 48px;
        display: inline-block;
        width: 10em;
        text-align: center;
        border-right: 1px solid #fff;
        font-size: 12px;
      }
    }
  }

  .chart {
    height: 200px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;

    .label {
      flex: 1;
      font-size: 12px;
      color: #fff;
      width: 10em;
      text-align: right;
      padding-right: 8px;
      position: relative;
      z-index: 99;

      span {
        position: relative;
        top: -0.5em;
      }

      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: -3px;
        width: 1px;
        background-color: #fff;
        border: 1px solid #fff;
      }
    }

    .label:nth-child(even) {
      &::after {
        background-color: #fff;
      }
    }
  }

  .scale {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 8px;

    .x {
      position: absolute;
      top: 0;
      left: 0;
      width: 8em;
      height: 2px;
      background-color: #fff;
      z-index: 200;
      text-align: center;

      &::after {
        content: "";
        position: absolute;
        top: -4px;
        right: 0;
        height: 0;
        width: 0;
        border-top: 5px solid transparent;
        border-left: 5px solid #fff;
        border-right: 0 solid transparent;
        border-bottom: 5px solid transparent;
        z-index: 98;
      }

      &::before {
        content: "";
        position: absolute;
        top: -4px;

        right: 2px;
        height: 0;
        width: 0;
        border-top: 5px solid transparent;
        border-left: 5px solid #fff;
        border-right: 0 solid transparent;
        border-bottom: 5px solid transparent;
        z-index: 99;
      }
    }

    .y {
      position: absolute;
      top: 0;
      left: 0;
      width: 2px;
      height: 8em;
      background-color: #fff;
      z-index: 100;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: -4px;
        height: 0;
        width: 0;
        border-top: 5px solid #fff;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 0 solid transparent;
        z-index: -2;
      }

      &::before {
        content: "";
        position: absolute;
        bottom: 2px;
        left: -4px;
        height: 0;
        width: 0;
        border-top: 5px solid #fff;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 0 solid transparent;
        z-index: -1;
      }

      span {
        position: absolute;
        top: 40%;
        left: -1em;
        transform: rotateZ(90deg);
      }
    }
  }

  .line-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // overflow: auto;
    overflow: overlay;
  }

  .line {
    position: absolute;
    bottom: 0;
    border-left: 1px dashed #666;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .well {
      background-color: transparent;
      position: absolute;
      top: 0;
      border: 2px solid #666;
    }

    .item {
      height: 48px;
    }

    .data {
      height: 1em;
      line-height: 1em;
      width: 44px;
      font-size: 12px;
      color: #fff;
      // transform: rotate(-90deg) translateY(-10px) translateX(0);
      transform: rotate(-90deg) translateY(-12px);
      border-bottom: 1px solid transparent;
      display: inline-block;
      box-sizing: content-box;
      position: relative;
      text-align: center;

      .old-data {
        position: absolute;
        top: -20px;
        left: 0;
        right: 0;
      }
    }

    .value {
      height: 48px;
      line-height: 48px;
      padding-left: 4px;
      display: inline-block;
      font-size: 10px;
      white-space: nowrap;
      color: #fff;
      border-bottom: 1px solid transparent;
      box-sizing: content-box;
      text-align: center;
    }
  }

  .horizon {
    position: absolute;
    height: 4px;
    background-color: #fff;

    .inner {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .shadow {
      height: 8px;
      background-color: #fff;
      width: 1px;
      transform: rotate(15deg);
    }
  }

  .pipe {
    height: 2px;
    background-color: #f00;
    position: absolute;
  }
}
</style>