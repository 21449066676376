<template>
  <div>
    <div class="left tool-bar">
      <span class="button" @click="across">
        <div class="image">
          <img :src="icon1" alt="" />
          <div class="loading" v-if="controlType === 'across'">
            <a-icon type="loading" />
          </div>
        </div>
        <span>横断面</span>
      </span>
      <span class="button" @click="vertical">
        <div class="image">
          <img :src="icon2" alt="" />
        </div>
        <span>纵断面</span>
      </span>
      <span class="button" @click="connect">
        <div class="image">
          <img :src="icon4" alt="" />
          <div class="loading" v-if="controlType === 'connect'">
            <a-icon type="loading" />
          </div>
        </div>
        <span> 连通性分析 </span>
      </span>
      <span class="button" @click="getDistance">
        <div class="image">
          <img :src="icon3" alt="" />
          <div class="loading" v-if="controlType === 'distance'">
            <a-icon type="loading" />
          </div>
        </div>
        <span>净距分析</span>
      </span>

      <span class="button" @click="source">
        <div class="image">
          <img :src="icon6" alt="" />
          <div class="loading" v-if="controlType === 'source'">
            <a-icon type="loading" />
          </div>
        </div>
        <span> 溯源分析 </span>
      </span>

      <span class="button" @click="getArea">
        <div class="image">
          <img :src="icon5" alt="" />
        </div>
        <span> 区域内设施 </span>
      </span>
    </div>

    <a-modal
      :visible="acrossVisible"
      title="横断面分析"
      width="80vw"
      :footer="null"
      @cancel="acrossVisible = false"
    >
      <div id="across">
        <div class="across">
          <div class="canvas">
            <div class="label label-title">管线高程</div>
            <div class="label" v-for="item in 11" :key="item">
              {{ 10 - item * 1 }}
            </div>
          </div>
          <div class="table">
            <div class="item">
              <span>地面高程</span>
            </div>
            <div class="item">
              <span>管线高程</span>
            </div>
            <div class="item">
              <span>埋深</span>
            </div>
            <div class="item">
              <span>管径</span>
            </div>
            <div class="item">
              <span>材质</span>
            </div>
          </div>

          <div
            class="line"
            v-for="(item, index) in crossRenderList"
            :key="index"
            :style="{
              top: item.wellFrom
                ? ((10 - item.wellFrom.elevation) / 5) * 18 + 30 + 'px'
                : '0',
              left: getCrossLeft(index),
              width: item.distance ? item.distance * 17 + 'px' : '60px',
            }"
          >
            <!-- width: item.distance ? item.distance * 5 + 'px' : '40px', -->
            <div
              class="pipe"
              :style="{ left: '-10px', width: '20px', height: '20px' }"
            >
              <span v-if="item.category === 'rain'">Y</span>
              <span v-if="item.category === 'sewage'">W</span>
            </div>
            <div class="distance" v-if="item.distance">
              {{ item.distance }}m
            </div>
            <span class="data" style="position: absolute; bottom: 175px">
              {{
                item.wellFrom
                  ? parseFloat(item.wellFrom.elevation).toFixed(2)
                  : ""
              }}
            </span>
            <span class="data" style="position: absolute; bottom: 130px">{{
              parseFloat(item.elevationFrom).toFixed(2)
            }}</span>
            <span class="data" style="position: absolute; bottom: 90px">
              {{ parseFloat(item.buringDepthFrom).toFixed(2) }}</span
            >
            <span class="data" style="position: absolute; bottom: 55px">{{
              item.diameter
            }}</span>
            <div class="data" style="position: absolute; bottom: 10px">
              <span>{{ item.material }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="right" style="margin-top: 1em" v-if="!isHarmonyOS()">
        <a-space>
          <a-button @click="$print('across')">打印</a-button>
          <a-button type="primary" @click="$download('across', '横断面分析')"
            >下载</a-button
          >
        </a-space>
      </div>
    </a-modal>

    <Vertical
      :verticalVisible="verticalVisible"
      :connectList="connectList"
      @close="verticalVisible = false"
    />
  </div>
</template>


<script>
import { fetchSewagePipeDetail } from "@/api/sewage";
import { fetchRainPipeDetail } from "@/api/rain";
import { mapMutations, mapState } from "vuex";
import Vertical from "./vertical.vue";
import { isHarmonyOS } from "../../../utils/os";

export default {
  props: ["pathForAcross"],

  components: {
    Vertical,
  },

  data() {
    return {
      icon1: require("@/assets/gis/tool1-active.png"),
      icon2: require("@/assets/gis/tool2-active.png"),
      icon3: require("@/assets/gis/tool3-active.png"),
      icon4: require("@/assets/gis/tool4-active.png"),
      icon5: require("@/assets/gis/tool5-active.png"),
      icon6: require("@/assets/gis/tool6-active.png"),

      acrossVisible: false,
      verticalVisible: false,

      connectList: [],

      crossRenderList: [], // 横断面列表

      figureVisible: false, // 选择图形类型
    };
  },
  computed: {
    ...mapState("gis", [
      "category",
      "type",
      "selectedWell",
      "connectDetail",
      "crossList",
      "clickedMarkers",

      "sewagePipeRawList",
      "rainPipeRawList",
      "selectedLnglat",

      "controlType",
    ]),
  },

  watch: {
    pathForAcross() {
      if (this.pathForAcross) {
        const lineStartLng = this.pathForAcross[0].lng;
        const lineStartLat = this.pathForAcross[0].lat;
        const lineEndLng = this.pathForAcross[1].lng;
        const lineEndLat = this.pathForAcross[1].lat;

        const sewageList = [];
        const rainList = [];
        this.sewagePipeRawList?.forEach((item) => {
          const flag = window.AMap.GeometryUtil.doesLineLineIntersect(
            [
              [parseFloat(item[1]), parseFloat(item[2])],
              [parseFloat(item[3]), parseFloat(item[4])],
            ],
            [
              [lineStartLng, lineStartLat],
              [lineEndLng, lineEndLat],
            ]
          );
          if (flag) {
            console.log("相交", item[0]);
            sewageList.push({
              id: item[0],
              position: this.segmentsIntr(
                {
                  y: parseFloat(item[1]),
                  x: parseFloat(item[2]),
                },
                {
                  y: parseFloat(item[3]),
                  x: parseFloat(item[4]),
                },
                {
                  y: lineStartLng,
                  x: lineStartLat,
                },
                {
                  y: lineEndLng,
                  x: lineEndLat,
                }
              ),
            });
            // sewageList.push(item[0]);
          }
        });
        this.rainPipeRawList?.forEach((item) => {
          const lng1 = parseFloat(item[1]);
          const lat1 = parseFloat(item[2]);
          const lng2 = parseFloat(item[3]);
          const lat2 = parseFloat(item[4]);
          if (isNaN(lng1) || isNaN(lat1) || isNaN(lng2) || isNaN(lat2)) {
            console.log("lnglat error", item);
            return;
          }
          const flag = window.AMap.GeometryUtil.doesLineLineIntersect(
            [
              [parseFloat(item[1]), parseFloat(item[2])],
              [parseFloat(item[3]), parseFloat(item[4])],
            ],
            [
              [this.pathForAcross[0].lng, this.pathForAcross[0].lat],
              [this.pathForAcross[1].lng, this.pathForAcross[1].lat],
            ]
          );
          if (flag) {
            console.log("相交", item[0]);
            // rainList.push(item[0]);

            rainList.push({
              id: item[0],
              position: this.segmentsIntr(
                {
                  y: parseFloat(item[1]),
                  x: parseFloat(item[2]),
                },
                {
                  y: parseFloat(item[3]),
                  x: parseFloat(item[4]),
                },
                {
                  y: lineStartLng,
                  x: lineStartLat,
                },
                {
                  y: lineEndLng,
                  x: lineEndLat,
                }
              ),
            });
          }
        });

        if (sewageList.length === 0 && rainList.length === 0) {
          this.$message.error("选择的点未与管线相交");
          this.setControlType("");
          return;
        }
        this.acrossVisible = true;

        console.log("sewagelist-rainlist", sewageList, rainList);

        const task = [];

        if (sewageList.length > 0) {
          task.push(
            fetchSewagePipeDetail({
              ids: sewageList.map((item) => item.id),
            })
          );
        }
        if (rainList.length > 0) {
          task.push(
            fetchRainPipeDetail({
              ids: rainList.map((item) => item.id),
            })
          );
        }

        Promise.all(task).then((res) => {
          if (Array.isArray(res) && res.length > 0) {
            let list = [];
            if (Array.isArray(res[0])) {
              list = res[0];
            }
            if (Array.isArray(res[1])) {
              list.push(...res[1]);
            }

            const sortedCrossPointerList = [...sewageList, ...rainList].sort(
              (element1, element2) => {
                const { y: element1Y, x: element1X } = element1.position ?? {};
                const { y: element2Y, x: element2X } = element2.position ?? {};

                if (element1Y && element1X && element2Y && element2X) {
                  if (element1Y < element2Y) {
                    return -1;
                  } else if (element1Y > element2Y) {
                    return 1;
                  } else {
                    if (element1X < element2X) {
                      return -1;
                    } else if (element1X > element2X) {
                      return 1;
                    } else {
                      return 0;
                    }
                  }
                } else {
                  return 0;
                }
              }
            );

            console.log("sortedCrossPointerList", sortedCrossPointerList);

            const sortedList = sortedCrossPointerList.map((item) => {
              const obj = list.find((element) => element.id === item.id) ?? {};
              return {
                ...item,
                ...obj,
              };
            });
            console.log("sortedlist", sortedList);

            this.crossRenderList = sortedList.map((item, index) => {
              const nextItem = sortedList[index + 1];
              let distance = 0;
              if (nextItem) {
                distance = window.AMap.GeometryUtil.distance(
                  [item.position?.y, item.position?.x],
                  [nextItem.position?.y, nextItem.position?.x]
                );
              }
              return {
                ...item,
                distance: distance
                  ? parseFloat(parseFloat(distance).toFixed(2))
                  : distance,
              };
            });

            console.log("crossRenderList", this.crossRenderList);
          }
        });
      }
    },

    acrossVisible() {
      if (!this.acrossVisible) {
        this.setControlType("");
      }
    },
  },

  methods: {
    isHarmonyOS,

    segmentsIntr(a, b, c, d) {
      /** 1 解线性方程组, 求线段交点. **/
      // 如果分母为0 则平行或共线, 不相交
      var denominator = (b.y - a.y) * (d.x - c.x) - (a.x - b.x) * (c.y - d.y);
      if (denominator == 0) {
        return null;
      }

      // 线段所在直线的交点坐标 (x , y)
      var x =
        ((b.x - a.x) * (d.x - c.x) * (c.y - a.y) +
          (b.y - a.y) * (d.x - c.x) * a.x -
          (d.y - c.y) * (b.x - a.x) * c.x) /
        denominator;
      var y =
        -(
          (b.y - a.y) * (d.y - c.y) * (c.x - a.x) +
          (b.x - a.x) * (d.y - c.y) * a.y -
          (d.x - c.x) * (b.y - a.y) * c.y
        ) / denominator;

      /** 2 判断交点是否在两条线段上 **/
      if (
        // 交点在线段1上
        (x - a.x) * (x - b.x) <= 0 &&
        (y - a.y) * (y - b.y) <= 0 &&
        // 且交点也在线段2上
        (x - c.x) * (x - d.x) <= 0 &&
        (y - c.y) * (y - d.y) <= 0
      ) {
        // 返回交点p
        return {
          x: x,
          y: y,
        };
      }
      //否则不相交
      return null;
    },

    ...mapMutations("gis", ["setClickedMarkers", "setControlType"]),

    across() {
      if (this.controlType !== "across") {
        this.setControlType("across");
        this.$message.info("请点击管线两侧地图拾取坐标");
      } else {
        this.setControlType("");
      }
    },

    getCrossLeft(index) {
      if (index !== 0) {
        let distance = 0;
        for (let i = 0; i < index; i++) {
          let len = this.crossRenderList[i].distance ?? 0;
          distance += len;
        }
        return `calc(10% + ${distance * 17}px)`;
      } else {
        return "10%";
      }
    },

    vertical() {
      console.log("connectDetail", this.connectDetail);
      if (
        !this.connectDetail ||
        !this.connectDetail.info ||
        !this.connectDetail.pipes
      ) {
        this.$message.error("请先进行连通性分析");
        return;
      }
      // if (!this.connectDetail.info || !this.connectDetail.pipes) {
      //   this.$message.error("连通性分析数据缺失 info 和 pipes");
      //   return;
      // }
      const list = [];
      this.setConnectionData(list, this.connectDetail);
      if (list.length > 0) {
        console.log("connect list", list);
        this.connectList = list;
        this.verticalVisible = true;
      } else {
        this.$message.error("数据为空");
      }
    },

    setConnectionData(list, obj) {
      if (obj.info && obj.info.x) {
        // 检查井
        list.push({
          ...obj.info,
        });
      }
      if (obj.info && obj.info.xfrom) {
        // 管道
        // pointFrom 指向它的起始检查井
        const index = list.findIndex(
          (item) => item.code === obj.info.pointFrom
        );
        if (index > -1) {
          list.splice(index, 1, {
            ...list[index],
            pipe: obj.info,
          });
        }
      }
      if (obj.well && obj.well.info && obj.well.info.x) {
        // 检查井
        list.push({
          ...obj.well.info,
        });
      }

      if (Array.isArray(obj.pipes)) {
        obj.pipes.forEach((item) => {
          this.setConnectionData(list, item);
        });
      }
      if (obj.well && Array.isArray(obj.well.pipes)) {
        obj.well.pipes.forEach((item) => {
          this.setConnectionData(list, item);
        });
      }
    },

    getDistance() {
      if (this.controlType !== "distance") {
        this.setControlType("distance");
        this.$message.info("请点击管线");
      } else {
        this.setControlType("");
      }
    },

    connect() {
      if (this.controlType !== "connect") {
        this.setControlType("connect");
        this.$message.info("请点击两个检查井");
      } else {
        this.setControlType("");
      }
    },

    source() {
      if (this.controlType !== "source") {
        this.setControlType("source");
        this.$message.info("请点击管线或检查井");
      } else {
        this.setControlType("");
      }
    },

    getArea() {
      this.$emit("openArea");
    },

    onChange(e) {
      console.log("e", e);
      this.setControlType(e.target.value);
      this.figureVisible = false;
    },
  },
};
</script>

<style lang="less" scoped>
.tool-bar {
  position: fixed;
  bottom: 2vh;
  left: 50%;
  z-index: 99;
  padding: 1vh;
  transform: translateX(-50%);
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(47, 118, 181, 0.2) 100%
  );
}

.label {
  font-weight: bold;
  margin-right: 8px;
}

.button {
  width: 6em;
  cursor: pointer;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .image {
    position: relative;
    margin-bottom: 8px;
    border-radius: 6px;
    overflow: hidden;

    img {
      height: 40px;
    }

    .loading {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 24px;
    }
  }
}

.button:last-child {
  margin-right: 0;
}

.across {
  margin: 0 4em;
  // border-top: 4px solid #fff;
  border-left: 1px solid #f0f0f0;
  position: relative;

  .canvas {
    height: 220px;

    background: linear-gradient(
      90deg,
      rgba(191, 106, 52, 0) 0%,
      rgba(235, 139, 78, 0.4) 22%,
      #eea271 51%,
      rgba(235, 139, 78, 0.4) 77%,
      rgba(244, 165, 115, 0) 100%
    );

    .label {
      color: #f00;
      text-align: right;
      position: relative;
      left: -5.5em;
      width: 5em;
      font-size: 12px;
      line-height: 18px;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        left: 5.5em;
        width: 8px;
        height: 2px;
        background-color: #f00;
      }
    }
  }

  .table {
    .item {
      height: 40px;
      border-bottom: 1px solid #f0f0f0;
      position: relative;

      span {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -6em;
        width: 5em;
        text-align: right;
        font-size: 12px;
      }
    }
  }

  .line {
    position: absolute;
    bottom: 0;
    border-left: 1px dashed #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .pipe {
      // background-color: #fff;
      border-radius: 50%;
      position: absolute;
      top: 0;
      right: 0;
      border: 2px solid #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      font-size: 12px;
    }

    .distance {
      font-size: 10px;
      position: absolute;
      top: 2em;
      left: 0;
      right: 0;
      text-align: center;
      color: #fff;
    }

    .item {
      height: 40px;
    }

    .data {
      height: 1em;
      line-height: 1em;
      width: 44px;
      font-size: 12px;
      color: #fff;
      // transform: rotate(-90deg) translateY(-10px) translateX(0);
      transform: rotate(-90deg) translateY(-12px);
      border-bottom: 1px solid transparent;
      display: inline-block;
      box-sizing: content-box;
      position: relative;
      text-align: center;

      .old-data {
        position: absolute;
        top: -20px;
        left: 0;
        right: 0;
      }
    }

    .value {
      height: 48px;
      line-height: 48px;
      padding-left: 4px;
      display: inline-block;
      font-size: 10px;
      white-space: nowrap;
      color: #999;
      border-bottom: 1px solid transparent;
      box-sizing: content-box;
      text-align: center;
    }
  }
}

.icons {
  position: absolute;
  top: 1em;
  right: 1em;
  cursor: pointer;
}
</style>