<template>
  <div>
    <div id="map"></div>

    <ControlBar :rotation="rotation" @rotate="rotate" />

    <div class="zoom-tip">当前层级: {{ currentZoom }}</div>
    <Selector :selected="selected" @change="onChange" />
    <ToolBar :pathForAcross="pathForAcross" @openArea="areaVisible = true" />

    <AreaWindow
      v-if="areaVisible"
      @cancel="cancel"
      @clear="clear"
      @query="query"
      :overlays="overlays"
      :mouseTool="mouseTool"
    />

    <!-- <div class="amap-ctrl-list-layer amap-ctrl-list-layer2">
      <ul class="amap-ctrl-base-layer">
        <li class="amap-ui-ctrl-layer-base-item">
          <input type="checkbox" v-model="isShowWellId" />
          <p>检查井编号</p>
        </li>
      </ul>
    </div> -->

    <PipeDetail
      :detail="pipeDetail"
      v-if="pipeVisible"
      @changeVisible="pipeVisible = false"
    />
    <WellDetail
      :detail="wellDetail"
      v-if="wellVisible"
      @changeVisible="wellVisible = false"
    />

    <RainPipeDetail
      :detail="rainpipeDetail"
      v-if="rainpipeVisible"
      @changeVisible="rainpipeVisible = false"
    />
    <RainWellDetail
      :detail="rainwellDetail"
      v-if="rainwellVisible"
      @changeVisible="rainwellVisible = false"
    />
    <RainDrainDetail
      :detail="raindrainDetail"
      v-if="raindrainVisible"
      @changeVisible="raindrainVisible = false"
    />

    <area-facility-modal
      :visible="areaFacilityListVisible"
      :idList="areaFacilityIdList"
      @close="areaFacilityListVisible = false"
    />

    <LiquidDetail
      v-if="liquidVisible"
      :detail="liquidDetail"
      @changeVisible="liquidVisible = false"
    />
    <FlowmeterDetail
      v-if="flowVisible"
      :detail="flowDetail"
      @changeVisible="flowVisible = false"
    />
    <WaterQualityDetail
      v-if="waterQualityVisible"
      :detail="waterQualityDetail"
      @changeVisible="waterQualityVisible = false"
    />

    <MonitorDetail
      v-if="monitorVisible"
      :detail="monitorDetail"
      @changeVisible="monitorVisible = false"
    />

    <RainfallDetail
      v-if="rainfallVisible"
      :detail="rainfallDetail"
      @changeVisible="rainfallVisible = false"
    />

    <intercepting-well-detail
      :detail="cutoDetail"
      v-if="cutoVisible"
      @changeVisible="(payload) => (cutoVisible = payload)"
    />
  </div>
</template>
<script>
import area from "@/mixins/area";

import Selector from "./components/selector.vue";
import ToolBar from "./components/tool-bar.vue";

import sewageScope from "./mixins/sewage-scope";

import sewagePipe from "./mixins/sewage-pipe";
import sewageWell from "./mixins/sewage-well";

import facility from "./mixins/facility";

import PipeDetail from "./components/pipe-detail.vue";
import WellDetail from "@/components/check-well/index.vue";

import rainPipe from "./mixins/rain-pipe";
import rainWell from "./mixins/rain-well";
import rainDrain from "./mixins/rain-drain";

import RainPipeDetail from "./components/rain-pipe-detail";
import RainWellDetail from "@/components/check-well/rain.vue";
import RainDrainDetail from "./components/rain-drain-detail";

import { Scene } from "@antv/l7";
import { GaodeMapV2 } from "@antv/l7-maps";

import { debounce } from "lodash";

import sourceMixin from "./mixins/source";
import connectMixin from "./mixins/connect";
import AreaFacilityModal from "./components/area-facility-modal.vue";

import { mapActions } from "vuex";

import AreaWindow from "./components/area-window.vue";

import ControlBar from "@/components/control-bar/index.vue";

// import { getCenterPoint, getAngle } from "@/utils/map";

export default {
  components: {
    Selector,
    ToolBar,

    PipeDetail,
    WellDetail,

    RainPipeDetail,
    RainWellDetail,
    RainDrainDetail,

    AreaFacilityModal,

    AreaWindow,

    ControlBar,
  },

  mixins: [
    area,

    sewagePipe,
    sewageWell,

    sewageScope,

    rainPipe,
    rainWell,
    rainDrain,

    sourceMixin,
    connectMixin,
    facility,
  ],

  data() {
    return {
      rotation: 0,
      selected: [],

      pathForAcross: null, // 横断面分析需要的两个点

      areaFacilityIdList: [],
      areaFacilityListVisible: false,

      areaVisible: false,
      overlays: [],
      mouseTool: null,

      currentZoom: 14, // 当前地图的zoom
    };
  },

  watch: {
    selected: {
      handler() {
        if (this.selected.indexOf("污水管网") > -1) {
          this.getPipe();
        } else {
          this.removePipe();
        }

        if (this.selected.indexOf("污水检查井") > -1) {
          this.getWell();
        } else {
          this.removeWell();
        }

        if (this.selected.indexOf("污水收集区") > -1) {
          this.getsewageScope();
        } else {
          this.removesewageScope();
        }

        if (this.selected.indexOf("雨水管网") > -1) {
          this.getrainpipe();
        } else {
          this.removerainpipe();
        }

        if (this.selected.indexOf("雨水排口") > -1) {
          this.getraindrain();
        } else {
          this.removeraindrain();
        }

        if (this.selected.indexOf("雨水检查井") > -1) {
          this.getrainwell();
        } else {
          this.removerainwell();
        }

        if (this.selected.indexOf("截流设施") > -1) {
          this.getCuto();
        } else {
          this.removeCuto();
        }

        if (this.selected.indexOf("液位计") > -1) {
          this.getLiquid();
        } else {
          this.removeLiquid();
        }

        if (this.selected.indexOf("流量计") > -1) {
          this.getFlow();
        } else {
          this.removeFlow();
        }

        if (this.selected.indexOf("雨量计") > -1) {
          this.getRainfall();
        } else {
          this.removeRainfall();
        }

        if (this.selected.indexOf("水质监测站") > -1) {
          this.getWaterQuality();
        } else {
          this.removeWaterQuality();
        }

        if (this.selected.indexOf("视频监控") > -1) {
          this.getMonitor();
        } else {
          this.removeMonitor();
        }
      },
      deep: true,
    },

    selectedWellType() {
      this.wellList?.forEach((item) => {
        if (this.selectedWellType.find((type) => type === item.type)) {
          item.layer.show();
        } else {
          item.layer.hide();
        }
      });
    },

    selectedrainwellType() {
      this.rainwellList?.forEach((item) => {
        if (this.selectedrainwellType.find((type) => type === item.type)) {
          item.layer.show();
        } else {
          item.layer.hide();
        }
      });
    },
  },
  mounted() {
    this.debounceZoomChange = debounce(this.onZoomChange, 150);
    this.debounceMoveend = debounce(this.onMoveEnd, 150);

    const map = this.initMap();

    this.scene = new Scene({
      id: "map",
      logoVisible: false,
      map: new GaodeMapV2({
        mapInstance: map,
      }),
    });

    this.scene.on("loaded", () => {
      this.getFacilityList();
    });

    this.scene?.addImage("sewageWell", "/icon/sewage-well-active.png");
    this.scene?.addImage("rainWell", "/icon/rain-well-active.png");

    this.scene?.addImage("raindrain", "/icon/rain-outlet-active.png");

    this.scene?.addImage("cutoffwell", "/icon/intercepting-well-active.png");
    this.scene?.addImage("video", "/icon/monitor-active.png");
    this.scene?.addImage("liquidLevel", "/icon/liquid-active.png");
    this.scene?.addImage("flowMeter", "/icon/flow-active.png");
    this.scene?.addImage("rainFall", "/icon/rain-active.png");
    this.scene?.addImage("water-quality", "/icon/water-quality-active.png");

    this.scene?.addImage("selected", "/icon/selected.png");

    this.selected = [
      // "污水收集区",
      "污水管网",
      "污水检查井",

      "雨水管网",
      "雨水排口",
      "雨水检查井",

      "截流设施",
      "流量计",
      "液位计",
      "雨量计",
      "水质监测站",
      "视频监控",
    ];
  },

  beforeDestroy() {
    if (this.wellList?.length > 0) {
      this.wellList.forEach((item) => {
        item.layer.destroy();
        item.markers.forEach((marker) => {
          marker.off("click", this.onWellClick);
          marker.remove();
        });
      });
    }

    if (this.drainList?.length > 0) {
      this.drainList.forEach((item) => {
        item.off("click", this.onDrainClick);
        item.remove();
      });
      this.drainLabelsLayer?.destroy();
    }

    this.pipeLayer?.destroy();
    this.pipeLayer2?.destroy();
    this.activePipeLayer?.destroy();

    this.rainpipeLayer?.destroy();
    this.rainpipeLayer2?.destroy();
    this.activerainpipeLayer?.destroy();

    // delete collect marker
    if (this.collectList?.length > 0) {
      this.collectList.forEach((item) => {
        item.off("click", this.onCollectMarkerClick);
        item.destroy();
      });
    }

    // delete collect info window
    if (this.collectInfoWindow) {
      const tag = document.getElementById("collect-info-window-tag");
      const extra = document.getElementById("collect-info-window-extra");
      if (tag && extra) {
        console.log("bind event");
        tag.removeEventListener("click", this.openCollectDetail);
        extra.removeEventListener("click", this.closeCollectWindow);
      }
    }

    if (this.pumpInfoWindow) {
      const tag = document.getElementById("pump-info-window-tag");
      const video = document.getElementById("pump-info-window-video");
      const extra = document.getElementById("pump-info-window-extra");
      if (tag && extra) {
        console.log("bind event");
        tag.removeEventListener("click", this.openPumpDetail);
        video.removeEventListener("click", this.openPumpVideo);
        extra.removeEventListener("click", this.closePumpWindow);
      }
    }

    this.map.off("click", this.onMapClick);
    this.map?.off("zoomend", this.debounceZoomChange);
    this.map?.off("moveend", this.debounceMoveend);

    this.map?.off("rotatechange", this.rotatechange);

    this.map?.clearMap();
    this.map?.destroy();
    this.scene?.destroy();
  },

  methods: {
    ...mapActions("home", ["getFacilityList"]),

    initMap() {
      const { AMap } = window;
      if (!AMap) {
        return;
      }
      var map = new AMap.Map("map", {
        // showLabel: false,
        viewMode: "3D",
        terrain: true, // 开启地形图
        center: [119.056708, 33.583976],
        zoom: 14,

        mapStyle: "amap://styles/52faa27d18984923cceae498330eceb1",
        WebGLParams: {
          preserveDrawingBuffer: true,
        },
      });
      const that = this;

      // const controlBar = new AMap.ControlBar({

      // });

      // map.addControl(controlBar);

      map.on("rotatechange", this.rotatechange);

      AMap.plugin(
        [
          // "AMap.Scale",
          // "AMap.MapType",
          "AMap.PolygonEditor",
          "AMap.MouseTool",
        ],
        function () {
          // map.addControl(new AMap.HawkEye({ isOpen: true }));
          // map.addControl(new AMap.MapType());

          that.mouseTool = new AMap.MouseTool(map);

          that.overlays = [];
          that.mouseTool.on("draw", function (e) {
            that.overlays.push(e.obj);
          });
        }
      );

      this.map = map;

      map.on("click", this.onMapClick);
      map.on("zoomend", this.debounceZoomChange);
      map.on("moveend", this.debounceMoveend);

      return map;
    },

    rotatechange() {
      this.rotation = this.map.getRotation();
    },
    rotate(rotation) {
      if (this.map) {
        this.map.setRotation(rotation);
      }
    },

    onMoveEnd() {
      // if (this.currentZoom < 16.7) return;
      // const center = this.map?.getCenter();
      // let path = [
      //   [center.lng + 0.01, center.lat + 0.01],
      //   [center.lng + 0.01, center.lat - 0.01],
      //   [center.lng - 0.01, center.lat + 0.01],
      //   [center.lng - 0.01, center.lat - 0.01],
      // ];
      // const { AMap } = window;
      // this.clearPipeText();
      // this.sewagePipeTextList = [];
      // if (this.selected.indexOf("污水管网") > -1) {
      //   for (let i = 0; i < this.sewagePipeRawList.length; i++) {
      //     const item = this.sewagePipeRawList[i];
      //     if (!item[5]) {
      //       continue;
      //     }
      //     // if (!this.isShowMainPipeText) {
      //     //   if (parseInt(item[5]) >= 400) {
      //     //     return;
      //     //   }
      //     // }
      //     // if (!this.isShowBranchPipeText) {
      //     //   if (parseInt(item[5]) < 400) {
      //     //     return;
      //     //   }
      //     // }
      //     const flag =
      //       AMap.GeometryUtil.isPointInRing(
      //         [parseFloat(item[1]), parseFloat(item[2])],
      //         path
      //       ) ||
      //       AMap.GeometryUtil.isPointInRing(
      //         [parseFloat(item[3]), parseFloat(item[4])],
      //         path
      //       );
      //     if (!flag) {
      //       continue;
      //     }
      //     const point = getCenterPoint(
      //       item[1] + "," + item[2],
      //       item[3] + "," + item[4]
      //     );
      //     if (!point.lng) {
      //       continue;
      //     }
      //     const angleValue = getAngle(
      //       parseFloat(item[1]),
      //       parseFloat(item[2]),
      //       parseFloat(item[3]),
      //       parseFloat(item[4])
      //     );
      //     const text = new AMap.Text({
      //       text: item[6] + "/DN" + item[5],
      //       zIndex: 500,
      //       zooms: [18, 20],
      //       anchor: "center", // 设置文本标记锚点
      //       angle: angleValue,
      //       offset: [16, 16],
      //       style: {
      //         "text-align": "center",
      //         "font-size": "8px",
      //         color: "#FFA500",
      //         background: "transparent",
      //         "border-width": 0,
      //       },
      //       position: [point.lng, point.lat],
      //     });
      //     this.sewagePipeTextList.push(text);
      //   }
      // }
      // if (this.sewagePipeTextList.length > 0) {
      //   this.map.add(this.sewagePipeTextList);
      // }
      // this.rainPipeTextList = [];
      // if (this.selected.indexOf("雨水管网") > -1) {
      //   for (let i = 0; i < this.rainPipeRawList.length; i++) {
      //     const item = this.rainPipeRawList[i];
      //     const flag =
      //       AMap.GeometryUtil.isPointInRing(
      //         [parseFloat(item[1]), parseFloat(item[2])],
      //         path
      //       ) ||
      //       AMap.GeometryUtil.isPointInRing(
      //         [parseFloat(item[3]), parseFloat(item[4])],
      //         path
      //       );
      //     if (!flag) {
      //       continue;
      //     }
      //     const point = getCenterPoint(
      //       item[1] + "," + item[2],
      //       item[3] + "," + item[4]
      //     );
      //     if (!point.lng) {
      //       continue;
      //     }
      //     const angleValue = getAngle(
      //       parseFloat(item[1]),
      //       parseFloat(item[2]),
      //       parseFloat(item[3]),
      //       parseFloat(item[4])
      //     );
      //     const text = new AMap.Text({
      //       text: item[6] + "/DN" + item[5],
      //       zIndex: 500,
      //       zooms: [18, 20],
      //       anchor: "center", // 设置文本标记锚点
      //       angle: angleValue,
      //       offset: [16, 16],
      //       style: {
      //         "text-align": "center",
      //         "font-size": "8px",
      //         color: "#01C8E3",
      //         background: "transparent",
      //         "border-width": 0,
      //       },
      //       position: [point.lng, point.lat],
      //     });
      //     this.rainPipeTextList.push(text);
      //   }
      // }
      // if (this.rainPipeTextList.length > 0) {
      //   this.map.add(this.rainPipeTextList);
      // }
    },

    // clearPipeText(type) {
    //   if (type === "sewage") {
    //     if (this.sewagePipeTextList && this.sewagePipeTextList.length > 0) {
    //       this.map.remove(this.sewagePipeTextList);
    //     }
    //   } else if (type === "rain") {
    //     if (this.rainPipeTextList && this.rainPipeTextList.length > 0) {
    //       this.map.remove(this.rainPipeTextList);
    //     }
    //   } else {
    //     if (this.sewagePipeTextList && this.sewagePipeTextList.length > 0) {
    //       this.map.remove(this.sewagePipeTextList);
    //     }
    //     if (this.rainPipeTextList && this.rainPipeTextList.length > 0) {
    //       this.map.remove(this.rainPipeTextList);
    //     }
    //   }
    // },

    onZoomChange() {
      this.changeSewageScopeSize();

      const zoom = this.map?.getZoom() || 3;
      this.currentZoom = zoom;

      // if (zoom >= 16.7) {
      //   console.log("设置管线文本标注");
      //   this.onMoveEnd();
      // } else {
      //   this.clearPipeText();
      // }

      this.sewageWellLayer?.size(zoom * 0.55);
      this.rainWellLayer?.size(zoom * 0.55);
      console.log("check zoom");

      if (zoom >= 16.7) {
        this.getWell();
        this.getrainwell();
      } else {
        this.removeWell();
        this.removerainwell();
      }

      this.cutoLayer?.size(zoom * 0.55);
      this.liquidLayer?.size(zoom * 0.55);
      this.flowLayer?.size(zoom * 0.55);
      this.rainfallLayer?.size(zoom * 0.55);
      this.waterQualityLayer?.size(zoom * 0.55);
      this.monitorLayer?.size(zoom * 0.55);
      this.facilityActiveLayer?.size(zoom * 0.6);

      this.raindrainLayer?.size(zoom * 0.55);

      // 2 调整地图大小
      if (zoom >= 20) {
        // 把这个值从20改成19了
        this.pipeLayer?.size(3);
        this.pipeLayer2?.size(3);
        this.rainpipeLayer?.size(3);
        this.rainpipeLayer2?.size(3);

        this.map.setPitch(20, true);
      } else {
        this.pipeLayer?.size(1);
        this.pipeLayer2?.size(1);
        this.rainpipeLayer?.size(1);
        this.rainpipeLayer2?.size(1);

        this.map.setPitch(0, true);
      }
    },

    onMapClick(e) {
      const { AMap } = window;
      if (this.controlType === "across") {
        // if (this.selectedLnglat.length === 2) {
        //   this.$message.error("请点击横断面按钮开始分析");
        //   return;
        // }
        const lng = e.lnglat.getLng();
        const lat = e.lnglat.getLat();

        console.log("lnglat", lng, lat);

        if (!this.markerForAcross1) {
          this.markerForAcross1 = new AMap.Marker({
            position: new AMap.LngLat(lng, lat),
          });

          this.map.add(this.markerForAcross1);
          this.$message.info("请点击管线另一侧");
          return;
        }
        if (!this.markerForAcross2) {
          this.markerForAcross2 = new AMap.Marker({
            position: new AMap.LngLat(lng, lat),
          });

          this.map.add(this.markerForAcross2);

          console.log("getPosition1", this.markerForAcross1.getPosition());
          console.log("getPosition2", this.markerForAcross2.getPosition());

          this.pathForAcross = [
            this.markerForAcross1.getPosition(),
            this.markerForAcross2.getPosition(),
          ];

          this.polylineForAcross = new AMap.Polyline({
            path: this.pathForAcross,
            borderWeight: 2, // 线条宽度，默认为 1
            strokeColor: "red", // 线条颜色
            lineJoin: "round", // 折线拐点连接处样式
          });

          // 将折线添加至地图实例
          this.map.add(this.polylineForAcross);
        }

        // const arr = [...this.selectedLnglat, marker];
        // this.setSelectedLnglat(arr);

        // if (arr.length === 2) {
        //   console.log("getPosition", arr);

        //   const path = arr.map((item) => {
        //     return item.getPosition();
        //   });

        //   var polyline = new AMap.Polyline({
        //     path,
        //     borderWeight: 2, // 线条宽度，默认为 1
        //     strokeColor: "red", // 线条颜色
        //     lineJoin: "round", // 折线拐点连接处样式
        //   });

        //   // 将折线添加至地图实例
        //   this.map.add(polyline);
        //   this.setSelectedLnglatLine(polyline);
        // }
      }
    },

    onChange(payload) {
      this.selected = payload;
    },

    cancel() {
      this.areaVisible = false;
      this.mouseTool.close(true);
      this.map?.remove(this.overlays);
      this.overlays = [];
    },
    clear() {
      // this.mouseTool.close(true);
      this.map?.remove(this.overlays);
      this.overlays = [];
    },
    query(list) {
      const AMap = window.AMap;
      const idList = [];

      console.log("list", list);

      list.forEach((position) => {
        console.log("position", position);
        if (this.sewagePipeRawList?.length > 0) {
          this.sewagePipeRawList.forEach((item) => {
            const startPosition = [parseFloat(item[1]), parseFloat(item[2])];
            const endPosition = [parseFloat(item[3]), parseFloat(item[4])];

            if (
              AMap.GeometryUtil.isPointInRing(startPosition, position) &&
              AMap.GeometryUtil.isPointInRing(endPosition, position)
            ) {
              idList.push({
                id: item[0],
                type: "sewage",
              });
            }
          });
        }
        if (this.rainPipeRawList?.length > 0) {
          this.rainPipeRawList.forEach((item) => {
            const startLng = parseFloat(item[1]);
            const startLat = parseFloat(item[2]);
            const endLng = parseFloat(item[3]);
            const endLat = parseFloat(item[4]);
            if (isNaN(startLng)) return;
            if (isNaN(startLat)) return;
            if (isNaN(endLng)) return;
            if (isNaN(endLat)) return;

            const startPosition = [parseFloat(item[1]), parseFloat(item[2])];
            const endPosition = [parseFloat(item[3]), parseFloat(item[4])];

            if (
              AMap.GeometryUtil.isPointInRing(startPosition, position) &&
              AMap.GeometryUtil.isPointInRing(endPosition, position)
            ) {
              idList.push({
                id: item[0],
                type: "rain",
              });
            }
          });
        }

        if (this.sewageWellRawList?.length > 0) {
          this.sewageWellRawList.forEach((item) => {
            const lng = parseFloat(item[3]);
            const lat = parseFloat(item[4]);
            if (isNaN(lng)) return;
            if (isNaN(lat)) return;

            const location = [lng, lat];

            if (AMap.GeometryUtil.isPointInRing(location, position)) {
              idList.push({
                id: item[0],
                type: "sewage-well",
              });
            }
          });
        }
        if (this.rainWellRawList?.length > 0) {
          if (this.rainWellRawList?.length > 0) {
            this.rainWellRawList.forEach((item) => {
              const lng = parseFloat(item[3]);
              const lat = parseFloat(item[4]);
              if (isNaN(lng)) return;
              if (isNaN(lat)) return;

              const location = [lng, lat];

              if (AMap.GeometryUtil.isPointInRing(location, position)) {
                idList.push({
                  id: item[0],
                  type: "rain-well",
                });
              }
            });
          }
        }

        if (this.facilityList?.length > 0) {
          this.facilityList.forEach((item) => {
            const location = [item.x, item.y];

            if (AMap.GeometryUtil.isPointInRing(location, position)) {
              idList.push({
                id: item.id,
                type: "facility",
              });
            }
          });
        }
      });

      if (idList.length > 0) {
        this.areaFacilityIdList = Object.freeze(Array.from(new Set(idList)));
        this.areaFacilityListVisible = true;
      } else {
        this.$message.error("选择范围内没有设施");
      }
    },
  },
};
</script>
<style lang="less" scoped>
#map {
  position: fixed;
  top: -50px;
  left: 0;
  right: 0;
  bottom: -20px;
  z-index: 10;
}

.zoom-tip {
  position: fixed;
  bottom: 40px;
  left: 40px;
  z-index: 100;
  color: grey;
}
</style>