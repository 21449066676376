import request from '../request'

// 获取污水管网列表
export function fetchSewagePipe(data) {
    return request({
        url: '/model-analysis/pipe/sewage/locations',
        method: 'post',
        data
    })
}

// 获取污水管网详情
export function fetchSewagePipeDetail(data) {
    return request({
        url: '/model-analysis/pipe/sewage/query',
        method: 'post',
        data
    })
}



// 获取污水收集区列表
export function fetchSewageScope(data) {
    return request({
        url: '/model-analysis/pipe/sewage/area/locations',
        method: 'post',
        data
    })
}

export function fetchWell(data) {
    return request({
        url: '/model-analysis/pipe/sewage/well/locations',
        method: 'post',
        data: {
            ...data,
            district: 'huangshan'
        }
    })
}

export function fetchWellDetail(data) {
    return request({
        url: '/model-analysis/pipe/sewage/well/query',
        method: 'post',
        data
    })
}

// 获取每条河的经纬度
export function fetchRiverDetail(data) {
    return request({
        url: '/model-analysis/pipe/area/locations',
        method: 'post',
        data
    })
}

// 获取所有的河
export function fetchRiverList(data) {
    return request({
        url: '/model-analysis/pipe/area',
        method: 'post',
        data
    })
}

// 获取所有的河
export function fetchWaterFacilityList(data) {
    return request({
        url: '/model-analysis/pipe/area/devices',
        method: 'post',
        data
    })
}

// 获取所有的设备，包括液位计等
export function fetchFacility(data) {
    return request({
        url: '/model-analysis/pipe/sewage/device/query',
        method: 'post',
        data
    })
}