<template>
  <div>
    <div class="detail">
      <div class="item">
        <div>
          <div>
            <a-icon class="icon" type="line-chart" />
          </div>
          <div>
            <div class="label">管线总数量</div>
            <span class="value">{{ detail.totalNum }}</span>
          </div>
        </div>
        <div>
          <div>
            <a-icon class="icon" type="bar-chart" />
          </div>
          <div>
            <div class="label">管线总长度</div>
            <span class="value">
              {{ detail.totalLength }}
            </span>
          </div>
        </div>
      </div>

      <div>
        <Pie id="diameterMap" :info="detail.diameterMap" />
      </div>
      <div>
        <Pie id="materialMap" :info="detail.materialMap" />
      </div>
      <div>
        <Pie id="categoryMap" :info="detail.categoryMap" />
      </div>
    </div>

    <a-table
      bordered
      :data-source="list"
      :loading="loading"
      @change="onChange"
      :pagination="{
        total: idList.length,
        current,
        pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }"
    >
      <a-table-column title="编码" data-index="code" />
      <a-table-column title="分类" align="center">
        <template slot-scope="text">
          <span v-if="text.category === 'sewage'" style="color: #ffa500"
            >污水管网</span
          >
          <span v-if="text.category === 'rain'" style="color: #01c8e3"
            >雨水管网</span
          >
        </template>
      </a-table-column>
      <a-table-column title="管径(mm)" data-index="diameter" align="right" />
      <a-table-column title="管长(m)" data-index="length" align="right" />
      <a-table-column title="材质" data-index="material" align="center" />
    </a-table>
  </div>
</template>

<script>
import { fetchPipeDetail } from "../api/sewage";
import { fetchPipeDetail as fetchRainPipeDetail } from "../api/rain";
import request from "@/api/request";
import Pie from "./pie.vue";
export default {
  components: {
    Pie,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    idList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      current: 1,
      pageSize: 10,
      loading: false,
      list: [],

      detail: {},
    };
  },
  computed: {
    filteredIdList() {
      return this.idList.filter(
        (item, index) =>
          index < this.current * this.pageSize &&
          index >= (this.current - 1) * this.pageSize
      );
    },
  },

  watch: {
    idList() {
      this.getData();
    },
    visible() {
      if (this.visible) {
        if (this.current !== 1) {
          this.current = 1;
        } else {
          this.getList();
        }
      }
    },

    filteredIdList() {
      this.getList();
    },
    list() {
      console.log("list changed", this.list);
    },
  },

  mounted() {
    this.getData();
    if (this.current !== 1) {
      this.current = 1;
    } else {
      this.getList();
    }
  },

  methods: {
    getData() {
      if (this.idList.length === 0) {
        return;
      }
      request({
        url: "/model-analysis/pipe/area/stat",
        method: "post",
        data: {
          rainIds: this.idList
            .filter((item) => item.type === "rain")
            .map((item) => item.id),
          sewageIds: this.idList
            .filter((item) => item.type === "sewage")
            .map((item) => item.id),
          type: "pipe",
        },
      }).then((res) => {
        console.log("stat", res);
        if (res && res.pipeModel) {
          this.detail = res.pipeModel;
        }
      });
    },

    getList() {
      let sewagePipeList = [];
      let rainPipeList = [];

      this.filteredIdList.forEach((element) => {
        if (element.type === "sewage") {
          sewagePipeList.push(element.id);
        }
        if (element.type === "rain") {
          rainPipeList.push(element.id);
        }
      });
      console.log("sewagePipeList, rainPipeList", sewagePipeList, rainPipeList);

      const tasks = [];
      if (sewagePipeList.length > 0) {
        tasks.push(
          fetchPipeDetail({
            ids: sewagePipeList,
          })
        );
      }
      if (rainPipeList.length > 0) {
        tasks.push(
          fetchRainPipeDetail({
            ids: rainPipeList,
          })
        );
      }

      this.loading = true;
      Promise.all(tasks)
        .then((res) => {
          const list = [];
          if (res[0] && Array.isArray(res[0])) {
            list.push(...res[0]);
          }
          if (res[1] && Array.isArray(res[1])) {
            list.push(...res[1]);
          }
          this.list = Object.freeze(list);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
    },
  },
};
</script>


<style lang="less" scoped>
.detail {
  display: flex;
  align-items: center;
  gap: 2em;
  margin-bottom: 2em;
  & > div {
    flex: 1;
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 1em;
    & > div {
      display: flex;
      align-items: center;
    }
    .icon {
      font-size: 2.5em;
      margin-right: 12px;
      color: var(--theme-color);
    }
    .label {
      font-size: 0.9em;
      color: #999;
    }
    .value {
      font-size: 1.4em;
      margin-top: 0.4em;
      font-weight: bold;
      color: var(--theme-color);
    }
  }
}
</style>