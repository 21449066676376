<template>
  <div class="selector">
    <div class="header">
      <img class="bg" src="@/assets/card-header-bg.png" alt="" />
      <div class="text">
        <div class="title">显示所有图层</div>
        <div class="extra">
          <a-checkbox :checked="allSelected" @change="onAllSelect" />
        </div>
      </div>
    </div>
    <div class="section" v-for="(section, index) in list" :key="section.title">
      <div class="header">
        <div class="title">{{ section.title }}</div>
        <div style="display: flex">
          <a-checkbox
            :checked="partSelected(section.children)"
            @change="(e) => onPartSelect(section.children, e.target.checked)"
          />
          <div style="margin-left: 0.2rem" @click="toggle(section, index)">
            <a-icon type="caret-down" v-if="section.fold" class="triangle" />
            <a-icon type="caret-up" v-else class="triangle" />
          </div>
        </div>
      </div>
      <div v-show="!section.fold">
        <div v-for="element in section.children" :key="element.name">
          <a-checkbox
            :checked="selected.indexOf(element.name) > -1"
            class="item"
            @change="(e) => onChange(element.name, e.target.checked)"
          >
            <div
              class="inner active"
              v-if="selected.indexOf(element.name) > -1"
            >
              <img :src="element.selectedIcon" alt />
              <div>{{ element.name }}</div>
            </div>
            <div class="inner" v-else>
              <img :src="element.icon" alt />
              <div>{{ element.name }}</div>
            </div>
          </a-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      list: [
        {
          title: "污水图层",
          fold: false,
          children: [
            {
              icon: "/icon/sewage-scope.png",
              selectedIcon: "/icon/sewage-scope-active.png",
              name: "污水收集区",
            },
            {
              icon: "/icon/sewage-pipe.png",
              selectedIcon: "/icon/sewage-pipe-active.png",
              name: "污水管网",
            },

            {
              icon: "/icon/sewage-well.png",
              selectedIcon: "/icon/sewage-well-active.png",
              name: "污水检查井",
            },
          ],
        },
        {
          title: "雨水图层",
          fold: false,
          children: [
            {
              icon: "/icon/rain-pipe.png",
              selectedIcon: "/icon/rain-pipe-active.png",
              name: "雨水管网",
            },
            {
              icon: "/icon/rain-outlet.png",
              selectedIcon: "/icon/rain-outlet-active.png",
              name: "雨水排口",
            },
            {
              icon: "/icon/rain-well.png",
              selectedIcon: "/icon/rain-well-active.png",
              name: "雨水检查井",
            },
          ],
        },
        {
          title: "其他图层",
          fold: false,
          children: [
            {
              icon: "/icon/intercepting-well.png",
              selectedIcon: "/icon/intercepting-well-active.png",
              name: "截流设施",
            },
            {
              icon: "/icon/liquid.png",
              selectedIcon: "/icon/liquid-active.png",
              name: "液位计",
            },
            {
              icon: "/icon/flow.png",
              selectedIcon: "/icon/flow-active.png",
              name: "流量计",
            },

            {
              icon: "/icon/rain.png",
              selectedIcon: "/icon/rain-active.png",
              name: "雨量计",
            },
            {
              icon: "/icon/water-quality.png",
              selectedIcon: "/icon/water-quality-active.png",
              name: "水质监测站",
            },
            {
              icon: "/icon/monitor.png",
              selectedIcon: "/icon/monitor-active.png",
              name: "视频监控",
            },
          ],
        },
      ],
    };
  },

  computed: {
    allSelected() {
      let length = 0;
      this.list.forEach((item) => {
        length += item.children.length;
      });

      return this.selected.length === length;
    },
  },

  methods: {
    onChange(name, value) {
      if (value) {
        this.selected.push(name);
      } else {
        const index = this.selected.indexOf(name);
        this.selected.splice(index, 1);
      }
      this.$emit(
        "change",
        this.selected.filter(
          (item, index, self) => self.indexOf(item) === index
        )
      );
    },

    onAllSelect(e) {
      const payload = [];

      if (e.target.checked) {
        this.list.forEach((item) => {
          item.children.forEach((element) => {
            payload.push(element.name);
          });
        });
      }

      this.$emit("change", payload);
    },

    partSelected(children) {
      let flag = true;
      children.forEach((element) => {
        if (this.selected.indexOf(element.name) === -1) {
          flag = false;
        }
      });
      return flag;
    },

    onPartSelect(children, value) {
      let arr = [...this.selected];
      if (value) {
        arr.push(...children.map((item) => item.name));
      } else {
        arr = arr.filter((item) => {
          return !children.find((element) => element.name === item);
        });
      }
      this.$emit(
        "change",
        arr.filter((item, index, self) => self.indexOf(item) === index)
      );
    },

    toggle(item, index) {
      this.list.splice(index, 1, {
        ...item,
        fold: !item.fold,
      });
    },
  },
};
</script>


<style lang="less" scoped>
.selector {
  position: fixed;
  top: 12vh;
  left: 4vw;
  z-index: 99;

  background: linear-gradient(
    180deg,
    rgba(55, 164, 231, 0) 0%,
    rgba(87, 161, 227, 0.8) 100%
  );

  width: 15vw;
  padding-bottom: 1.5vh;

  & > .header {
    color: #fff;
    font-weight: bold;
    position: relative;

    .bg {
      width: 100%;
      display: block;
    }
    .text {
      position: absolute;
      top: 20%;
      left: 0;
      bottom: 0;
      right: 1vw;
      display: flex;
      justify-content: space-between;
    }
  }

  .section {
    padding: 1.8vh 1vw 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);

    &:last-child {
      border-bottom-width: 0;
    }

    & > .header {
      font-weight: bold;
      margin-bottom: 1.2vh;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;

      .triangle {
        font-size: 0.8vw;
        color: var(--theme-color);
      }
    }

    .title {
      color: #fff;
    }

    .item {
      display: flex;
      align-items: center;
      margin-bottom: 1.5vh;

      .inner {
        display: flex;
        align-items: center;
        color: rgba(255, 255, 255, 0.7);
      }

      .active {
        color: #fff;
      }

      img {
        width: 1vw;
        margin-right: 0.4vw;
      }
    }
  }
}
</style>