import { fetchSewageScope } from "../api/sewage";

export default {

    data() {
        return {
            sewageScopeList: [],
            sewageScopeDetail: {},
            sewageScopeVisible: false,

            sewageScopeTextList: [], // 污水处理区的中心文本列表
        }
    },

    methods: {
        // 污水收集区
        getsewageScope() {

            if (this.sewageScopeList.length === 0) {
                fetchSewageScope({
                    category: 'sewage',
                }).then((res) => {
                    console.log('sewage scope res', res)
                    if (res && Array.isArray(res)) {
                        console.log('area res', res)
                        this.addsewageScope({ list: Object.freeze(res) });
                    }
                });
            } else {
                // 已经有值，直接显示出来即可
                // this.sewageScopeLabelsLayer?.show();
                this.sewageScopeList.forEach(item => {
                    item.show();
                })
                this.sewageScopeTextList.forEach(item => {
                    item.show();
                })
            }
        },

        addsewageScope({ list = [] }) {
            if (!this.map) {
                return;
            }
            const { AMap } = window;

            const arr = []

            const textList = []

            list.forEach(item => {

                if (Array.isArray(item.locs)) {

                    var path = item.locs.map(item => {
                        return new AMap.LngLat(parseFloat(item[0]), parseFloat(item[1]))
                    })

                    var polygon = new AMap.Polygon({
                        path,

                        fillColor: '#000', // 多边形填充颜色
                        fillOpacity: 0.8,
                        strokeStyle: 'dashed',
                        strokeDasharray: [20, 10],
                        borderWeight: 1, // 线条宽度，默认为 1
                        strokeColor: '#FF5500', // 线条颜色
                        strokeWeight: 8,
                    });

                    arr.push(polygon)

                    var text = new AMap.Text({
                        text: item.name,
                        position: [item.x, item.y],
                    });
                    const zoom = this.map?.getZoom() || 14;
                    text.setStyle({
                        'font-size': zoom * 0.6 + 'px',
                    })
                    textList.push(text)

                }
            })

            this.map?.add(arr);
            this.map?.add(textList)
            this.sewageScopeList = Object.freeze(arr);
            this.sewageScopeTextList = textList;
        },

        removesewageScope() {
            this.sewageScopeList.forEach(item => {
                item.hide();
            })
            this.sewageScopeTextList.forEach(item => {
                item.hide();
            })
        },

        changeSewageScopeSize() {
            // 设置污水处理区的中心文本大小
            const zoom = this.map?.getZoom() || 14;
            this.sewageScopeTextList?.forEach((text) => {
                text.setStyle({
                    "font-size": zoom * 0.6 + "px",
                });
            });
        }

    }
}

